import React, { Fragment } from "react";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";

import facebook_png from "../../../images/home/facebook.png";

import { socialLogin } from "../slice";

const Facebook = () => {
  const dispatch = useDispatch();
  const { replace } = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const responseFacebook = async (response) => {
    if (response) {
      const { email, userID, name } = response;
      const payload = {
        social_id: userID,
        social_type: 2,
        first_name: name && name.split(" ")[0],
        last_name: name && name.split(" ")[1],
        email,
      };

      dispatch(socialLogin(payload)).then(
        ({ message, errorMessage, status }) => {
          if (status === 200) {
            enqueueSnackbar(message || "Success", {
              variant: "success",
            });
            replace("/");
          } else {
            enqueueSnackbar(errorMessage || "Error", {
              variant: "error",
            });
          }
        }
      );
    } else {
      // enqueueSnackbar(error || "Error", {
      //   variant: "error",
      // });
      console.error("Error:", response.error);
    }
  };

  return (
    <Fragment>
      <FacebookLogin
        appId={process.env.REACT_APP_FB_APP_ID}
        fields="name,email,picture"
        callback={responseFacebook}
        render={(renderProps) => (
          <img src={facebook_png} alt="fbimg" onClick={renderProps.onClick} />
        )}
      />
    </Fragment>
  );
};

export default Facebook;
