import React from "react";
import { useHistory } from "react-router-dom";

import dog_walker_png from "../../../../../images/profile/dog-walker.png";

const baseURL = process.env.REACT_APP_PAW_HUGGER_WEB_BASE_URL;

const Services = ({ data, path }) => {
  const { push } = useHistory();

  return (
    <div className="tab-pane fade show active" id="servicesTab" role="tabpanel">
      {data &&
        data.length &&
        data.map((el) => (
          <div className="row my-4 align-items-center" key={el._id}>
            <div className="col-lg-8">
              <div className="round mb-3 shadow p-4">
                <div
                  className="row no-gutters align-items-center"
                  onClick={() => push(`${path}/availability`)}
                  style={{ cursor: "pointer" }}
                >
                  <div className="col-3 col-lg-2">
                    <img
                      src={
                        el && el.service_image
                          ? `${baseURL}/${el.service_image}`
                          : dog_walker_png
                      }
                      alt="Pet"
                      className="img-fluid"
                    />
                  </div>
                  <div className="col-6">
                    <p className="h5">{el.service_name}</p>
                  </div>
                  <div className="col-3 text-right">
                    <p className="price h5 text-primary">
                      $
                      {el.providerServicedata &&
                        el.providerServicedata &&
                        el.providerServicedata.price}{" "}
                      <span> / hr</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-lg-4 text-center">
              <button
                type="button"
                className="btn px-5 btn-primary btn-lg rounded-pill"
              >
                Book Now
              </button>
            </div> */}
          </div>
        ))}
    </div>
  );
};

export default Services;
