import React from "react";

import avatar_png from "../../../../../images/avatar.png";

const baseURL = process.env.REACT_APP_PAW_HUGGER_WEB_BASE_URL;

const Provider = ({ data }) => {
  return (
    <div className="col-lg-7 d-flex flex-wrap">
      <p className="h5 text-gray mb-4  ml-3">Service Provider Details</p>
      <div className="serviceItem my-0 shadow  w-100">
        <div className="row">
          <div className="col-3 col-xl-2">
            <div className="provideravtar">
              <img
                className="avtar"
                src={
                  data && data.profileurl
                    ? `${baseURL}/${data.profileurl}`
                    : avatar_png
                }
                alt="avtar"
              />
              <div className="rating-star">
                <div className="circlerating">
                  <i className="iratingstar active"></i>
                </div>
                <div>{(data && data.rating) || 0}/5</div>
              </div>
            </div>
          </div>
          <div className="col-9 col-xl-10">
            <div className="row justify-content-between align-items-end">
              <div className=" col-auto">
                <h5 className="name">
                  {data && data.first_name + " " + data.last_name}
                  {data && data.is_verified ? (
                    <i className="i20px iverified"></i>
                  ) : null}
                </h5>
                <p>
                  <i className="i20px iexperience"></i>
                  {data &&
                    data.providerService &&
                    data.providerService[0] &&
                    data.providerService[0][0] &&
                    data.providerService[0][0].exp_years}{" "}
                  year Exp
                </p>
                <p>
                  <i className="i20px ilocationmarker"></i>
                  {data && data.address}
                </p>
              </div>
            </div>
            {data &&
            data.provider_service_images &&
            data.provider_service_images.length ? (
              <div className="row mt-3 align-items-center">
                <div className="col-xl-7 col-sm-6">
                  <p>
                    <span className="text-gray small">Photos</span>
                  </p>
                  <div className="gallery">
                    {data.provider_service_images[0] && (
                      <img
                        className="galleryimg"
                        src={
                          data.provider_service_images[0].path
                            ? `${baseURL}/${data.provider_service_images[0].path}`
                            : avatar_png
                        }
                        alt="avtar"
                      />
                    )}
                    {data.provider_service_images[1] && (
                      <img
                        className="galleryimg"
                        src={
                          data.provider_service_images[1].path
                            ? `${baseURL}/${data.provider_service_images[1].path}`
                            : avatar_png
                        }
                        alt="avtar"
                      />
                    )}
                    {data.provider_service_images[1] && (
                      <img
                        className="galleryimg"
                        src={
                          data.provider_service_images[1].path
                            ? `${baseURL}/${data.provider_service_images[1].path}`
                            : avatar_png
                        }
                        alt="avtar"
                      />
                    )}
                    {data.provider_service_images &&
                      data.provider_service_images.length > 3 && (
                        <div>+{data.provider_service_images.length - 3}</div>
                      )}
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Provider;
