/* eslint-disable linebreak-style */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable jsx-a11y/control-has-associated-label */

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";

import logo_footer_png from "../../images/home/logo_footer.png";
import google_play_png from "../../images/home/google_play.png";
import app_store_png from "../../images/home/app-store.png";
import QR_jpg from "../../images/QR.jpg";

import { usePosition } from "../../app/hooks/mapHooks";
import {
  fetchServices,
  getAvailableProviders,
} from "../../features/customer/services/slice";
import { reverseDate } from "../../utils";

const Footer = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { push } = useHistory();

  const { services, isLoggedIn, user } = useSelector((state) => {
    return {
      services: state.services.services,
      isLoggedIn: state.accounts.loggedIn,
      user: state.accounts.user,
    };
  });

  const { lat, lng } = usePosition();

  useEffect(() => {
    if (!services) {
      dispatch(fetchServices({ page: 1 }));
    }
  }, [services, dispatch]);

  const handleService = (e, serviceID) => {
    e.preventDefault();
    if (isLoggedIn && user === "Customer") {
      if (lat & lng) {
        let query = `?cpage=1&perpage=10&service_id=${serviceID}&current_lat=${lat}&current_lng=${lng}&start_date=${reverseDate(
          new Date().toISOString().split("T")[0]
        )}&end_date=${reverseDate(new Date().toISOString().split("T")[0])}`;

        dispatch(getAvailableProviders({ query })).then(() => {
          const serviceObj = services.data.find((el) => el._id === serviceID);
          push(
            `/services/${serviceID}/list?service=${serviceObj.service_name}`
          );
        });
      } else {
        enqueueSnackbar(
          "Loaction is required. Please allow this app to access your curent location.",
          {
            variant: "info",
          }
        );
      }
    } else {
      enqueueSnackbar("Please login as a customer.", {
        variant: "info",
      });
    }
  };

  return (
    <footer className="position-relative ">
      <section className="footer px-3 px-lg-5">
        <div className="container-fluid">
          <div className="row justify-content-between">
            <div className="col-6 col-md-3 d-flex  order-md-1">
              <div className="textwidget align-self-center">
                <Link to="/" onClick={() => window.scrollTo(0, 0)}>
                  <img
                    className="img-fluid logo"
                    src={logo_footer_png}
                    alt="logo"
                  />
                </Link>
              </div>
            </div>
            <div className=" col-md-3 order-md-4">
              <div className="textwidget ">
                <h6 className="widget-title"> Follow Us</h6>
                <div className="social-links">
                  <a
                    href="https://www.facebook.com/paw.hugger"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span className="i40px ifb" />
                  </a>
                  <a
                    href="https://twitter.com/pawhugger"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span className="i40px itw" />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/pawhugger/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span className="i40px iin" />
                  </a>
                  <a
                    href="https://www.instagram.com/pawhugger/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span className="i40px iinsta" />
                  </a>
                </div>
                <h6 className="widget-title">Download App</h6>
                <div className="row">
                  <div className="col">
                    <a
                      href="https://play.google.com/store/apps/details?id=app.petapp.pawhugger"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        className="img-fluid"
                        src={google_play_png}
                        alt="img"
                      />
                    </a>
                  </div>
                  <div className="col">
                    <a
                      href="https://apps.apple.com/in/app/pawhugger/id1534127874"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        className="img-fluid"
                        src={app_store_png}
                        alt="img"
                      />
                    </a>
                  </div>
                </div>
                <div className="row">
                  <img
                    className="img-fluid"
                    src={QR_jpg}
                    alt="img"
                    style={{ width: "50%", height: "50%" }}
                  />
                </div>
              </div>
            </div>
            <div className="col-6 col-md-3  order-md-2">
              <div className="text-left">
                <h5 className="widget-title">Company</h5>
              </div>
              <div className="textwidget ">
                <ul className="nav">
                  <li>
                    <Link to="/?q=how-it-works">How it works</Link>
                  </li>
                  <li>
                    <Link to="/?q=why-choose-us">Why Choose Us</Link>
                  </li>
                  <li>
                    <Link to="/?q=about-us">About Us</Link>
                  </li>
                  <li>
                    <Link to="/?q=contact-us">Contact Us</Link>
                  </li>
                  <li>
                    <a
                      href="https://pawhugger.com:3000/privacy_cookies_policy"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Privacy Policy
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://pawhugger.com:3000/term_condition"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Terms & Conditions
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-6 col-md-3  order-md-3">
              <div className="textwidget ">
                <div className="text-left">
                  <h5 className="widget-title">Services</h5>
                </div>
                <ul className="nav">
                  {services &&
                    services.data &&
                    services.data.length &&
                    services.data.map((el) => (
                      <li key={el._id}>
                        <Link to="/" onClick={(e) => handleService(e, el._id)}>
                          {el.service_name}
                        </Link>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="row justify-content-between">
            <div className="col-12 mt-3 text-center">
              <p className="mb-0">
                Copyright &copy; 2020 Paw Hugger. All rights Reserved.
              </p>
            </div>
          </div>
        </div>
      </section>
    </footer>
  );
};

export default Footer;
