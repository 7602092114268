import React, { Fragment, useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";

// import { reverseDate } from "../../../../utils";
import { addProfileAvailability, getAvailability } from "../../slice";

const Availability = () => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const [state, setState] = useState({
    0: {
      working_day: "",
      start_time: "",
      end_time: "",
      breaks: [{ start_time: "", end_time: "" }],
    },
    1: {
      working_day: "",
      start_time: "",
      end_time: "",
      breaks: [{ start_time: "", end_time: "" }],
    },
    2: {
      working_day: "",
      start_time: "",
      end_time: "",
      breaks: [{ start_time: "", end_time: "" }],
    },
    3: {
      working_day: "",
      start_time: "",
      end_time: "",
      breaks: [{ start_time: "", end_time: "" }],
    },
    4: {
      working_day: "",
      start_time: "",
      end_time: "",
      breaks: [{ start_time: "", end_time: "" }],
    },
    5: {
      working_day: "",
      start_time: "",
      end_time: "",
      breaks: [{ start_time: "", end_time: "" }],
    },
    6: {
      working_day: "",
      start_time: "",
      end_time: "",
      breaks: [{ start_time: "", end_time: "" }],
    },
  });
  const [activeDay, setActiveDay] = useState(0);
  const [leaveDates, setLeaveDates] = useState([{ date: "" }]);
  // const [currentLeave, setCurrentLeave] = useState("");

  const { availability, loading } = useSelector((state) => {
    return {
      availability: state.provider.availability,
      loading: state.provider.loading,
    };
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!availability) {
      dispatch(getAvailability());
    } else {
      if (
        availability &&
        availability.working_day &&
        availability.working_day.length
      ) {
        let newState = {
          0: {
            working_day: "",
            start_time: "",
            end_time: "",
            breaks: [{ start_time: "", end_time: "" }],
          },
          1: {
            working_day: "",
            start_time: "",
            end_time: "",
            breaks: [{ start_time: "", end_time: "" }],
          },
          2: {
            working_day: "",
            start_time: "",
            end_time: "",
            breaks: [{ start_time: "", end_time: "" }],
          },
          3: {
            working_day: "",
            start_time: "",
            end_time: "",
            breaks: [{ start_time: "", end_time: "" }],
          },
          4: {
            working_day: "",
            start_time: "",
            end_time: "",
            breaks: [{ start_time: "", end_time: "" }],
          },
          5: {
            working_day: "",
            start_time: "",
            end_time: "",
            breaks: [{ start_time: "", end_time: "" }],
          },
          6: {
            working_day: "",
            start_time: "",
            end_time: "",
            breaks: [{ start_time: "", end_time: "" }],
          },
        };
        availability.working_day.forEach(
          (el) => (newState[el.working_day] = el)
        );
        setState(newState);
      }

      if (availability && availability.holiday && availability.holiday.length) {
        setLeaveDates(availability.holiday);
      }
    }
  }, [availability, dispatch]);

  const handleChange = ({ name, value }) => {
    let newState = {};
    newState = {
      ...state,
      [activeDay]: {
        ...state[activeDay],
        [name]: value,
      },
    };

    setState(newState);
  };

  // const addBreaks = () => {
  //   const length = state[activeDay].breaks.length - 1;
  //   if (
  //     state[activeDay].breaks[length].start_time !== "" &&
  //     state[activeDay].breaks[length].end_time !== ""
  //   ) {
  //     let newState = {};
  //     newState = {
  //       ...state,
  //       [activeDay]: {
  //         ...state[activeDay],
  //         breaks: [
  //           ...state[activeDay].breaks,
  //           { start_time: "", end_time: "" },
  //         ],
  //       },
  //     };

  //     setState(newState);
  //   }
  // };

  // const handleOpenTime = ({ index, value }) => {
  //   let newState = state;
  //   newState = {
  //     ...state,
  //     [activeDay]: {
  //       ...state[activeDay],
  //       breaks: state[activeDay].breaks.map((el, i) => {
  //         if (i === index) {
  //           return {
  //             ...el,
  //             start_time: value,
  //           };
  //         }
  //         return el;
  //       }),
  //     },
  //   };

  //   setState(newState);
  // };

  // const handleCloseTime = ({ index, value }) => {
  //   let newState = state;
  //   newState = {
  //     ...state,
  //     [activeDay]: {
  //       ...state[activeDay],
  //       breaks: state[activeDay].breaks.map((el, i) => {
  //         if (i === index) {
  //           return {
  //             ...el,
  //             end_time: value,
  //           };
  //         }
  //         return el;
  //       }),
  //     },
  //   };

  //   setState(newState);
  // };

  // const renderBreakTime = () => {
  //   const breakTime =
  //     state[activeDay].breaks &&
  //     state[activeDay].breaks.map((el, index) => (
  //       <Fragment key={"breakTime" + index}>
  //         <div className="form-group col-md-3 col-sm-6">
  //           <label>Open Time</label>
  //           <div className="custom-group-input rounded-digonal left-icon">
  //             <input
  //               type="time"
  //               className="form-control "
  //               placeholder="Open Time"
  //               value={el.start_time || ""}
  //               onChange={(e) => {
  //                 let value = e.target.value;
  //                 handleOpenTime({ index, value });
  //               }}
  //             />
  //             <span className="group-icon-left ">
  //               <i className="i30px iadd-time"></i>
  //             </span>
  //           </div>
  //         </div>
  //         <div className="form-group col-md-3 col-sm-6">
  //           <label>Close Time</label>
  //           <div className="custom-group-input rounded-digonal left-icon">
  //             <input
  //               type="time"
  //               className="form-control "
  //               placeholder="Close Time"
  //               value={el.end_time || ""}
  //               onChange={(e) => {
  //                 let value = e.target.value;
  //                 handleCloseTime({ index, value });
  //               }}
  //             />
  //             <span className="group-icon-left ">
  //               <i className="i30px iadd-time"></i>
  //             </span>
  //           </div>
  //         </div>
  //       </Fragment>
  //     ));

  //   return breakTime;
  // };

  // const addLeave = () => {
  //   setCurrentLeave("");
  //   if (leaveDates[leaveDates.length - 1].date !== "") {
  //     setLeaveDates([...leaveDates, { date: "" }]);
  //   }
  // };

  // const removeLeave = ({ index }) => {
  //   const leaves = leaveDates.filter((el, i) => i !== index);
  //   if (!leaves.length) {
  //     setLeaveDates([{ date: "" }]);
  //   } else {
  //     setLeaveDates(leaves);
  //   }
  // };

  // const handleLeaveChange = ({ index, value }) => {
  //   let leaves = leaveDates;
  //   leaves[index].date = reverseDate(value);
  //   setLeaveDates(leaves);
  // };

  // const renderLeaves = () => {
  //   const leaves = leaveDates.map((el, index) => (
  //     <div className="form-group col-sm-6 col-lg-4" key={"Leaves" + index}>
  //       <div className="custom-group-input rounded-digonal left-icon">
  //         <input
  //           type="date"
  //           className="form-control "
  //           placeholder="Start Date"
  //           value={el.date ? reverseDate(el.date) : currentLeave}
  //           onChange={(e) => {
  //             let value = e.target.value;
  //             handleLeaveChange({ index, value });
  //             setCurrentLeave(value);
  //           }}
  //         />
  //         <span className="group-icon-left ">
  //           <i className="i30px icalendar"></i>{" "}
  //         </span>
  //       </div>
  //     </div>
  //   ));

  //   return leaves;
  // };

  const validate = () => {
    let isValid = true;

    for (const key of Object.keys(state)) {
      if (state[key].working_day !== "") {
        if (!state[key].start_time) {
          isValid = false;
          enqueueSnackbar(
            `Please add open time for day ${
              Number(state[key].working_day) + 1
            }`,
            {
              variant: "error",
            }
          );
        }

        if (!state[key].end_time) {
          isValid = false;
          enqueueSnackbar(
            `Please add close time for day ${
              Number(state[key].working_day) + 1
            }`,
            {
              variant: "error",
            }
          );
        }
      }
    }

    return isValid;
  };

  const handleSubmit = () => {
    let isAdded = false;
    for (const key of Object.keys(state)) {
      if (state[key].working_day !== "") {
        isAdded = true;
      }
    }

    if (isAdded && validate()) {
      let leaves = [];
      let workday = [];
      leaveDates.forEach((el) => {
        if (el.date) {
          leaves.push(el.date);
        }
      });
      for (const key of Object.keys(state)) {
        if (state[key].working_day !== "") {
          workday.push(state[key]);
        }
      }
      let payload = {
        workday,
        leavedates: leaves,
      };

      dispatch(addProfileAvailability(payload)).then(
        ({ message, status, errorMessage }) => {
          if (status === 200) {
            enqueueSnackbar(message || "Success", {
              variant: "success",
            });
            dispatch(getAvailability());
          } else {
            enqueueSnackbar(errorMessage || "Error", {
              variant: "error",
            });
          }
        }
      );
    } else {
      enqueueSnackbar("Please add your working days.", {
        variant: "error",
      });
    }
  };

  return (
    <Fragment>
      <p className="h4 my-5">Business Days</p>

      <div className="checkbox-tabs mb-5 businessCheckbox">
        <div>
          <input
            type="checkbox"
            name="working_day"
            id="sun"
            value={0}
            checked={state[0].working_day !== "" || activeDay === 0}
            onChange={() => setActiveDay(0)}
          />
          <label
            htmlFor="sun"
            className={
              state[0].working_day !== ""
                ? "label-button rounded-pill completed"
                : activeDay === 0
                ? "label-button rounded-pill active"
                : "label-button rounded-pill"
            }
          >
            Sun
          </label>
        </div>
        <div>
          <input
            type="checkbox"
            name="working_day"
            id="mon"
            value={1}
            checked={state[1].working_day !== "" || activeDay === 1}
            onChange={() => setActiveDay(1)}
          />
          <label
            htmlFor="mon"
            className={
              state[1].working_day !== ""
                ? "label-button rounded-pill completed"
                : activeDay === 1
                ? "label-button rounded-pill active"
                : "label-button rounded-pill"
            }
          >
            Mon
          </label>
        </div>
        <div>
          <input
            type="checkbox"
            name="working_day"
            id="tue"
            value={2}
            checked={state[2].working_day !== "" || activeDay === 2}
            onChange={() => setActiveDay(2)}
          />
          <label
            htmlFor="tue"
            className={
              state[2].working_day !== ""
                ? "label-button rounded-pill completed"
                : activeDay === 2
                ? "label-button rounded-pill active"
                : "label-button rounded-pill"
            }
          >
            Tue
          </label>
        </div>
        <div>
          <input
            type="checkbox"
            name="working_day"
            id="wed"
            value={3}
            checked={state[3].working_day !== "" || activeDay === 3}
            onChange={() => setActiveDay(3)}
          />
          <label
            htmlFor="wed"
            className={
              state[3].working_day !== ""
                ? "label-button rounded-pill completed"
                : activeDay === 3
                ? "label-button rounded-pill active"
                : "label-button rounded-pill"
            }
          >
            Wed
          </label>
        </div>
        <div>
          <input
            type="checkbox"
            name="working_day"
            id="thur"
            value={4}
            checked={state[4].working_day !== "" || activeDay === 4}
            onChange={() => setActiveDay(4)}
          />
          <label
            htmlFor="thur"
            className={
              state[4].working_day !== ""
                ? "label-button rounded-pill completed"
                : activeDay === 4
                ? "label-button rounded-pill active"
                : "label-button rounded-pill"
            }
          >
            Thur
          </label>
        </div>
        <div>
          <input
            type="checkbox"
            name="working_day"
            id="fri"
            value={5}
            checked={state[5].working_day !== "" || activeDay === 5}
            onChange={() => setActiveDay(5)}
          />
          <label
            htmlFor="fri"
            className={
              state[5].working_day !== ""
                ? "label-button rounded-pill completed"
                : activeDay === 5
                ? "label-button rounded-pill active"
                : "label-button rounded-pill"
            }
          >
            Fri
          </label>
        </div>
        <div>
          <input
            type="checkbox"
            name="working_day"
            id="sat"
            value={6}
            checked={state[6].working_day !== "" || activeDay === 6}
            onChange={() => setActiveDay(6)}
          />
          <label
            htmlFor="sat"
            className={
              state[6].working_day !== ""
                ? "label-button rounded-pill completed"
                : activeDay === 6
                ? "label-button rounded-pill active"
                : "label-button rounded-pill"
            }
          >
            Sat
          </label>
        </div>
      </div>
      <div className="my-4 my-lg-5 d-flex align-items-center justify-content-between">
        <p className="h4 text-gray">
          {" "}
          {(() => {
            switch (activeDay) {
              case 0:
                return "Sunday";
              case 1:
                return "Monday";
              case 2:
                return "Tuesday";
              case 3:
                return "Wednesday";
              case 4:
                return "Thursday";
              case 5:
                return "Friday";
              case 6:
                return "Saturday";

              default:
                break;
            }
          })()}
        </p>
        <div>
          <div className="switch-btn">
            <input
              type="checkbox"
              name="working_day"
              id=""
              value={state[activeDay].working_day || ""}
              checked={state[activeDay].working_day !== ""}
              onChange={() =>
                handleChange({
                  name: "working_day",
                  value: state[activeDay].working_day === "" ? activeDay : "",
                })
              }
            />
            <span></span>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <p className="h4 my-4 text-capitalize">Select Business Hours</p>
        </div>
        <div className="form-group col-md-3 col-sm-6">
          <label>Open Time</label>
          <div className="custom-group-input rounded-digonal left-icon">
            <input
              type="time"
              className="form-control "
              placeholder="Open Time"
              value={state[activeDay].start_time || ""}
              required={state[activeDay].working_day !== ""}
              onChange={(e) => {
                let value = e.target.value;
                handleChange({ name: "start_time", value });
              }}
            />
            <span className="group-icon-left ">
              <i className="i30px iadd-time"></i>
            </span>
          </div>
        </div>
        <div className="form-group col-md-3 col-sm-6">
          <label>Close Time</label>
          <div className="custom-group-input rounded-digonal left-icon">
            <input
              type="time"
              className="form-control "
              placeholder="Close Time"
              value={state[activeDay].end_time || ""}
              required={state[activeDay].working_day !== ""}
              onChange={(e) => {
                let value = e.target.value;
                handleChange({ name: "end_time", value });
              }}
            />
            <span className="group-icon-left ">
              <i className="i30px iadd-time"></i>
            </span>
          </div>
        </div>
      </div>
      {/* <div className="row align-items-center mt-5">
        <div className="col-12">
          <p className="h4 mb-4 text-capitalize">Add Break Time</p>
        </div>
        {renderBreakTime()}
        <div className="form-group col-md-3 col-sm-6 mt-4">
          <button
            type="button"
            className="btn btn-primary rounded-pill"
            onClick={addBreaks}
          >
            + Add More
          </button>
        </div>
      </div> */}
      {/* <div className="row align-items-center mt-5">
        <div className="col-12">
          <p className="h4 mb-4 text-capitalize">Add Business Holidays</p>
        </div>
        {renderLeaves()}
        <div className="form-group col-md-3 col-sm-6">
          <button
            type="button"
            className="btn btn-primary rounded-pill"
            onClick={addLeave}
          >
            + Add More
          </button>
        </div>
      </div>

      {leaveDates && leaveDates.length ? (
        <div className="mb-5">
          {leaveDates.map((el, index) => (
            <Fragment key={index}>
              {el.date ? (
                <div className="btn btn-primary p-3 rounded-pill">
                  {new Date(reverseDate(el.date)).toDateString()}
                  <i
                    className="ti-close pointer ml-3"
                    onClick={() => removeLeave({ index })}
                  ></i>
                </div>
              ) : null}
            </Fragment>
          ))}
        </div>
      ) : null} */}

      <div className="row justify-content-center">
        <div className="col-md-6 mb-5 col-xl-4">
          <div className="form-group mt-4">
            <button
              type="button"
              className="btn btn-primary btn-block btn-feet --blue btn-lg btn-block rounded-pill"
              onClick={handleSubmit}
            >
              {loading ? "Saving..." : "Save"}
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Availability;
