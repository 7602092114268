import React from "react";
import { getLastActive } from "../../../../../utils";

const Stats = ({ data }) => {
  return (
    <div className="tab-pane fade show active" id="statsTab" role="tabpanel">
      <div className="row my-4 align-items-center">
        <div className="col-sm-12">
          <div className="stats-box">
            <div className="row no-gutters align-items-center">
              <div className="col-sm-9">
                <h5>
                  <i className="i30px mr-2 iproflikeblk"></i>Response Rate
                </h5>
              </div>
              <div className="col-sm-3 text-sm-right">
                <p className="h5 text-primary">
                  {(data && data.response_rate) || 100}%
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row my-4 align-items-center">
        <div className="col-sm-12">
          <div className="stats-box">
            <div className="row no-gutters align-items-center">
              <div className="col-sm-9">
                <h5>
                  <i className="i30px mr-2 iproftime"></i>Average Response Time
                </h5>
              </div>
              <div className="col-sm-3 text-sm-right">
                <p className="h5 text-primary">
                  Within {(data && data.response_rate) || 1}hr
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row my-4 align-items-center">
        <div className="col-sm-12">
          <div className="stats-box">
            <div className="row no-gutters align-items-center">
              <div className="col-sm-9">
                <h5>
                  <i className="i30px mr-2 iprofeye"></i>Last Active
                </h5>
              </div>
              <div className="col-3 text-sm-right">
                <p className="h5 text-primary">
                  {data && getLastActive(data.last_active)} ago
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row my-4 align-items-center">
        <div className="col-sm-12">
          <div className="stats-box">
            <div className="row no-gutters align-items-center">
              <div className="col-sm-9">
                <h5>
                  <i className="i30px mr-2 iprofverifiy"></i>
                  Verification
                </h5>
              </div>
              <div className="col-sm-3 text-sm-right">
                <p className="h5 text-primary">
                  {data && data.is_verified ? "Verified" : "Not Verified"}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Stats;
