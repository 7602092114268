import { useSnackbar } from "notistack";
import React, { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import change_password_png from "../../../images/profile/change-password.png";

import { changePassword } from "../slice";

const ChangePassword = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const loading = useSelector((state) => state.accounts.loading);

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showOldPwd, setShowOldPwd] = useState(false);
  const [showNewPwd, setShowNewPwd] = useState(false);
  const [showCnfPwd, setShowCnfPwd] = useState(false);
  const [error, setError] = useState({});

  const validate = () => {
    let errors = {};
    let isValid = true;

    if (!oldPassword) {
      isValid = false;
      errors.oldPassword = "Required";
    }

    if (!newPassword) {
      isValid = false;
      errors.newPassword = "Required";
    } else if (newPassword.length < 8) {
      isValid = false;
      errors.newPassword = "Password length should be at least 8 characters.";
    }

    if (!confirmPassword) {
      isValid = false;
      errors.confirmPassword = "Required";
    } else if (newPassword !== confirmPassword) {
      isValid = false;
      errors.confirmPassword = "Confirm password should be same as password.";
    }

    setError(errors);
    return isValid;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (validate()) {
      dispatch(
        changePassword({
          new_password: confirmPassword,
          current_password: oldPassword,
        })
      ).then(({ errorMessage, status, message }) => {
        if (status === 200) {
          setOldPassword("");
          setNewPassword("");
          setConfirmPassword("");
          enqueueSnackbar(message || "Success", {
            variant: "success",
          });
        } else {
          enqueueSnackbar(errorMessage || "Error", {
            variant: "error",
          });
        }
      });
    }
  };

  return (
    <Fragment>
      <div className="row align-items-center">
        <div className="col-3">
          {/* <span className="ti-angle-left h3 text-dark"></span> */}
        </div>
        <div className="col-sm-6 text-center">
          <p className="h3 text-gray"> Change Password</p>
        </div>
      </div>

      <div className="row justify-content-center my-5">
        <div className="col-lg-10 col-xl-8">
          <form onSubmit={handleSubmit}>
            <div className="round-box px-5">
              <div className="form-group text-center">
                <img
                  src={change_password_png}
                  className="img-fluid mx-auto"
                  alt="change img"
                />
              </div>
              <div className="row justify-content-center mt-5">
                <div className="form-group col-md-8">
                  <div className="custom-group-input rounded-digonal left-icon">
                    <input
                      type={showOldPwd ? "text" : "password"}
                      className="form-control "
                      placeholder="Current Password"
                      onInput={() => setError({ ...error, oldPassword: "" })}
                      required
                      value={oldPassword || ""}
                      onChange={(e) => {
                        let value = e.target.value;
                        setOldPassword(value);
                      }}
                    />
                    <span className="group-icon-left ">
                      <i className="i25px ipassword"></i>
                    </span>
                    <span
                      onMouseUp={() => setShowOldPwd(false)}
                      onMouseDown={() => setShowOldPwd(true)}
                      id="reg_Pwd"
                      className={
                        showOldPwd
                          ? "group-icon-right fa fa-eye-slash pointer"
                          : "group-icon-right fa fa-eye pointer"
                      }
                      aria-hidden="true"
                    ></span>
                  </div>
                  <span className="error">{error.oldPassword}</span>
                </div>
                <div className="form-group col-md-8">
                  <div className="custom-group-input rounded-digonal left-icon">
                    <input
                      type={showNewPwd ? "text" : "password"}
                      className="form-control "
                      placeholder="New Password"
                      onInput={() => setError({ ...error, newPassword: "" })}
                      required
                      value={newPassword || ""}
                      onChange={(e) => {
                        let value = e.target.value;
                        setNewPassword(value);
                      }}
                    />
                    <span className="group-icon-left ">
                      <i className="i25px ipassword"></i>
                    </span>
                    <span
                      onMouseUp={() => setShowNewPwd(false)}
                      onMouseDown={() => setShowNewPwd(true)}
                      id="reg_Pwd"
                      className={
                        showNewPwd
                          ? "group-icon-right fa fa-eye-slash pointer"
                          : "group-icon-right fa fa-eye pointer"
                      }
                      aria-hidden="true"
                    ></span>
                  </div>
                  <span className="error">{error.newPassword}</span>
                </div>
                <div className="form-group col-md-8">
                  <div className="custom-group-input rounded-digonal left-icon">
                    <input
                      type={showCnfPwd ? "text" : "password"}
                      className="form-control "
                      placeholder="Confirm Password"
                      onInput={() =>
                        setError({ ...error, confirmPassword: "" })
                      }
                      required
                      value={confirmPassword || ""}
                      onChange={(e) => {
                        let value = e.target.value;
                        setConfirmPassword(value);
                      }}
                    />
                    <span className="group-icon-left ">
                      <i className="i25px ipassword"></i>
                    </span>
                    <span
                      onMouseUp={() => setShowCnfPwd(false)}
                      onMouseDown={() => setShowCnfPwd(true)}
                      id="reg_Pwd"
                      className={
                        showCnfPwd
                          ? "group-icon-right fa fa-eye-slash pointer"
                          : "group-icon-right fa fa-eye pointer"
                      }
                      aria-hidden="true"
                    ></span>
                  </div>
                  <span className="error">{error.confirmPassword}</span>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-md-6">
                  <div className="form-group mt-4">
                    <button
                      type="submit"
                      className="btn btn-primary btn-block btn-feet --blue btn-lg btn-block rounded-pill"
                    >
                      {loading ? "Changing..." : "Change Password"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

export default ChangePassword;
