import React, { memo, useEffect, useState } from "react";
import { Prompt, useHistory, useParams } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";

import login_bg_without_shadow_png from "../../../images/home/login_bg_without_shadow.png";
import logo_footer_png from "../../../images/home/logo_footer.png";

import { useInputValue, useToggle } from "../../../app/hooks";
import { resetPassword } from "../slice";

const Reset = memo(() => {
  const { goBack, replace } = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useParams();
  const dispatch = useDispatch();

  const loading = useSelector((state) => state.accounts.loading);

  const [open, toggle] = useToggle(true);
  const password = useInputValue("");
  const confirmPassword = useInputValue("");

  const [showPwd, setShowPwd] = useState(false);
  const [showCnfPwd, setShowCnfPwd] = useState(false);
  const [isBlocking, setIsBlocking] = useState(true);
  const [error, setError] = useState({});

  useEffect(() => {
    if (isBlocking) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }
  }, [isBlocking]);

  const validate = () => {
    let errors = {};
    let isValid = true;

    if (!password.value) {
      isValid = false;
      errors.password = "Required";
    } else if (password.value.length < 8) {
      isValid = false;
      errors.password = "Password length should be at least 8 characters.";
    }

    if (!confirmPassword.value) {
      isValid = false;
      errors.confirmPassword = "Required";
    } else if (password.value !== confirmPassword.value) {
      isValid = false;
      errors.confirmPassword = "Confirm password should be same as password.";
    }

    setError(errors);
    return isValid;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (validate()) {
      dispatch(resetPassword({ new_password: confirmPassword.value })).then(
        ({ errorMessage, status, message }) => {
          if (status === 200) {
            setIsBlocking(false);
            enqueueSnackbar(message || "Success", {
              variant: "success",
            });
            replace(`/${user}/login`);
          } else {
            enqueueSnackbar(errorMessage || "Error", {
              variant: "error",
            });
          }
        }
      );
    }
  };

  const handleHide = () => {
    goBack();
    if (!isBlocking) {
      toggle();
    }
  };

  return (
    <Modal
      show={open}
      onHide={handleHide}
      size="xl"
      id="reset-passwordModal"
      className="modal form-popup "
      aria-labelledby="exampleModalLabel"
    >
      <Prompt
        when={isBlocking}
        message={(location) =>
          `Are you sure you want to go to ${location.pathname}`
        }
      />
      <div className="modal-body ">
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={handleHide}
        ></button>
        <div className="row no-gutters justify-content-end">
          <div className="col-md-6 form-img d-lg-flex justify-content-center">
            <div className="img-holder align-self-center">
              <img
                className="img-fluid"
                src={login_bg_without_shadow_png}
                alt=" img"
              />
            </div>
            <img src={logo_footer_png} alt="img" className="logomodal" />
          </div>
          <div className="col-lg-6 form-box d-flex justify-content-center">
            <form
              className="w-100 px-5 align-self-center"
              onSubmit={handleSubmit}
            >
              <div className="text-center col-9  mx-auto pb-5">
                <h4 className="mb-3">Reset Password</h4>
                <p className="h6">
                  Please fill the fields below to reset your password
                </p>
              </div>

              <div className="form-group">
                <div className="custom-group-input rounded-digonal left-icon">
                  <input
                    type={showPwd ? "text" : "password"}
                    className="form-control "
                    placeholder="Password"
                    onInput={() => setError({ ...error, password: "" })}
                    required
                    {...password}
                  />
                  <span className="group-icon-left ">
                    <i className="i25px ipassword"></i>
                  </span>
                  <span
                    onMouseUp={() => setShowPwd(false)}
                    onMouseDown={() => setShowPwd(true)}
                    id="reg_Pwd"
                    className={
                      showPwd
                        ? "group-icon-right fa fa-eye-slash pointer"
                        : "group-icon-right fa fa-eye pointer"
                    }
                    aria-hidden="true"
                  ></span>
                </div>
                <span className="error">{error.password}</span>
              </div>

              <div className="form-group">
                <div className="custom-group-input rounded-digonal left-icon">
                  <input
                    type={showCnfPwd ? "text" : "password"}
                    className="form-control "
                    placeholder=" Password"
                    onInput={() => setError({ ...error, confirmPassword: "" })}
                    required
                    {...confirmPassword}
                  />
                  <span className="group-icon-left ">
                    <i className="i25px ipassword"></i>
                  </span>
                  <span
                    onMouseUp={() => setShowCnfPwd(false)}
                    onMouseDown={() => setShowCnfPwd(true)}
                    id="reg_Pwd"
                    className={
                      showCnfPwd
                        ? "group-icon-right fa fa-eye-slash pointer"
                        : "group-icon-right fa fa-eye pointer"
                    }
                    aria-hidden="true"
                  ></span>
                </div>
                <span className="error">{error.confirmPassword}</span>
              </div>

              <div className=" mt-5">
                <button
                  type="submit"
                  className="btn btn-primary btn-block btn-feet --blue btn-lg btn-block rounded-pill"
                >
                  {loading ? "Saving..." : "Save"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Modal>
  );
});

export default Reset;
