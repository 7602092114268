import React from "react";

import ChangePassword from "../../features/accounts/changePassword";
import Help from "../../features/accounts/help";
import MyBookings from "../../features/provider/dashboard/myBookings";
import Revenue from "../../features/provider/dashboard/revenue";
import Availability from "../../features/provider/dashboard/availability";
import BankAccounts from "../../features/provider/dashboard/bankAcoounts";
import MyProfile from "../../features/myProfile";

const routes = [
  {
    path: "/dashboard/my-bookings",
    main: () => <MyBookings />,
  },
  {
    path: "/dashboard/revenue",
    exact: true,
    main: () => <Revenue />,
  },
  {
    path: "/dashboard/bank-accounts",
    exact: true,
    main: () => <BankAccounts />,
  },
  {
    path: "/dashboard/availability",
    exact: true,
    main: () => <Availability />,
  },
  {
    path: "/dashboard/my-profile",
    exact: true,
    main: () => <MyProfile />,
  },
  {
    path: "/dashboard/help",
    exact: true,
    main: () => <Help />,
  },
  {
    path: "/dashboard/change-password",
    exact: true,
    main: () => <ChangePassword />,
  },
];

export default routes;
