import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { contact } from "../../features/accounts/slice";

const ContactUs = () => {
  const disptach = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [state, setState] = useState({});
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleChange = (event) => {
    const value = event.target.value;
    const name = event.target.name;

    setError({ ...error, [name]: "" });
    setState({ ...state, [name]: value });
  };

  const validate = () => {
    let isValid = true;
    let errors = {};

    if (!state.email) {
      isValid = false;
      errors.email = "Required.";
    }

    if (!state.name) {
      isValid = false;
      errors.name = "Required.";
    }

    if (!state.message) {
      isValid = false;
      errors.message = "Required.";
    }

    setError(errors);
    return isValid;
  };

  function handleSubmit(event) {
    event.preventDefault();

    if (validate()) {
      setLoading(true);
      const { name, email, message } = state;
      const payload = {
        name,
        email,
        message,
      };
      disptach(contact(payload)).then(({ message, status, errorMessage }) => {
        setLoading(false);
        if (status === 200) {
          setState({});
          enqueueSnackbar(message || "Success", {
            variant: "success",
          });
        } else {
          enqueueSnackbar(errorMessage || "Error", {
            variant: "error",
          });
        }
      });
    }
  }

  return (
    <main className="page-wrapper">
      <div className=" cms-banner d-flex mt-5"></div>
      <div className="cmsForm mb-5 px-5 mt-5">
        <h3 className="text-light mb-4 text-center">Contact Us</h3>
        <div className="row justify-content-center">
          <div className="col-xl-7 col-md-9 col-sm-10 border-0 shadow card p-5">
            <div className="col-lg-9 mx-auto">
              <h5 className="text-center">
                We would love to hear from you? <br></br>
                Feel free to contact us through our email address
              </h5>
              <p className="h5 text-center font-weight-normal mb-5">
                <a className="text-dark" href="mailto:contact@goejar.com">
                  info@pawhugger.com
                </a>
              </p>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-6 form-group">
                  <div className="input-group	">
                    <input
                      type="text"
                      name="name"
                      minLength="2"
                      maxLength="35"
                      required
                      value={state.name || ""}
                      className="form-control gray-placeholder"
                      placeholder="Your Name"
                      onChange={handleChange}
                    ></input>
                  </div>
                  <span className="error">{error.name}</span>
                </div>
                <div className="col-md-6 form-group">
                  <div className="input-group	">
                    <input
                      type="email"
                      name="email"
                      minLength="2"
                      maxLength="35"
                      required
                      value={state.email || ""}
                      className="form-control gray-placeholder"
                      placeholder="Email Address"
                      onChange={handleChange}
                    ></input>
                  </div>
                  <span className="error">{error.email}</span>
                </div>
                <div className="col-12 form-group">
                  <textarea
                    className="form-control gray-placeholder"
                    placeholder="Type your message here"
                    rows="5"
                    name="message"
                    required
                    minLength="10"
                    maxLength="1000"
                    value={state.message || ""}
                    onChange={handleChange}
                  ></textarea>
                  <span className="error">{error.message}</span>
                </div>
              </div>
              <div className="row justify-content-end">
                <div className="col-md-4 col-sm-6">
                  <button
                    type="reset"
                    className="btn btn-outline-danger btn-lg btn-block mb-3 mb-sm-0"
                    onClick={() => setState({})}
                  >
                    Cancel
                  </button>
                </div>
                <div className="col-md-4 col-sm-6">
                  <button
                    type="submit"
                    className="btn btn-primary btn-lg btn-block"
                  >
                    {loading ? "Sending..." : "Send Message"}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </main>
  );
};

export default ContactUs;
