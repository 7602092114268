import React, { forwardRef } from "react";
import { Link } from "react-router-dom";

const Contact = forwardRef((props, ref) => {
  return (
    <section className="home-getintouch" ref={ref}>
      <div className="container-fluid px-0 ">
        <div className="px-0">
          <div className="row mx-0 no-gutters ">
            <div className="col-md-6 getintouchimg" />
            <div className="col-md-6 contentbg-gradient d-flex">
              <div className="my-5 p-3 p-lg-5 align-self-center">
                <h3 className="mb-4 text-light">Feel free to Get in Touch</h3>
                <p className=" text-light">
                  Please contact us through the contact form.
                </p>
                <Link
                  to="/contact-us"
                  className="btn btn-xl btn-light-primary rounded-pill mt-3"
                >
                  Contact
                  <i className="ti-angle-right ml-4" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
});

export default Contact;
