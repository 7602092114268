import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import stripe_png from "../../../../images/stripe.png";

import { createStipeAccount } from "../../../../api";
import { getStripeAccount, removeStripeAccount } from "../../slice";

const BankAccounts = () => {
  const dispatch = useDispatch();
  const { replace } = useHistory();

  const { loading, account } = useSelector((state) => {
    return {
      loading: state.provider.loading,
      account: state.provider.account,
    };
  });

  const [redirecting, setRedirecting] = useState(false);

  useEffect(() => {
    if (!account) {
      dispatch(getStripeAccount());
    }
  }, [dispatch, account]);

  return (
    <Fragment>
      <div className="my-4 my-lg-5 d-flex align-items-center justify-content-between">
        <p className="h4">My Accounts</p>
        {loading && <p>Loading...</p>}
        {!(account && account.stripe_account_id) && (
          <div>
            <Link
              to="/dashboard/bank-accounts/new"
              className="btn btn-lg px-4 btn-primary rounded-pill"
              data-target="#add-bankdetails-modal"
              data-toggle="modal"
              onClick={(e) => {
                e.preventDefault();
                createStipeAccount();
                setRedirecting(true);
                setTimeout(() => {
                  setRedirecting(false);
                  replace("/");
                }, 3000);
              }}
            >
              {redirecting
                ? "Redirecting you to stripe..."
                : "Please link your bank account to get the Earning"}
            </Link>
          </div>
        )}
      </div>

      <div className="row my-4">
        {account && account.stripe_account_id && (
          <div className="booking-item mt-0 mb-4 w-100">
            <div className="input-select">
              <i
                className="fa fa-trash pointer"
                aria-hidden="true"
                onClick={() => dispatch(removeStripeAccount())}
                style={{ float: "right", margin: "2px" }}
              />
              <label htmlFor="" className="media">
                <i className="i40px i-cc mr-3"></i>
                <div className="media-body py-2">
                  <h5 className="text-uppercase font-weight-bold">
                    Stripe Account
                    <img src={stripe_png} width="60px" alt="alt" />
                  </h5>

                  <p className="h4 my-3">
                    {account &&
                      account.stripe_account_details &&
                      account.stripe_account_details.country}
                  </p>
                  <p className="text-gray mb-2 text-capitalize h5">
                    {account &&
                      account.stripe_account_details &&
                      account.stripe_account_details.type}
                  </p>
                  {/* <h6 className="text-gray">ABA Number</h6>
                  <h5>101001101</h5> */}
                </div>
              </label>
            </div>
          </div>
        )}
        <div className="col-md-6 d-flex flex-wrap"></div>
      </div>
    </Fragment>
  );
};

export default BankAccounts;
