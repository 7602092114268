import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useLocation } from "react-router-dom";

import header_logo_png from "../../images/home/header_logo.png";
import avatar_png from "../../images/home/avatar.png";

import useComponentVisible from "../../app/hooks";
import { logoutUser } from "../../features/accounts/slice";

const baseURL = process.env.REACT_APP_PAW_HUGGER_WEB_BASE_URL;

const Header = () => {
  const { search } = useLocation();
  const dispatch = useDispatch();

  const { loggedIn, details, user } = useSelector((state) => {
    return {
      loggedIn: state.accounts.loggedIn,
      details: state.accounts.details,
      user: state.accounts.user,
    };
  });

  const {
    ref,
    isComponentVisible,
    setIsComponentVisible,
  } = useComponentVisible(false);

  const [isSticky, setSticky] = useState(false);

  const handleScroll = () => {
    setSticky(window.scrollY > 110);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <header id="header" className="site-header">
      <nav
        className={
          isSticky
            ? "navbar navbar-expand-xl fixed-top sticky-on"
            : "navbar navbar-expand-xl fixed-top"
        }
      >
        <Link
          className="navbar-brand"
          to="/"
          onClick={() => window.scrollTo(0, 0)}
        >
          <img src={header_logo_png} className="logo" alt="logo" />
        </Link>
        <div
          className="navbar-collapse offcanvas-collapse"
          id="navbarsExampleDefault"
        >
          <ul className="navbar-nav ml-auto">
            <li className={search === "" ? "nav-item active" : "nav-item"}>
              <NavLink
                className="nav-link"
                to="/"
                onClick={() => window.scrollTo(0, 0)}
              >
                Home
                <span className="sr-only">(current)</span>
              </NavLink>
            </li>
            <li
              className={
                search === "?q=services" ? "nav-item active" : "nav-item"
              }
            >
              <NavLink className="nav-link" to="/?q=services">
                Services
              </NavLink>
            </li>
            <li
              className={
                search === "?q=how-it-works" ? "nav-item active" : "nav-item"
              }
            >
              <NavLink className="nav-link" to="/?q=how-it-works">
                How it works
              </NavLink>
            </li>
            <li
              className={
                search === "?q=why-choose-us" ? "nav-item active" : "nav-item"
              }
            >
              <NavLink className="nav-link" to="/?q=why-choose-us">
                Why Choose Us
              </NavLink>
            </li>
            <li
              className={
                search === "?q=about-us" ? "nav-item active" : "nav-item"
              }
            >
              <NavLink className="nav-link" to="/?q=about-us">
                About Us
              </NavLink>
            </li>
            <li
              className={
                search === "?q=contact-us" ? "nav-item active" : "nav-item"
              }
            >
              <NavLink className="nav-link" to="/?q=contact-us">
                Contact Us
              </NavLink>
            </li>
          </ul>
        </div>
        <ul className="nav ml-auto nav-btns">
          <li className="nav-item" style={{ cursor: "pointer" }}>
            <i className="iappdownload i30px" />
            <span
              className="d-none d-sm-inline-block"
              onClick={() =>
                window.scrollTo({
                  top: document.body.scrollHeight,
                  behavior: "smooth",
                })
              }
            >
              Download App
            </span>
          </li>

          {loggedIn ? (
            <li
              ref={ref}
              className={
                isComponentVisible
                  ? "nav-item dropdown user-drop show"
                  : "nav-item dropdown user-drop"
              }
            >
              <Link
                className="nav-link  btn btn-outline-light"
                data-toggle="dropdown"
                to="/"
                aria-expanded={isComponentVisible}
                onClick={(e) => {
                  e.preventDefault();
                  setIsComponentVisible(!isComponentVisible);
                }}
              >
                <img
                  src={
                    details && details.profileurl
                      ? `${baseURL}/${details.profileurl}`
                      : avatar_png
                  }
                  alt="img profile"
                />
                <span>{details && details.first_name}</span>
              </Link>
              <div
                className={
                  isComponentVisible
                    ? "dropdown-menu dropdown-menu-right show"
                    : "dropdown-menu dropdown-menu-right"
                }
              >
                <Link
                  to="/dashboard/my-bookings/ongoing"
                  onClick={() => setIsComponentVisible(false)}
                >
                  <span>Dashboard</span>
                </Link>
                {user === "Provider" ? (
                  <Link
                    to="/profile-setup/services"
                    onClick={() => setIsComponentVisible(false)}
                  >
                    <span>Profile</span>
                  </Link>
                ) : null}
                <Link
                  to="/"
                  onClick={() => {
                    setIsComponentVisible(false);
                    dispatch(logoutUser());
                  }}
                >
                  <span>Logout</span>
                </Link>
              </div>
            </li>
          ) : (
            <li className="nav-item">
              <Link
                className="nav-link btn btn-outline-primary "
                to="/paw-hugger"
                data-toggle="modal"
              >
                <span> Login | Signup</span>
              </Link>
            </li>
          )}
          <div className="nav-icon" data-toggle="offcanvas">
            <span />
            <span />
            <span />
          </div>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
