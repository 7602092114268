import { useSnackbar } from "notistack";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import PhoneInput from "react-phone-number-input";

import avatar_png from "../../images/avatar.png";

import { useAddressPredictions, useGeoCoder } from "../../app/hooks/mapHooks";
import {
  getDetails,
  selectProfile,
  updateProfile,
  verifyEmail,
} from "../accounts/slice";

const basePath = process.env.REACT_APP_PAW_HUGGER_WEB_BASE_URL;

const MyProfile = () => {
  const { user } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const loading = useSelector((state) => state.accounts.loading);
  const profile = useSelector(selectProfile);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [mobile, setMobile] = useState("");
  const [isEmailExists, setEmailExists] = useState(true);
  const [image, setImage] = useState("");
  const [placeId, setPlaceId] = useState("");
  const [address, setAddress] = useState("");
  const [location, setLocation] = useState("");
  const [showPredictions, setShowPredictions] = useState(false);
  const [error, setError] = useState({});

  const predictions = useAddressPredictions(location || " ");
  const geometry = useGeoCoder(placeId);

  useEffect(() => {
    if (!profile) {
      dispatch(getDetails());
    } else {
      const {
        first_name,
        last_name,
        email: userEmail,
        mobile: userMobile,
        country_code,
        address: userAddress,
        profileurl,
        company_name,
      } = profile;

      setFirstName(first_name);
      setLastName(last_name);
      setEmail(userEmail);
      setMobile(country_code + userMobile);
      setLocation(userAddress);
      setImage(profileurl);
      setCompany(company_name);
    }
  }, [dispatch, profile]);

  const validate = async () => {
    let errors = {};
    let isValid = true;

    if (!location) {
      isValid = false;
      errors.address = "Required";
    }

    if (!email) {
      isValid = false;
      errors.email = "Required";
    } else if (
      !isEmailExists &&
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)
    ) {
      isValid = false;
      errors.email = "Invalid email address.";
    }

    if (profile && profile.usertype === 3 && !company) {
      isValid = false;
      errors.company = "Required";
    } else if (
      profile &&
      profile.usertype === 3 &&
      company &&
      company.length < 3
    ) {
      isValid = false;
      errors.company = "Name length should be at least 3 characters.";
    }

    if (!firstName) {
      isValid = false;
      errors.firstName = "Required";
    } else if (firstName.length < 3) {
      isValid = false;
      errors.firstName = "Name length should be at least 3 characters.";
    }

    if (!lastName) {
      isValid = false;
      errors.lastName = "Required";
    } else if (lastName.length < 3) {
      isValid = false;
      errors.lastName = "Name length should be at least 3 characters.";
    }

    if (!image) {
      isValid = false;
      errors.image = "Profile image is required.";
    }

    setError(errors);
    return isValid;
  };

  const validateEmail = async (event) => {
    const value = event.target.value;
    const payload = {
      email: value,
      usertype: user === "customer" ? 2 : 3,
    };

    await dispatch(verifyEmail(payload)).then(
      ({ message, exists, status, errorMessage }) => {
        if (status === 200 && exists) {
          setError({ ...error, email: message });
        } else if (status !== 200 && errorMessage) {
          setError({ ...error, email: errorMessage });
        } else if (status === 200 && !exists) {
          setEmailExists(false);
        }
      }
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validate()) {
      var formdata = new FormData();
      formdata.append("first_name", firstName);
      formdata.append("last_name", lastName);
      formdata.append("email", email);
      if (user === "provider") {
        formdata.append("company_name", company);
      }
      formdata.append("address", address);
      formdata.append(
        "lat",
        geometry && geometry.lat ? geometry.lat : profile.latitude
      );
      formdata.append(
        "long",
        geometry && geometry.lng ? geometry.lng : profile.longitude
      );
      formdata.append("profileimage", image);

      dispatch(updateProfile(formdata)).then(
        ({ message, errorMessage, status }) => {
          if (status === 200) {
            // dispatch(getDetails());
            enqueueSnackbar(message || "Success", {
              variant: "success",
            });
          } else {
            enqueueSnackbar(errorMessage || "Error", {
              variant: "error",
            });
          }
        }
      );
    }
  };

  const handleImageUpload = (event) => {
    setError({ ...error, image: "" });
    const file = event.target.files[0];
    if (file.type.split("/")[0] !== "image") {
      enqueueSnackbar("Only image files are accepted", { variant: "error" });
    } else {
      setImage(
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );
    }
  };

  return (
    <Fragment>
      <div className="row align-items-center">
        <div className="col-3">
          {/* <span className="ti-angle-left h3 text-dark"></span> */}
        </div>
        <div className="col-sm-6 text-center">
          <p className="h3 text-gray">My Profile</p>
        </div>
      </div>

      <div className="row justify-content-center my-5">
        <div className="col-lg-10 col-xl-8">
          <form
            className="w-100 px-5 align-self-center"
            onSubmit={handleSubmit}
          >
            <div className="round-box px-5">
              <div className="form-group text-center">
                <div className="avatar-upload">
                  <div className="avatar-edit">
                    <input
                      type="file"
                      id="imageUpload"
                      accept=".png, .jpg, .jpeg"
                      onChange={handleImageUpload}
                    />
                    <label htmlFor="imageUpload">
                      <i className="i25px icamera"></i>
                    </label>
                  </div>
                  <div className="avatar-preview">
                    <div
                      id="imagePreview"
                      style={{
                        backgroundImage: image
                          ? `url(${
                              image.preview
                                ? image.preview
                                : `${basePath}/${image}`
                            })`
                          : `url(${avatar_png})`,
                      }}
                    ></div>
                  </div>
                </div>
                <span className="error">{error.image}</span>
              </div>
              <div className="form-group">
                <div className="custom-group-input rounded-digonal left-icon">
                  <input
                    type="text"
                    className="form-control "
                    placeholder="First Name"
                    minLength={3}
                    maxLength={30}
                    onInput={() => setError({ ...error, firstName: "" })}
                    required
                    value={firstName || ""}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                  <span className="group-icon-left ">
                    <i className="i25px iuserman"></i>
                  </span>
                </div>
                <span className="error">{error.firstName}</span>
              </div>
              <div className="form-group">
                <div className="custom-group-input rounded-digonal left-icon">
                  <input
                    type="text"
                    className="form-control "
                    placeholder="Last Name"
                    minLength={3}
                    maxLength={30}
                    required
                    value={lastName || ""}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                  <span className="group-icon-left ">
                    <i className="i25px iuserman"></i>
                  </span>
                </div>
                <span className="error">{error.lastName}</span>
              </div>
              <div className="form-group">
                <div className="custom-group-input rounded-digonal left-icon">
                  <input
                    type="email"
                    className="form-control "
                    placeholder="Email Address"
                    onInput={() => setError({ ...error, email: "" })}
                    onBlur={validateEmail}
                    required
                    value={email || ""}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <span className="group-icon-left ">
                    <i className="i25px iuserman"></i>
                  </span>
                </div>
                <span className="error">{error.email}</span>
              </div>

              {company && (
                <div className="form-group">
                  <div className="custom-group-input rounded-digonal left-icon">
                    <input
                      type="text"
                      className="form-control "
                      placeholder="Company Name"
                      onInput={() => setError({ ...error, company: "" })}
                      required
                      value={company || ""}
                      onChange={(e) => setCompany(e.target.value)}
                    />
                    <span className="group-icon-left ">
                      <i className="i25px iuserman"></i>
                    </span>
                  </div>
                  <span className="error">{error.company}</span>
                </div>
              )}

              <div className="form-group">
                <div className="custom-group-input rounded-digonal">
                  <PhoneInput
                    className="form-control"
                    placeholder="Mobile Number"
                    international
                    defaultCountry="AU"
                    countryCallingCodeEditable={false}
                    initialValueFormat="national"
                    disabled
                    value={mobile || ""}
                    onChange={setMobile}
                  />
                </div>
                <span className="error">{error.mobile}</span>
              </div>

              <div className="form-group" style={{ position: "relative" }}>
                <div className="custom-group-input rounded-digonal left-icon">
                  <input
                    type="text"
                    className="form-control "
                    placeholder="Address"
                    required
                    onInput={() => setError({ ...error, address: "" })}
                    value={location || ""}
                    onChange={(e) => {
                      let value = e.target.value;
                      setLocation(value);
                      setShowPredictions(true);
                    }}
                  />
                  <span className="group-icon-left ">
                    <i className="i25px ilcoation"></i>
                  </span>
                </div>
                <span className="error">{error.address}</span>
                {showPredictions && (
                  <ul className="predictions">
                    {predictions.map((prediction, index) => (
                      <li
                        key={index}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setPlaceId(prediction.placeId);
                          setAddress(prediction.description);
                          setLocation(prediction.description);
                          setShowPredictions(false);
                        }}
                      >
                        {prediction.description}
                      </li>
                    ))}
                  </ul>
                )}
              </div>

              <div className="form-group mt-5">
                <button
                  type="submit"
                  className="btn btn-primary btn-block btn-feet --blue btn-lg btn-block rounded-pill"
                >
                  {loading ? "Saving..." : "Save"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

export default MyProfile;
