import React, { Fragment, useEffect } from "react";
import { HashRouter as Router, Switch, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { SnackbarProvider } from "notistack";

import "./App.css";

import { getDetails } from "./features/accounts/slice";
import publicRoutes from "./routes";
import Error from "./components/error";
import Header from "./components/header";
import Footer from "./components/footer";
import ServiceProvider from "./features/customer/services";
import CustomerDashboard from "./features/customer/dashboard";
import ProfileSetup from "./features/provider/profileSetup";
import ProviderDashboard from "./features/provider/dashboard";
import Stripe from "./features/provider/stripe";

function App() {
  const dispatch = useDispatch();

  const { isLoggedIn, user } = useSelector((state) => {
    return {
      isLoggedIn: state.accounts.loggedIn,
      user: state.accounts.user,
    };
  });

  useEffect(() => {
    if (!isLoggedIn && localStorage && localStorage.getItem("phgr")) {
      dispatch(getDetails());
    }
  }, [dispatch, isLoggedIn]);

  return (
    <Fragment>
      <Router>
        <SnackbarProvider
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <Header />
          <Switch>
            <Route exact path="/get_account">
              <Stripe />
            </Route>
            {publicRoutes.map((route, index) => (
              // Render more <Route>s with the same paths as
              // above, but different components this time.
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
                children={<route.main />}
              />
            ))}
            {isLoggedIn && user === "Customer" && (
              <Route path="/dashboard">
                <CustomerDashboard />
              </Route>
            )}
            {isLoggedIn && user === "Provider" && (
              <Route path="/dashboard">
                <ProviderDashboard />
              </Route>
            )}
            {isLoggedIn && user === "Provider" && (
              <Route path="/profile-setup">
                <ProfileSetup />
              </Route>
            )}
            {isLoggedIn && user === "Customer" && (
              <Route path="/services">
                <ServiceProvider />
              </Route>
            )}
            <Route path="*">
              <Error />
            </Route>
          </Switch>
          <Footer />
        </SnackbarProvider>
      </Router>
    </Fragment>
  );
}

export default App;
