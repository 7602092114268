import React, { Fragment, useState } from "react";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import avatar_png from "../../../../../images/home/avatar.png";

import { useInputValue } from "../../../../../app/hooks";
import { addEditPet } from "../../slice";

const baseURL = process.env.REACT_APP_PAW_HUGGER_WEB_BASE_URL;

const AddEditPet = () => {
  const { goBack, replace } = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { petID } = useParams();
  const dispatch = useDispatch();

  const { loading, data } = useSelector((state) => {
    return {
      loading: state.dashboard.loading,
      data: state.dashboard.petDetails,
    };
  });

  const name = useInputValue(data ? data.pet_name : "");
  const age = useInputValue(data ? data.pet_age : "");
  const preferences = useInputValue(data ? data.personel_prefrence : "");
  const dislikes = useInputValue(data ? data.dont_like_things : "");

  const [image, setImage] = useState(data ? data.pet_image : "");
  const [error, setError] = useState({});

  const validate = () => {
    let errors = {};
    let isValid = true;

    if (!dislikes.value) {
      isValid = false;
      errors.dislikes = "Required";
    }

    if (!preferences.value) {
      isValid = false;
      errors.preferences = "Required";
    }

    if (!age.value) {
      isValid = false;
      errors.age = "Required";
    }

    if (!name.value) {
      isValid = false;
      errors.name = "Required";
    } else if (name.value.length < 3) {
      isValid = false;
      errors.name = "Name length should be at least 3 characters.";
    }

    if (!image) {
      isValid = false;
      errors.image = "Pet image is required.";
    }

    setError(errors);
    return isValid;
  };

  const handleImageUpload = (event) => {
    setError({ ...error, image: "" });
    const file = event.target.files[0];
    if (file.type.split("/")[0] !== "image") {
      enqueueSnackbar("Only image files are accepted", { variant: "error" });
    } else {
      setImage(
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validate()) {
      var formdata = new FormData();
      formdata.append("pet_name", name.value);
      formdata.append("pet_age", age.value);
      formdata.append("personel_prefrence", preferences.value);
      formdata.append("dont_like_things", dislikes.value);

      if (image.preview) {
        formdata.append("pet_image", image);
      }

      if (petID) {
        formdata.append("pet_id", petID);
      }

      dispatch(
        addEditPet({ payload: formdata, uri: petID ? "/edit_pet" : "/add_pet" })
      ).then(({ message, errorMessage, status }) => {
        if (status === 200) {
          enqueueSnackbar(message || "Success", {
            variant: "success",
          });
          replace(`/dashboard/my-pets`);
        } else {
          enqueueSnackbar(errorMessage || "Error", {
            variant: "error",
          });
        }
      });
    }
  };

  return (
    <Fragment>
      <div className="row align-items-center">
        <div className="col-3">
          <span
            className="ti-angle-left h3 text-dark"
            onClick={goBack}
            style={{ cursor: "pointer" }}
          ></span>
        </div>
        <div className="col-sm-6 text-center">
          <p className="h3 text-gray">Add New Pet</p>
        </div>
      </div>

      <div className="row justify-content-center my-5">
        <div className="col-lg-10 col-xl-8">
          <form onSubmit={handleSubmit}>
            <div className="round-box px-5">
              <div className="form-group">
                <div className="avatar-upload">
                  <div className="avatar-edit">
                    <input
                      type="file"
                      id="imageUpload"
                      accept=".png, .jpg, .jpeg"
                      onChange={handleImageUpload}
                    />
                    <label htmlFor="imageUpload">
                      <i className="i25px icamera"></i>
                    </label>
                  </div>
                  <div className="avatar-preview">
                    <div
                      id="imagePreview"
                      style={{
                        backgroundImage: image
                          ? image.preview
                            ? `url(${image.preview})`
                            : `url(${baseURL}/${image})`
                          : `url(${avatar_png})`,
                      }}
                    ></div>
                  </div>
                </div>
                <span className="error">{error.image}</span>
              </div>
              <div className="row mt-5">
                <div className="form-group mb-xl-5 col-md-6">
                  <div className="custom-group-input rounded-digonal left-icon">
                    <input
                      type="text"
                      className="form-control "
                      placeholder="Dog Name"
                      {...name}
                      onInput={() => setError({ ...error, name: "" })}
                    />
                    <span className="group-icon-left ">
                      <i className="i30px ibookdog-type"></i>
                    </span>
                  </div>
                  <span className="error">{error.name}</span>
                </div>
                <div className="form-group mb-xl-5 col-md-6">
                  <div className="custom-group-input rounded-digonal left-icon">
                    <input
                      type="text"
                      className="form-control "
                      placeholder="Dog Age"
                      {...age}
                      onInput={() => setError({ ...error, age: "" })}
                    />
                    <span className="group-icon-left ">
                      <i className="i30px ibookage-cake"></i>
                    </span>
                  </div>
                  <span className="error">{error.age}</span>
                </div>

                <div className="form-group mb-xl-5 col-md-6">
                  <div className="custom-group-input rounded-digonal left-icon">
                    <input
                      type="text"
                      className="form-control "
                      placeholder="Personal Preferences"
                      {...preferences}
                      onInput={() => setError({ ...error, preferences: "" })}
                    />
                    <span className="group-icon-left ">
                      <i className="i30px ibookfeatures-dog"></i>
                    </span>
                  </div>
                  <span className="error">{error.preferences}</span>
                </div>

                <div className="form-group mb-xl-5 col-md-6">
                  <div className="custom-group-input rounded-digonal left-icon">
                    <input
                      type="text"
                      className="form-control "
                      placeholder="Things that dog doesn't like"
                      {...dislikes}
                      onInput={() => setError({ ...error, dislikes: "" })}
                    />
                    <span className="group-icon-left ">
                      <i className="i30px ibookfeatures-dog"></i>
                    </span>
                  </div>
                  <span className="error">{error.dislikes}</span>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-md-6">
                  <div className="form-group mt-4">
                    <button
                      type="submit"
                      className="btn btn-primary btn-block btn-feet --blue btn-lg btn-block rounded-pill"
                    >
                      {loading ? "Adding..." : "Add Pet"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

export default AddEditPet;
