import React, { memo, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { useSnackbar } from "notistack";

import avatar from "../../../../../../images/avatar.png";
import avatar_png from "../../../../../../images/home/avatar.png";
import cancel_png from "../../../../../../images/provider/cancell.png";

import { useToggle } from "../../../../../../app/hooks";
import { addTrackingImage, removeTrackingImage } from "../../../../slice";

const baseURL = process.env.REACT_APP_PAW_HUGGER_WEB_BASE_URL;

const SendUpdate = memo(() => {
  const { goBack } = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const [open, toggle] = useToggle(true);

  const [images, setImage] = useState([]);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file.type.split("/")[0] !== "image") {
      enqueueSnackbar("Only image files are accepted", { variant: "error" });
    } else {
      var formdata = new FormData();
      formdata.append("device_type", "3");
      formdata.append("track_image", file);

      dispatch(addTrackingImage(formdata)).then(
        ({ message, errorMessage, status, data }) => {
          if (status === 200) {
            setImage([...images, data]);
            enqueueSnackbar(message || "Success", {
              variant: "success",
            });
          } else {
            enqueueSnackbar(errorMessage || "Error", {
              variant: "error",
            });
          }
        }
      );
    }
  };

  const handleRemovePhoto = (track_image_id) => {
    dispatch(removeTrackingImage({ track_image_id })).then(
      ({ message, status, errorMessage }) => {
        if (status === 200) {
          enqueueSnackbar(message || "Success", {
            variant: "success",
          });
          const newImages = images.filter((el) => el._id !== track_image_id);
          setImage(newImages);
        } else {
          enqueueSnackbar(errorMessage || "Error", {
            variant: "error",
          });
        }
      }
    );
  };

  const handleHide = () => {
    toggle();
    goBack();
  };

  return (
    <Modal
      show={open}
      onHide={handleHide}
      size="lg"
      id="send-update-modal"
      className="modal form-popup "
      aria-labelledby="exampleModalLabel"
    >
      <div className="modal-body ">
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={handleHide}
        ></button>
        <div className="row no-gutters justify-content-center">
          <div className="col-sm-10 py-4  text-center">
            <h4>Send Update</h4>
            <h5 className="text-primary">To Shelly Holt</h5>
            <div className="form-group">
              <div className="avatar-upload">
                <div className="avatar-edit">
                  <input
                    type="file"
                    id="imageUpload"
                    accept=".png, .jpg, .jpeg"
                    onChange={handleImageUpload}
                  />
                  <label htmlFor="imageUpload">
                    <i className="fas fa-camera"></i>
                  </label>
                </div>
                <div className="avatar-preview">
                  <div
                    id="imagePreview"
                    style={{
                      backgroundImage: `url(${avatar_png})`,
                    }}
                  ></div>
                </div>
              </div>
            </div>
            <ul className="add-photos w-100 row mb-4">
              {images && images.length
                ? images.map((el) => (
                    <li className="col-3 m-0" key={el._id}>
                      <div>
                        <button
                          className="p-2"
                          onClick={() => handleRemovePhoto(el._id)}
                        >
                          <img width="30px" src={cancel_png} alt="removeicon" />
                        </button>
                        <img
                          src={
                            el.image_path
                              ? `${baseURL}/${el.image_path}`
                              : avatar
                          }
                          className="img-fluid serviceImg"
                          alt="img"
                        />
                      </div>
                    </li>
                  ))
                : null}
            </ul>
            <div className="col-10 col-sm-8 mx-auto">
              <button
                type="button"
                className="btn my-5 btn-primary btn-feet --blue btn-lg btn-block rounded-pill"
                onClick={handleHide}
              >
                Send
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
});

export default SendUpdate;
