import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";

import avatar_png from "../../../../images/avatar.png";
import book_dog_png from "../../../../images/book-dog.png";

import { useToggle } from "../../../../app/hooks";
import { getMyPets, setPetDetails } from "../../dashboard/slice";
import { requestBooking } from "../slice";

const baseURL = process.env.REACT_APP_PAW_HUGGER_WEB_BASE_URL;

const SelectDog = () => {
  const { goBack, push, replace } = useHistory();
  const { providerID } = useParams();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const { loading, pets, formData, service, requesting } = useSelector(
    (state) => {
      return {
        loading: state.dashboard.loading,
        pets: state.dashboard.myPets,
        service: state.services.details,
        formData: state.services.formData,
        requesting: state.services.loading,
      };
    }
  );

  const [open, toggle] = useToggle(true);
  const [petID, setPetID] = useState("");

  useEffect(() => {
    if (!pets) {
      dispatch(getMyPets({ page: 1 }));
    }
  }, [pets, dispatch]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (petID) {
      let payload = { ...formData };
      payload.pet_id = petID;

      dispatch(requestBooking(payload)).then(
        ({ message, errorMessage, status }) => {
          if (status === 200) {
            // enqueueSnackbar(message || "Success", {
            //   variant: "success",
            // });
            replace(`/services/${providerID}/success`);
          } else {
            enqueueSnackbar(errorMessage || "Error", {
              variant: "error",
            });
          }
        }
      );
    } else {
      enqueueSnackbar("Please select a pet.", { variant: "error" });
    }
  };

  const handleHide = () => {
    toggle();
    goBack();
  };

  return (
    <Modal
      show={open}
      onHide={handleHide}
      size="xl"
      id="bookingSelectDogModal"
      className="modal form-popup "
      aria-labelledby="exampleModalLabel"
    >
      <div className="modal-body ">
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={handleHide}
        ></button>
        <div className="row no-gutters justify-content-end">
          <div className="col-12 form-box d-flex justify-content-center">
            <form
              className="w-100 py-5 align-self-center"
              onSubmit={handleSubmit}
            >
              <p className="text-gray mb-5 h4  text-capitalize">Select Dog </p>

              <div className="row justify-content-center">
                <div className="col-lg-4">
                  <div className="provider-profile mb-4 d-flex">
                    <img
                      src={
                        service && service.profileurl
                          ? `${baseURL}/${service.profileurl}`
                          : avatar_png
                      }
                      className="avatar"
                      alt="img"
                    />
                    <div>
                      <p className="text-gray mb-0">Selected</p>
                      <p className="name h5">
                        {service &&
                          service &&
                          service.first_name + " " + service.last_name}
                      </p>
                      <p className="price h4 text-primary">
                        $
                        {service &&
                          service.providerServicedata &&
                          service.providerServicedata.price}
                        <span> / hr</span>
                      </p>
                    </div>
                  </div>
                  <div className="px-4">
                    <button
                      type="submit"
                      className="btn mt-5 btn-primary btn-feet --blue btn-lg btn-block rounded-pill"
                    >
                      {requesting ? "Sending..." : "Send Request"}
                    </button>
                    <Link
                      to="/dashboard/my-pets/new"
                      className="btn my-5 btn-light-primary btn-feet --light-blue btn-lg btn-block rounded-pill"
                    >
                      <span className="d-inline-flex">
                        <i className="i40px iprofadd-feet"></i> Add New Pet
                      </span>
                    </Link>
                  </div>
                </div>
                <div className="col-lg-8">
                  <div className="row selectdog">
                    {loading
                      ? "Loading..."
                      : pets && pets.data && pets.data.length
                      ? pets.data.map((el) => (
                          <div className="col-6 col-md-4" key={el._id}>
                            <input
                              type="radio"
                              name="selectdog"
                              id={el._id}
                              checked={petID === el._id}
                              onChange={() => setPetID(el._id)}
                            />
                            <label htmlFor={el._id}>
                              <img
                                src={
                                  el.pet_image
                                    ? `${baseURL}/${el.pet_image}`
                                    : book_dog_png
                                }
                                className="avatarimg"
                                alt="img"
                              />
                              <h6 className="mb-1">{el.pet_name}</h6>
                              <p>
                                <i className="i25px iprofcake"></i> {el.pet_age}{" "}
                                old
                              </p>
                              <button
                                className="edit"
                                onClick={() => {
                                  dispatch(setPetDetails(el));
                                  push(`/dashboard/my-pets/${el._id}`);
                                }}
                              >
                                <i className="i30px iprofedit"></i>
                              </button>
                            </label>
                          </div>
                        ))
                      : null}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SelectDog;
