import React, { Fragment, useEffect } from "react";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import book_dog_png from "../../../../images/book-dog.png";

import SimpleBackdrop from "../../../../components/backdrop";
import { deletePet, getMyPets, setPetDetails } from "../slice";

const baseURL = process.env.REACT_APP_PAW_HUGGER_WEB_BASE_URL;

const MyPets = () => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const { loading, pets } = useSelector((state) => {
    return {
      loading: state.dashboard.loading,
      pets: state.dashboard.myPets,
    };
  });

  useEffect(() => {
    if (!pets) {
      dispatch(getMyPets({ page: 1 }));
    }
  }, [pets, dispatch]);

  const handleDelete = (petID) => {
    if (petID) {
      dispatch(deletePet({ petid: petID })).then(
        ({ errorMessage, status, message }) => {
          if (status === 200) {
            enqueueSnackbar(message || "Success", {
              variant: "success",
            });
          } else {
            enqueueSnackbar(errorMessage || "Error", {
              variant: "error",
            });
          }
        }
      );
    }
  };

  return (
    <Fragment>
      <div className="row justify-content-between align-items-center">
        <div className="col-sm-7">
          <p className="h4 text-gray">My Pets</p>
        </div>
        <div className="col-auto text-right">
          <Link
            to="/dashboard/my-pets/new"
            className="btn px-5 my-4 btn-primary d-flex justify-content-center btn-feet --blue btn-lg rounded-pill"
          >
            <span className="d-inline-flex align-self-center">
              <i className="i35px float-left iprofadd-feet invert-white"></i>
              Add New Pet
            </span>
          </Link>
        </div>
      </div>

      <div className="row mb-5  rowMarginHalf colpad-half">
        {pets && pets.data && pets.data.length ? (
          pets.data.map((el) => (
            <div className="col-sm-6 col-xl-4" key={el._id}>
              <div className="mypet-box">
                <div className="row rowMarginHalf colpad-half">
                  <div className="col-3 pr-0 col-md-4">
                    <img
                      src={
                        el.pet_image
                          ? `${baseURL}/${el.pet_image}`
                          : book_dog_png
                      }
                      className="img-fluid"
                      alt="img"
                    />
                  </div>
                  <div className="col-9 col-md-8">
                    <h4>{el.pet_name}</h4>
                    <p className="d-flex align-items-center">
                      <i className="iprofcake i25px mr-2"></i>
                      {el.pet_age} old
                    </p>
                    <div className="row rowMarginHalf colpad-half">
                      <div className="col-6">
                        <button
                          type="button"
                          className="btn btn-block btn-light-primary d-flex justify-content-center align-items-center rounded-pill"
                          onClick={() => handleDelete(el._id)}
                        >
                          <i className="ti-trash mr-1"></i>Delete
                        </button>
                      </div>
                      <div className="col-6">
                        <button
                          type="button"
                          className="btn btn-block btn-primary d-flex justify-content-center align-items-center rounded-pill"
                          onClick={() => {
                            dispatch(setPetDetails(el));
                            push(`/dashboard/my-pets/${el._id}`);
                          }}
                        >
                          <i className="ti-pencil mr-1"></i>Edit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <span>You don't have any pet.</span>
        )}
        {pets && Number(pets.pages) > Number(pets.cpage) && (
          <span
            onClick={() =>
              dispatch(getMyPets({ page: pets.cpage + 1, more: true }))
            }
          >
            get more...
          </span>
        )}
        {loading && <SimpleBackdrop />}
      </div>
    </Fragment>
  );
};

export default MyPets;
