import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Slider from "@material-ui/core/Slider";
import { useDispatch } from "react-redux";
import { getAvailableProviders } from "../../slice";
import { reverseDate } from "../../../../../utils";
import { useSnackbar } from "notistack";

const useStyles = makeStyles({
  root: {
    width: 300,
  },
});

function costText(value) {
  return `$ ${value}`;
}

function yearText(value) {
  return `${value} yrs`;
}

const Filter = ({
  serviceID,
  startDate,
  endDate,
  geometry,
  current,
  lat,
  lng,
}) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const [cost, setCost] = useState([10, 115]);
  const [experience, setExperience] = useState([10, 37]);
  const [rating, setRating] = useState(1);

  const handleCost = (event, newValue) => {
    setCost(newValue);
  };

  const handleExperience = (event, newValue) => {
    setExperience(newValue);
  };

  const handleRating = (newValue) => {
    setRating(newValue);

    handleSubmit();
  };

  function getClassName(level) {
    if (rating >= level) {
      return "i40px iratingstar active";
    } else if (rating === level - 0.5) {
      return "fas fa-star-half-alt";
    } else {
      return "i40px iratingstar";
    }
  }

  const handleSubmit = (e) => {
    if (serviceID) {
      let query = "?cpage=1&perpage=10";

      if (serviceID) {
        query = query + `&service_id=${serviceID}`;
      }

      // if (current) {
      //   query = query + `&current_lat=${lat}&current_lng=${lng}`;
      // } else {
      //   query =
      //     query + `&current_lat=${geometry.lat}&current_lng=${geometry.lng}`;
      // }

      if (startDate) {
        query = query + `&start_date=${reverseDate(startDate)}`;
      }

      if (endDate) {
        query = query + `&end_date=${reverseDate(endDate)}`;
      }

      if (cost && cost.length) {
        query = query + `&price_min=${cost[0]}&price_max=${cost[1]}`;
      }

      if (experience && experience.length) {
        query = query + `&exp_min=${experience[0]}&exp_max=${experience[1]}`;
      }

      if (rating) {
        query = query + `&rating=${rating}`;
      }

      setTimeout(() => {
        dispatch(getAvailableProviders({ query }));
      }, 3000);
    } else {
      enqueueSnackbar("Pleas select a service from above search bar.", {
        variant: "info",
      });
    }
  };

  return (
    <div className=" col-md-8 col-lg-4 ">
      <div className="service-filter my-5 shadow">
        <h3 className="text-black font-weight-normal"> Filter </h3>

        <div className="py-3 mt-4">
          <span className="text-gray">Service Cost</span>
          <label className="float-right text-primary range-unit">
            ${cost[0]} - ${cost[1]}
          </label>
        </div>
        {/* <div className="range-slider py-3"></div> */}

        <div className={classes.root}>
          <Slider
            min={10}
            max={500}
            value={cost}
            onChange={handleCost}
            valueLabelDisplay="auto"
            aria-labelledby="range-slider"
            getAriaValueText={costText}
            onChangeCommitted={handleSubmit}
          />
        </div>

        <div className="py-3 mt-4">
          <span className="text-gray">Experience</span>
          <label className="float-right text-primary year-unit">
            {experience[0]} - {experience[1]} years
          </label>
        </div>
        {/* <div className="year-slider py-3"></div> */}

        <div className={classes.root}>
          <Slider
            min={0}
            max={99}
            value={experience}
            onChange={handleExperience}
            valueLabelDisplay="auto"
            aria-labelledby="range-slider"
            getAriaValueText={yearText}
            onChangeCommitted={handleSubmit}
          />
        </div>

        <div className="ratings mt-5">
          <p className="text-gray">Rating</p>
          <i className={getClassName(1)} onClick={() => handleRating(1)}></i>
          <i className={getClassName(2)} onClick={() => handleRating(2)}></i>
          <i className={getClassName(3)} onClick={() => handleRating(3)}></i>
          <i className={getClassName(4)} onClick={() => handleRating(4)}></i>
          <i className={getClassName(5)} onClick={() => handleRating(5)}></i>
        </div>
      </div>
      {/* <div className="form-group col-sm-6 col-lg-3  col-xl">
        <button
          type="button"
          className="btn btn-primary btn-feet --blue btn-lg btn-block rounded-pill"
          onClick={handleSubmit}
        >
          {loading ? "Filtering..." : "Filter"}
        </button>
      </div> */}
    </div>
  );
};

export default Filter;
