import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  NavLink,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from "react-router-dom";
import { getMyBookings } from "../slice";
import TrackingDetails from "../track";

import CancelledBooking from "./cancelled";
import CompletedBooking from "./completed";
import BookingDetails from "./details";
import OngoingBooking from "./ongoing";
import PendingBooking from "./pending";
import UpcomingBooking from "./upcoming";

const MyBookings = () => {
  let { path, url } = useRouteMatch();
  const dispatch = useDispatch();
  const {
    push,
    location: { pathname },
  } = useHistory();

  const { pending, completed, ongoing, cancelled, upcoming } = useSelector(
    (state) => {
      return {
        pending: state.dashboard.pending,
        completed: state.dashboard.completed,
        ongoing: state.dashboard.ongoing,
        cancelled: state.dashboard.cancelled,
        upcoming: state.dashboard.upcoming,
      };
    }
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!ongoing) {
      dispatch(getMyBookings({ page: 1, type: 2 })).then(({ status }) => {
        if (
          status === 200 &&
          pathname !== "/dashboard/my-bookings/pending" &&
          pathname !== "/dashboard/my-bookings/ongoing"
        ) {
          push("/dashboard/my-bookings/ongoing");
        }
      });
    }
  }, [ongoing, dispatch, push, pathname]);

  return (
    <Fragment>
      <ul className="nav booking-tabs mb-3" id="myTab" role="tablist">
        <li className="nav-item">
          <NavLink
            className="nav-link"
            data-toggle="tab"
            to={`${url}/pending`}
            role="tab"
            onClick={() => dispatch(getMyBookings({ page: 1, type: 1 }))}
          >
            <i className="i30px ibookpending-blue"></i>Pending
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            className="nav-link"
            data-toggle="tab"
            to={`${url}/upcoming`}
            role="tab"
            onClick={() => dispatch(getMyBookings({ page: 1, type: 5 }))}
          >
            <i className="i30px iupcoming"></i>Upcoming
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            className="nav-link"
            data-toggle="tab"
            to={`${url}/ongoing`}
            role="tab"
            onClick={() => dispatch(getMyBookings({ page: 1, type: 2 }))}
          >
            <i className="i30px ibookongoing-blue"></i>Ongoing
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            className="nav-link"
            data-toggle="tab"
            to={`${url}/completed`}
            role="tab"
            onClick={() => dispatch(getMyBookings({ page: 1, type: 3 }))}
          >
            <i className="i30px ibookcompleted-blue"></i>Completed
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            className="nav-link"
            data-toggle="tab"
            to={`${url}/cancelled`}
            role="tab"
            onClick={() => dispatch(getMyBookings({ page: 1, type: 4 }))}
          >
            <i className="i30px ibookcancelled-blue"></i>Cancelled
          </NavLink>
        </li>
      </ul>
      <Switch>
        <Route exact path={`${path}/pending`}>
          <PendingBooking list={pending} />
        </Route>
        <Route exact path={`${path}/upcoming`}>
          <UpcomingBooking list={upcoming} />
        </Route>
        <Route exact path={`${path}/ongoing`}>
          <OngoingBooking list={ongoing} />
        </Route>
        <Route exact path={`${path}/completed`}>
          <CompletedBooking list={completed} />
        </Route>
        <Route exact path={`${path}/cancelled`}>
          <CancelledBooking list={cancelled} />
        </Route>
        <Route exact path={`${path}/:status/details/:bookingID`}>
          <BookingDetails />
        </Route>
        <Route exact path={`${path}/ongoing/track/:bookingID`}>
          <TrackingDetails />
        </Route>
      </Switch>
    </Fragment>
  );
};

export default MyBookings;
