import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";

import profile_step_active_png from "../../../images/provider/profile-step-active.png";
import profile_step_png from "../../../images/provider/profile-step.png";

import { getProfileData, selectProfileData } from "../slice";
import Availability from "./availability";
import Experience from "./experience";
import Services from "./services";

const ProfileSetup = () => {
  let { path } = useRouteMatch();
  let { pathname } = useLocation();
  const { push } = useHistory();

  const dispatch = useDispatch();
  const data = useSelector(selectProfileData);

  useEffect(() => {
    if (!data) {
      dispatch(getProfileData());
    }
  }, [dispatch, data]);

  return (
    <main className="page-wrapper">
      <div className="my-accounts mt-5 pt-5">
        <div className="container pt-xl-3">
          <h4 className="text-center my-4">Profile Setup</h4>
          <div className="col-lg-6 mx-auto profile-progress">
            <div className="row">
              <div className="col">
                <img
                  src={profile_step_active_png}
                  alt="img"
                  style={{
                    cursor:
                      data && data.providerServices ? "pointer" : "default",
                  }}
                  onClick={() =>
                    data && data.providerServices
                      ? push("/profile-setup/services")
                      : null
                  }
                />
              </div>
              <div className="col text-center">
                <img
                  src={
                    pathname === "/profile-setup/experience" ||
                    pathname === "/profile-setup/availability"
                      ? profile_step_active_png
                      : profile_step_png
                  }
                  alt="img"
                  style={{
                    cursor: data ? "pointer" : "default",
                  }}
                  onClick={() =>
                    data ? push("/profile-setup/experience") : null
                  }
                />
              </div>
              <div className="col text-right">
                <img
                  src={
                    pathname === "/profile-setup/availability"
                      ? profile_step_active_png
                      : profile_step_png
                  }
                  alt="img"
                  style={{
                    cursor: data && data.workingday ? "pointer" : "default",
                  }}
                  onClick={() =>
                    data && data.workingday
                      ? push("/profile-setup/availability")
                      : null
                  }
                />
              </div>
            </div>
          </div>
        </div>

        <Switch>
          <Route exact path={`${path}/services`}>
            <Services data={data && data.providerServices} />
          </Route>
          <Route exact path={`${path}/availability`}>
            <Availability data={data && data.workingday} />
          </Route>
          <Route exact path={`${path}/experience`}>
            <Experience
              data={
                data && {
                  providerServiceimages: data.providerServiceimages,
                  service_words: data.service_words,
                  skills: data.skills,
                }
              }
            />
          </Route>
        </Switch>
      </div>
    </main>
  );
};

export default ProfileSetup;
