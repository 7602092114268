/* eslint-disable linebreak-style */
/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */

import { createSlice } from "@reduxjs/toolkit";
import { axiosGet, postEncoded, postForm } from "../../api";

const initialState = {
  loading: false,
  error: null,
};

const provider = createSlice({
  name: "provider",
  initialState,
  reducers: {
    loadingStart(state) {
      state.loading = true;
      state.error = null;
    },
    loadingStop(state) {
      state.loading = false;
    },

    // Profile

    setProfileData(state, action) {
      state.profileData = action.payload;
      state.error = null;
      state.loading = null;
    },

    // Revenue

    setRevenue(state, action) {
      const { data, more } = action.payload;
      if (more && state.revenue) {
        state.revenue = {
          ...data,
          data: [...state.revenue.data, ...data.data],
        };
      } else {
        state.revenue = data;
      }
      state.loading = false;
      state.error = null;
    },

    // Bookings

    setNewRequests(state, action) {
      const { data, more } = action.payload;
      if (more && state.newRequests) {
        state.newRequests = {
          ...data,
          data: [...state.newRequests.data, ...data.data],
        };
      } else {
        state.newRequests = data;
      }
      state.loading = false;
      state.error = null;
    },
    setCompletedBookings(state, action) {
      const { data, more } = action.payload;
      if (more && state.completed) {
        state.completed = {
          ...data,
          data: [...state.completed.data, ...data.data],
        };
      } else {
        state.completed = data;
      }
      state.loading = false;
      state.error = null;
    },
    setOngoingBookings(state, action) {
      const { data, more } = action.payload;
      if (more && state.ongoing) {
        state.ongoing = {
          ...data,
          data: [...state.ongoing.data, ...data.data],
        };
      } else {
        state.ongoing = data;
      }
      state.loading = false;
      state.error = null;
    },
    setCancelledBookings(state, action) {
      const { data, more } = action.payload;
      if (more && state.cancelled) {
        state.cancelled = {
          ...data,
          data: [...state.cancelled.data, ...data.data],
        };
      } else {
        state.cancelled = data;
      }
      state.loading = false;
      state.error = null;
    },
    setUpcomingBookings(state, action) {
      const { data, more } = action.payload;
      if (more && state.upcoming) {
        state.upcoming = {
          ...data,
          data: [...state.upcoming.data, ...data.data],
        };
      } else {
        state.upcoming = data;
      }
      state.loading = false;
      state.error = null;
    },
    updateRequest(state, action) {
      state.newRequests = {
        ...state.newRequests,
        data: state.newRequests.data.filter(
          (el) => el.booking_id !== action.payload.booking_id
        ),
      };
    },
    removeCompletedBooking(state, action) {
      state.completed = {
        ...state.completed,
        data: state.completed.data.filter(
          (el) => el.booking_id !== action.payload.booking_id
        ),
      };
    },
    removeCancelledBooking(state, action) {
      state.cancelled = {
        ...state.cancelled,
        data: state.cancelled.data.filter(
          (el) => el.booking_id !== action.payload.booking_id
        ),
      };
    },
    startUpcomingService(state, action) {
      state.upcoming = {
        ...state.upcoming,
        data: state.upcoming.data.filter(
          (el) => el.booking_id !== action.payload.booking_id
        ),
      };
    },
    setBookingDetails(state, action) {
      state.bookingDetails = action.payload;
      state.loading = false;
      state.error = null;
    },

    // Availability

    setAvailability(state, action) {
      state.availability = action.payload;
      state.loading = false;
      state.error = null;
    },
    setServices(state, action) {
      state.services = action.payload;
      state.loading = false;
      state.error = null;
    },
    setStripeAccount(state, action) {
      state.account = action.payload;
      state.loading = false;
      state.error = null;
    },
  },
});

export const {
  loadingStart,
  loadingStop,
  setAvailability,
  setUpcomingBookings,
  setOngoingBookings,
  setCancelledBookings,
  setCompletedBookings,
  setBookingDetails,
  setNewRequests,
  setRevenue,
  updateRequest,
  removeCompletedBooking,
  removeCancelledBooking,
  startUpcomingService,
  setServices,
  setStripeAccount,
  setProfileData,
} = provider.actions;

export default provider.reducer;

// Profile

export const getProfileData = () => async (dispatch) => {
  dispatch(loadingStart());
  const {
    data: { data },
    status,
  } = await axiosGet({
    uri: `/get_all_services`,
    authName: "phgr",
  });

  if (status === 200) {
    dispatch(setProfileData(data && data[0]));
  }

  dispatch(loadingStop());

  return null;
};

// Bookings

export const getMyBookings = ({
  page,
  type,
  more,
  startDate,
  endDate,
}) => async (dispatch) => {
  // Required 1: New Requests 2: Ongoing 3: Complete 4: Cancel 5:upcoming

  let uri = `/booking_list?booking_type=${type}&cpage=${page}&perpage=10`;

  if (startDate) {
    uri = uri + `&start_date=${startDate}`;
  }

  if (endDate) {
    uri = uri + `&end_date=${endDate}`;
  }

  dispatch(loadingStart());
  const { data, status } = await axiosGet({
    uri,
    authName: "phgr",
  });

  if (status === 200) {
    switch (type) {
      case 1:
        dispatch(setNewRequests({ data, more }));
        break;
      case 2:
        dispatch(setOngoingBookings({ data, more }));
        break;
      case 5:
        dispatch(setUpcomingBookings({ data, more }));
        break;
      case 3:
        dispatch(setCompletedBookings({ data, more }));
        break;
      case 4:
        dispatch(setCancelledBookings({ data, more }));
        break;
      default:
        dispatch(setNewRequests({ data, more }));
        break;
    }
  }

  dispatch(loadingStop());

  return { status };
};

export const getBookingDetails = ({ bookingID }) => async (dispatch) => {
  // newRequest  :  "1",   Ongoing  :  "2",   upcoming   : 3,   Complete :  "4",   Cancel  : "5"

  dispatch(loadingStart());
  const {
    data: { data },
    status,
  } = await axiosGet({
    uri: `/booking_details?booking_id=${bookingID}`,
    authName: "phgr",
  });

  if (status === 200) {
    dispatch(setBookingDetails(data && data[0]));
  }

  dispatch(loadingStop());

  return null;
};

export const sendUpdate = ({ bookingID }) => async (dispatch) => {
  dispatch(loadingStart());
  const {
    // data: { data },
    status,
  } = await postForm({
    uri: `/track_pet?booking_id=${bookingID}`,
    authName: "phgr",
  });

  if (status === 200) {
    // dispatch(setTrackingDetails(data));
  }

  dispatch(loadingStop());

  return null;
};

export const startService = (payload) => async (dispatch) => {
  dispatch(loadingStart());
  const {
    data: { message, errorMessage, status: success },
    status,
  } = await postEncoded({
    uri: "/start_service",
    payload,
    authName: "phgr",
  });

  if (status === 200 && success) {
    dispatch(startUpcomingService(payload));
  }

  dispatch(loadingStop());

  return { message, status, errorMessage };
};

export const bookingResponse = (payload) => async (dispatch) => {
  dispatch(loadingStart());
  const {
    data: { message, errorMessage },
    status,
  } = await postEncoded({
    uri: "/booking_accept_reject",
    payload,
    authName: "phgr",
  });

  if (status === 200) {
    dispatch(updateRequest(payload));
  }

  dispatch(loadingStop());

  return { message, status, errorMessage };
};

export const addTrackingImage = (payload) => async (dispatch) => {
  dispatch(loadingStart());
  const {
    data: { message, errorMessage, data },
    status,
  } = await postForm({
    uri: "/track_add_image",
    payload,
    authName: "phgr",
  });

  dispatch(loadingStop());

  return { message, status, errorMessage, data };
};

export const removeTrackingImage = (payload) => async (dispatch) => {
  dispatch(loadingStart());
  const {
    data: { message, errorMessage },
    status,
  } = await postEncoded({
    uri: "/track_remove_image",
    payload,
    authName: "phgr",
  });

  dispatch(loadingStop());

  return { message, status, errorMessage };
};

export const updateTracking = (payload) => async (dispatch) => {
  dispatch(loadingStart());
  const {
    data: { message, errorMessage },
    status,
  } = await postEncoded({
    uri: "/update_status",
    payload,
    authName: "phgr",
  });

  dispatch(loadingStop());

  return { message, status, errorMessage };
};

export const updateLastActive = (payload) => async (dispatch) => {
  dispatch(loadingStart());
  const {
    data: { message, errorMessage },
    status,
  } = await postEncoded({
    uri: "/set_last_active",
    payload,
    authName: "phgr",
  });

  dispatch(loadingStop());

  return { message, status, errorMessage };
};

export const getLastActive = (payload) => async (dispatch) => {
  dispatch(loadingStart());
  const {
    data: { message, errorMessage },
    status,
  } = await axiosGet({
    uri: "/get_last_active?device_type=3",
    payload,
    authName: "phgr",
  });

  dispatch(loadingStop());

  return { message, status, errorMessage };
};

export const updateLocation = (payload) => async (dispatch) => {
  dispatch(loadingStart());
  const {
    data: { message, errorMessage },
    status,
  } = await axiosGet({
    uri: "/update_location",
    payload,
    authName: "phgr",
  });

  dispatch(loadingStop());

  return { message, status, errorMessage };
};

export const removeBooking = (payload, type) => async (dispatch) => {
  dispatch(loadingStart());
  const {
    data: { message, errorMessage },
    status,
  } = await postEncoded({
    uri: "/remove_booking",
    payload,
    authName: "phgr",
  });

  if (status === 200) {
    if (type === 3) {
      dispatch(removeCompletedBooking({ ...payload, type }));
    } else if (type === 4) {
      dispatch(removeCancelledBooking({ ...payload, type }));
    }
  }

  dispatch(loadingStop());

  return { message, status, errorMessage };
};

// Revenue

export const getRevenue = ({ payload, more }) => async (dispatch) => {
  dispatch(loadingStart());

  const {
    status,
    data: { data, message, errorMessage },
  } = await postEncoded({
    uri: `/revenue`,
    payload,
    authName: "phgr",
  });

  if (status === 200) {
    dispatch(setRevenue({ data, more }));
  }

  dispatch(loadingStop());

  return { status, message, errorMessage };
};

// Availability

export const getAvailability = () => async (dispatch) => {
  dispatch(loadingStart());
  const {
    data: { message, errorMessage, data },
    status,
  } = await axiosGet({
    uri: "/provider/get_availability",
    authName: "phgr",
  });

  if (status === 200) {
    dispatch(setAvailability(data));
  }

  dispatch(loadingStop());

  return { message, status, errorMessage };
};

export const addProfileAvailability = (payload) => async (dispatch) => {
  dispatch(loadingStart());
  const {
    data: { message, errorMessage },
    status,
  } = await postForm({
    uri: "/profile_setup_step_3",
    payload,
    authName: "phgr",
  });

  dispatch(loadingStop());

  return { message, status, errorMessage };
};

// Profile Setup

export const addProfileService = (payload) => async (dispatch) => {
  dispatch(loadingStart());
  const {
    data: { message, errorMessage },
    status,
  } = await postForm({
    uri: "/profile_setup_step_1",
    payload,
    authName: "phgr",
  });

  dispatch(loadingStop());

  return { message, status, errorMessage };
};

export const addProfileExperience = (payload) => async (dispatch) => {
  dispatch(loadingStart());
  const {
    data: { message, errorMessage },
    status,
  } = await postEncoded({
    uri: "/profile_setup_step_2",
    payload,
    authName: "phgr",
  });

  dispatch(loadingStop());

  return { message, status, errorMessage };
};

export const addExperienceImage = (payload) => async (dispatch) => {
  dispatch(loadingStart());
  const {
    data: { message, errorMessage, data },
    status,
  } = await postForm({
    uri: "/add_photo",
    payload,
    authName: "phgr",
  });

  dispatch(loadingStop());

  return { message, status, errorMessage, data };
};

export const removeExperienceImage = (payload) => async (dispatch) => {
  dispatch(loadingStart());
  const {
    data: { message, errorMessage },
    status,
  } = await postEncoded({
    uri: "/remove_photo",
    payload,
    authName: "phgr",
  });

  dispatch(loadingStop());

  return { message, status, errorMessage };
};

export const getServices = () => async (dispatch) => {
  dispatch(loadingStart());
  const {
    data: { message, errorMessage, data },
    status,
  } = await axiosGet({
    uri: "/get_all_services_record",
    authName: "phgr",
  });

  if (status === 200) {
    dispatch(setServices(data));
  }

  dispatch(loadingStop());

  return { message, status, errorMessage };
};

// Bank Accounts

export const addStripeAccount = (payload) => async (dispatch) => {
  dispatch(loadingStart());
  const {
    data: { data, message, errorMessage },
    status,
  } = await postEncoded({
    uri: "/save_provider_stripe_account",
    payload,
    authName: "phgr",
  });

  dispatch(loadingStop());

  return { status, data, message, errorMessage };
};

export const requestPayment = (payload) => async (dispatch) => {
  dispatch(loadingStart());
  const {
    data: { data, message, errorMessage },
    status,
  } = await postEncoded({
    uri: "/send_provider_payment_requrest",
    payload,
    authName: "phgr",
  });

  dispatch(loadingStop());

  return { status, data, message, errorMessage };
};

export const getStripeAccount = () => async (dispatch) => {
  dispatch(loadingStart());
  const {
    data: { data, message, errorMessage },
    status,
  } = await axiosGet({
    uri: "/get_provider_stripe_account",
    authName: "phgr",
  });

  if (status === 200) {
    dispatch(setStripeAccount(data));
  }

  dispatch(loadingStop());

  return { status, data, message, errorMessage };
};

export const removeStripeAccount = (payload) => async (dispatch) => {
  dispatch(loadingStart());
  const {
    data: { data, message, errorMessage },
    status,
  } = await postEncoded({
    uri: "/remove_provider_stripe_account",
    payload,
    authName: "phgr",
  });

  if (status === 200) {
    dispatch(setStripeAccount(null));
  }

  dispatch(loadingStop());

  return { status, data, message, errorMessage };
};

export const selectProfileData = (state) => state.provider.profileData;
