/* eslint-disable linebreak-style */

import { combineReducers } from "@reduxjs/toolkit";

import accountsReducer from "../features/accounts/slice";
import dashboardReducer from "../features/customer/dashboard/slice";
import servicesReducer from "../features/customer/services/slice";
import providerReducer from "../features/provider/slice";

const rootReducer = combineReducers({
  accounts: accountsReducer,
  dashboard: dashboardReducer,
  services: servicesReducer,
  provider: providerReducer,
});

export default rootReducer;
