import React, { Fragment, memo, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import "react-phone-number-input/style.css";
import PhoneInput, {
  isValidPhoneNumber,
  formatPhoneNumberIntl,
} from "react-phone-number-input";
import { useSnackbar } from "notistack";

import login_bg_without_shadow_png from "../../../images/home/login_bg_without_shadow.png";
import logo_footer_png from "../../../images/home/logo_footer.png";

import { useInputValue, useToggle } from "../../../app/hooks";
import { login } from "../slice";
import Facebook from "../facebook";
import Google from "../google";

const Login = memo(() => {
  const { goBack, replace, push } = useHistory();
  const { user } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const loading = useSelector((state) => state.accounts.loading);

  const [open, toggle] = useToggle(true);

  const password = useInputValue("");
  const [showPwd, setShowPwd] = useState(false);
  const [mobile, setMobile] = useState("");
  const [error, setError] = useState({});

  const validate = () => {
    let errors = {};
    let isValid = true;

    if (!password.value) {
      isValid = false;
      errors.password = "Required";
    } else if (password.value.length < 8) {
      isValid = false;
      errors.password = "Password length should be at least 8 characters.";
    }

    if (!mobile) {
      isValid = false;
      errors.mobile = "Required";
    } else if (!isValidPhoneNumber(mobile)) {
      isValid = false;
      errors.mobile = "Invalid mobile number.";
    }

    setError(errors);
    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      const countryCode = formatPhoneNumberIntl(mobile).split(" ")[0];
      const payload = {
        country_code: countryCode,
        mobile: mobile.replace(countryCode, ""),
        password: password.value,
        deviceType: 2,
        usertype: user === "customer" ? 2 : 3,
      };

      dispatch(login(payload)).then(
        ({ message, status, errorMessage, otpmessage, profile_step }) => {
          if (otpmessage) {
            enqueueSnackbar(otpmessage || "Success", {
              variant: "success",
            });
            push(
              `/${user}/verify/signup?p=${mobile.replace(
                mobile.substring(3, 10),
                "*******"
              )}`
            );
          } else if (user === "provider" && profile_step === 0) {
            enqueueSnackbar("Please complete you profile setup.", {
              variant: "info",
            });
            replace("/profile-setup/services");
          } else if (user === "provider" && profile_step === 1) {
            enqueueSnackbar("Please complete you profile setup.", {
              variant: "info",
            });
            replace("/profile-setup/experience");
          } else if (user === "provider" && profile_step === 2) {
            enqueueSnackbar("Please complete you profile setup.", {
              variant: "info",
            });
            replace("/profile-setup/availability");
          } else if (status === 200) {
            enqueueSnackbar(message || "Success", {
              variant: "success",
            });
            replace("/");
          } else {
            enqueueSnackbar(errorMessage || "Error", {
              variant: "error",
            });
          }
        }
      );
    }
  };

  const handleHide = () => {
    toggle();
    goBack();
  };

  return (
    <Modal
      show={open}
      onHide={handleHide}
      size="xl"
      id="loginModal"
      className="modal form-popup "
      aria-labelledby="exampleModalLabel"
    >
      <div className="modal-body ">
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={handleHide}
        ></button>
        <div className="row no-gutters justify-content-end">
          <div className="col-md-7 form-img d-lg-flex justify-content-center">
            <div className="img-holder align-self-center">
              <img
                className="img-fluid"
                src={login_bg_without_shadow_png}
                alt="img"
              />
            </div>
            <img src={logo_footer_png} alt="img" className="logomodal" />
          </div>
          <div className="col-lg-5 form-box d-flex justify-content-center">
            <form
              className="w-100 px-5 pt-0	 align-self-center"
              onSubmit={handleSubmit}
            >
              <div className="mx-auto pb-4">
                <p className=" h4 text-gray">Login</p>
              </div>

              <div className="form-group">
                <div className="custom-group-input rounded-digonal">
                  <PhoneInput
                    className="form-control"
                    placeholder="Mobile Number"
                    international
                    defaultCountry="AU"
                    countryCallingCodeEditable={false}
                    initialValueFormat="national"
                    value={mobile}
                    required
                    onInput={() => setError({ ...error, mobile: "" })}
                    onChange={setMobile}
                  />
                </div>
                <span className="error">{error.mobile}</span>
              </div>
              <div className="form-group">
                <div className="custom-group-input rounded-digonal left-icon">
                  <input
                    type={showPwd ? "text" : "password"}
                    className="form-control "
                    placeholder="Password"
                    onInput={() => setError({ ...error, password: "" })}
                    required
                    {...password}
                  />
                  <span className="group-icon-left ">
                    <i className="i25px ipassword"></i>
                  </span>
                  <span
                    onMouseUp={() => setShowPwd(false)}
                    onMouseDown={() => setShowPwd(true)}
                    id="reg_Pwd"
                    className={
                      showPwd
                        ? "group-icon-right fa fa-eye-slash pointer"
                        : "group-icon-right fa fa-eye pointer"
                    }
                    aria-hidden="true"
                  ></span>
                </div>
                <span className="error">{error.password}</span>
                <Link
                  className="text-primary float-right font-weight-bold"
                  to={`/${user}/forgot-password`}
                >
                  Forgot Password?
                </Link>
              </div>

              <div className="mb-3 mx-auto mt-3 mt-lg-5">
                <button
                  type="submit"
                  className="btn btn-primary btn-block btn-feet --blue btn-lg btn-block rounded-pill"
                >
                  {loading ? "Logging in..." : "Login"}
                </button>
              </div>
              {user === "customer" && (
                <Fragment>
                  <div className="loginOr pb-xl-3">
                    <span>Or login with</span>
                  </div>
                  <div className="socialLogin">
                    <Link
                      to="/customer/login"
                      onClick={(e) => e.preventDefault()}
                    >
                      <Facebook />
                    </Link>
                    <Link to="/customer/login">
                      <Google />
                    </Link>
                  </div>
                </Fragment>
              )}

              <p className="text-center mt-3 mt-xl-5 ">
                <span className="">
                  Don't have account?{" "}
                  <Link
                    className="text-primary font-weight-bold"
                    to={`/${user}/signup`}
                    replace
                  >
                    Signup
                  </Link>
                </span>
              </p>
            </form>
          </div>
        </div>
      </div>
    </Modal>
  );
});

export default Login;
