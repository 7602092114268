import React, { memo, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import "react-phone-number-input/style.css";
import PhoneInput, {
  isValidPhoneNumber,
  formatPhoneNumberIntl,
} from "react-phone-number-input";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";

import signup_without_shadow_07_png from "../../../images/home/signup_without_shadow-07.png";
import logo_footer_png from "../../../images/home/logo_footer.png";
import avatar_png from "../../../images/home/avatar.png";

import { useInputValue, useToggle } from "../../../app/hooks";
import {
  useAddressPredictions,
  useGeoCoder,
} from "../../../app/hooks/mapHooks";
import { signup, verifyEmail, verifyMobile } from "../slice";

const SignupForm = memo(() => {
  const { goBack, push } = useHistory();
  const { user } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const loading = useSelector((state) => state.accounts.loading);

  const [open, toggle] = useToggle(true);
  const firstName = useInputValue("");
  const lastName = useInputValue("");
  const email = useInputValue("");
  const password = useInputValue("");
  const company = useInputValue("");

  const [isMobileExists, setMobileExists] = useState(true);
  const [isEmailExists, setEmailExists] = useState(true);
  const [isAgree, setIsAgree] = useState(false);
  const [showPwd, setShowPwd] = useState(false);
  const [mobile, setMobile] = useState("");
  const [image, setImage] = useState("");
  const [placeId, setPlaceId] = useState("");
  const [address, setAddress] = useState("");
  const [location, setLocation] = useState("");
  const [showPredictions, setShowPredictions] = useState(false);
  const [error, setError] = useState({});

  const predictions = useAddressPredictions(location || " ");
  const geometry = useGeoCoder(placeId);

  const validate = async () => {
    let errors = {};
    let isValid = true;

    if (!isAgree) {
      isValid = false;
      errors.isAgree = "Please agree to terms & conditions.";
    }

    if (!password.value) {
      isValid = false;
      errors.password = "Required";
    } else if (password.value.length < 8) {
      isValid = false;
      errors.password = "Password length should be at least 8 characters.";
    }

    if (!location) {
      isValid = false;
      errors.address = "Required";
    } else if (location && !address) {
      isValid = false;
      errors.address = "Please choose address from predictions.";
    }

    if (!email.value) {
      isValid = false;
      errors.email = "Required";
    } else if (
      !isEmailExists &&
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email.value)
    ) {
      isValid = false;
      errors.email = "Invalid email address.";
    }

    if (!mobile) {
      isValid = false;
      errors.mobile = "Required";
    } else if (!isMobileExists && !isValidPhoneNumber(mobile)) {
      isValid = false;
      errors.mobile = "Ivalid mobile number.";
    }

    if (user === "provider" && !company.value) {
      isValid = false;
      errors.company = "Required";
    } else if (user === "provider" && company.value.length < 3) {
      isValid = false;
      errors.company = "Name length should be at least 3 characters.";
    }

    if (!firstName.value) {
      isValid = false;
      errors.firstName = "Required";
    } else if (firstName.value.length < 3) {
      isValid = false;
      errors.firstName = "Name length should be at least 3 characters.";
    }

    if (!lastName.value) {
      isValid = false;
      errors.lastName = "Required";
    } else if (lastName.value.length < 3) {
      isValid = false;
      errors.lastName = "Name length should be at least 3 characters.";
    }

    if (!image) {
      isValid = false;
      errors.image = "Profile image is required.";
    }

    setError(errors);
    return isValid;
  };

  const validateMobile = async (event) => {
    const value = event.target.value;
    const countryCode = formatPhoneNumberIntl(value).split(" ")[0];
    const payload = {
      country_code: countryCode,
      mobile: value.replace(countryCode, ""),
      usertype: user === "customer" ? 2 : 3,
    };

    dispatch(verifyMobile(payload)).then(
      ({ message, exists, status, errorMessage }) => {
        if (status === 200 && exists) {
          setError({ ...error, mobile: message });
        } else if (status !== 200 && errorMessage) {
          setError({ ...error, mobile: errorMessage });
        } else if (status === 200 && !exists) {
          setMobileExists(false);
        }
      }
    );
  };

  const validateEmail = async (event) => {
    const value = event.target.value;
    const payload = {
      email: value,
      usertype: user === "customer" ? 2 : 3,
    };

    await dispatch(verifyEmail(payload)).then(
      ({ message, exists, status, errorMessage }) => {
        if (status === 200 && exists) {
          setError({ ...error, email: message });
        } else if (status !== 200 && errorMessage) {
          setError({ ...error, email: errorMessage });
        } else if (status === 200 && !exists) {
          setEmailExists(false);
        }
      }
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validate()) {
      const countryCode = formatPhoneNumberIntl(mobile).split(" ")[0];
      var formdata = new FormData();
      formdata.append("first_name", firstName.value);
      formdata.append("last_name", lastName.value);
      formdata.append("email", email.value);
      if (user === "provider") {
        formdata.append("company_name", company.value);
      }
      formdata.append("country_code", countryCode);
      formdata.append("mobile", mobile.replace(countryCode, ""));
      formdata.append("password", password.value);
      formdata.append("usertype", user === "customer" ? 2 : 3);
      formdata.append("address", address);
      formdata.append("lat", geometry && geometry.lat);
      formdata.append("lng", geometry && geometry.lng);
      formdata.append("profileimage", image);

      dispatch(signup(formdata)).then(({ message, errorMessage, status }) => {
        if (status === 200) {
          enqueueSnackbar(message || "Success", {
            variant: "success",
          });
          push(
            `/${user}/verify/signup?p=${mobile.replace(
              mobile.substring(3, 10),
              "*******"
            )}`
          );
        } else {
          enqueueSnackbar(errorMessage || "Error", {
            variant: "error",
          });
        }
      });
    }
  };

  const handleHide = () => {
    toggle();
    goBack();
  };

  const handleImageUpload = (event) => {
    setError({ ...error, image: "" });
    const file = event.target.files[0];
    if (file.type.split("/")[0] !== "image") {
      enqueueSnackbar("Only image files are accepted", { variant: "error" });
    } else {
      setImage(
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );
    }
  };

  return (
    <Modal
      show={open}
      onHide={handleHide}
      size="xl"
      className="modal form-popup "
      id="signupModal"
      aria-labelledby="exampleModalLabel"
    >
      <div className="modal-body ">
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={handleHide}
        ></button>
        <div className="row no-gutters justify-content-end">
          <div className="col-md-6 form-img d-lg-flex justify-content-center">
            <div className="img-holder align-self-center">
              <img
                className="img-fluid"
                src={signup_without_shadow_07_png}
                alt=" img"
              />
            </div>
            <img src={logo_footer_png} alt="img" className="logomodal" />
          </div>
          <div className="col-lg-6 form-box d-flex justify-content-center">
            <form
              className="w-100 px-5 align-self-center"
              onSubmit={handleSubmit}
            >
              <div className="mt-4">
                <div className="form-group">
                  <div className="avatar-upload">
                    <div className="avatar-edit">
                      <input
                        type="file"
                        id="imageUpload"
                        accept=".png, .jpg, .jpeg"
                        onChange={handleImageUpload}
                      />
                      <label htmlFor="imageUpload">
                        <i className="i25px icamera"></i>
                      </label>
                    </div>
                    <div className="avatar-preview">
                      <div
                        id="imagePreview"
                        style={{
                          backgroundImage: image
                            ? `url(${image.preview})`
                            : `url(${avatar_png})`,
                        }}
                      ></div>
                    </div>
                  </div>
                  <span className="error">{error.image}</span>
                </div>
                <div className="form-group">
                  <div className="custom-group-input rounded-digonal left-icon">
                    <input
                      type="text"
                      className="form-control "
                      placeholder="First Name"
                      minLength={3}
                      maxLength={30}
                      onInput={() => setError({ ...error, firstName: "" })}
                      required
                      {...firstName}
                    />
                    <span className="group-icon-left ">
                      <i className="i25px iuserman"></i>
                    </span>
                  </div>
                  <span className="error">{error.firstName}</span>
                </div>
                <div className="form-group">
                  <div className="custom-group-input rounded-digonal left-icon">
                    <input
                      type="text"
                      className="form-control "
                      placeholder="Last Name"
                      minLength={3}
                      maxLength={30}
                      onInput={() => setError({ ...error, lastName: "" })}
                      required
                      {...lastName}
                    />
                    <span className="group-icon-left ">
                      <i className="i25px iuserman"></i>
                    </span>
                  </div>
                  <span className="error">{error.lastName}</span>
                </div>
                <div className="form-group">
                  <div className="custom-group-input rounded-digonal left-icon">
                    <input
                      type="email"
                      className="form-control "
                      placeholder="Email Address"
                      onInput={() => setError({ ...error, email: "" })}
                      onBlur={validateEmail}
                      required
                      {...email}
                    />
                    <span className="group-icon-left ">
                      <i className="i25px iuserman"></i>
                    </span>
                  </div>
                  <span className="error">{error.email}</span>
                </div>

                {user === "provider" && (
                  <div className="form-group">
                    <div className="custom-group-input rounded-digonal left-icon">
                      <input
                        type="text"
                        className="form-control "
                        placeholder="Company Name"
                        onInput={() => setError({ ...error, company: "" })}
                        required
                        {...company}
                      />
                      <span className="group-icon-left ">
                        <i className="i25px iuserman"></i>
                      </span>
                    </div>
                    <span className="error">{error.company}</span>
                  </div>
                )}

                <div className="form-group">
                  <div className="custom-group-input rounded-digonal">
                    <PhoneInput
                      className="form-control"
                      placeholder="Mobile Number"
                      international
                      defaultCountry="AU"
                      countryCallingCodeEditable={false}
                      initialValueFormat="national"
                      value={mobile}
                      required
                      onInput={() => setError({ ...error, mobile: "" })}
                      onBlur={validateMobile}
                      onChange={setMobile}
                    />
                  </div>
                  <span className="error">{error.mobile}</span>
                </div>

                <div className="form-group" style={{ position: "relative" }}>
                  <div className="custom-group-input rounded-digonal left-icon">
                    <input
                      type="text"
                      className="form-control "
                      placeholder="Address"
                      required
                      onInput={() => setError({ ...error, address: "" })}
                      value={address ? address : location}
                      onChange={(e) => {
                        let value = e.target.value;
                        setLocation(value);
                        setShowPredictions(true);
                      }}
                    />
                    <span className="group-icon-left ">
                      <i className="i25px ilcoation"></i>
                    </span>
                  </div>
                  <span className="error">{error.address}</span>
                  {showPredictions && (
                    <ul className="predictions">
                      {predictions.map((prediction, index) => (
                        <li
                          key={index}
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setPlaceId(prediction.placeId);
                            setAddress(prediction.description);
                            setLocation(prediction.description);
                            setShowPredictions(false);
                          }}
                        >
                          {prediction.description}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
                <div className="form-group">
                  <div className="custom-group-input rounded-digonal left-icon">
                    <input
                      type={showPwd ? "text" : "password"}
                      className="form-control "
                      placeholder="Password"
                      onInput={() => setError({ ...error, password: "" })}
                      required
                      {...password}
                    />
                    <span className="group-icon-left ">
                      <i className="i25px ipassword"></i>
                    </span>
                    <span
                      onMouseUp={() => setShowPwd(false)}
                      onMouseDown={() => setShowPwd(true)}
                      id="reg_Pwd"
                      className={
                        showPwd
                          ? "group-icon-right fa fa-eye-slash pointer"
                          : "group-icon-right fa fa-eye pointer"
                      }
                      aria-hidden="true"
                    ></span>
                  </div>
                  <span className="error">{error.password}</span>
                </div>

                <div className="form-group text-center ">
                  <div className="custom-control custom-checkbox mr-sm-2">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="customControlAutosizing"
                      name="agree"
                      checked={isAgree}
                      onChange={() => {
                        setError({ ...error, isAgree: "" });
                        setIsAgree(!isAgree);
                      }}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customControlAutosizing"
                    >
                      <small>
                        I agree to{" "}
                        <a
                          href="http://3.131.5.153:5002/privacy_cookies_policy"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-dark font-weight-bold"
                        >
                          Privacy Policy
                        </a>{" "}
                        and{" "}
                        <a
                          href="http://3.131.5.153:5002/term_condition"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-dark font-weight-bold"
                        >
                          Terms & Conditions
                        </a>
                      </small>
                    </label>
                  </div>
                  <span className="error">{error.isAgree}</span>
                </div>

                <div className="form-group mt-5">
                  <button
                    type="submit"
                    className="btn btn-primary btn-block btn-feet --blue btn-lg btn-block rounded-pill"
                  >
                    {loading ? "Signing..." : "Signup"}
                  </button>
                </div>

                <p className="text-center pr-3 mb-4 ">
                  <span className="">
                    Already have an account.
                    <Link
                      className="text-primary font-weight-bold"
                      to={`/${user}/login`}
                      replace
                    >
                      Login
                    </Link>
                  </span>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Modal>
  );
});

export default SignupForm;
