import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

import AboutUs from "./About";
import Banner from "./Banner";
import Contact from "./Contact";
import HowItWorks from "./How";
import Services from "./Services";
import WhyChooseUs from "./Why";

const scrollTo = (ref) =>
  window.scrollTo({
    top: ref.current.offsetTop - 100,
    behavior: "smooth",
  });

const Home = () => {
  const { search } = useLocation();

  const servicesRef = useRef(null);
  const howRef = useRef(null);
  const whyRef = useRef(null);
  const aboutRef = useRef(null);
  const contactRef = useRef(null);

  useEffect(() => {
    switch (search) {
      case "?q=services":
        scrollTo(servicesRef);
        break;
      case "?q=how-it-works":
        scrollTo(howRef);
        break;
      case "?q=why-choose-us":
        scrollTo(whyRef);
        break;
      case "?q=about-us":
        scrollTo(aboutRef);
        break;
      case "?q=contact-us":
        scrollTo(contactRef);
        break;
      default:
        // window.scrollTo(0, 0);
        break;
    }
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <main className="page-wrapper">
      <Banner />
      <Services ref={servicesRef} />
      <HowItWorks ref={howRef} />
      <WhyChooseUs ref={whyRef} />
      <AboutUs ref={aboutRef} />
      <Contact ref={contactRef} />
    </main>
  );
};

export default Home;
