import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Skeleton } from "@material-ui/lab";

import { usePrevious } from "../../../../app/hooks";
import GoogleMap from "../../../../components/googleMap";
import { getBookingDetails } from "../slice";
import Pet from "../myBookings/details/Pet";
import Provider from "../myBookings/details/Provider";
import Photos from "../myBookings/details/Photos";

const TrackingDetails = () => {
  const dispatch = useDispatch();
  const { bookingID } = useParams();
  const { goBack } = useHistory();

  const prevBookingID = usePrevious(bookingID);

  const { loading, details } = useSelector((state) => {
    return {
      loading: state.dashboard.loading,
      details: state.dashboard.bookingDetails,
      // details: state.dashboard.trackingDetails,
    };
  });

  useEffect(() => {
    if (!loading && bookingID && (!details || bookingID !== prevBookingID)) {
      window.scrollTo(0, 0);
      dispatch(getBookingDetails({ bookingID }));
      // dispatch(getTrackingDetails({ bookingID }));
    }
  }, [dispatch, details, bookingID, prevBookingID, loading]);

  return (
    <div className="tab-pane fade show active" id="ongoing" role="tabpanel">
      <p className="light-gray mt-4 text-dark mb-1">
        <i className="ti-angle-left mr-1 pointer" onClick={goBack}></i> ID:{" "}
        {loading ? "Loading..." : details && details._id}
      </p>
      <div
        id="servicemap"
        style={{ height: "400px" }}
        className="my-4 round round-xl"
      >
        {details ? (
          <GoogleMap
            markers={[
              { user: { latitude: 27.718041699999997, longitude: 79.6558242 } },
            ]}
            height="400px"
          />
        ) : (
          <Skeleton variant="rect" height={400} />
        )}
      </div>
      <div className="row my-4">
        {details && <Pet data={details.pet_list && details.pet_list[0]} />}
        {details && <Provider data={details.provider} />}
      </div>
      {details && details.track && details.track.length
        ? details.track.map((el, i) => <Photos key={i} data={el} />)
        : null}
    </div>
  );
};

export default TrackingDetails;
