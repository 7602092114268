import React from "react";

import book_dog_png from "../../../../../images/book-dog.png";

const baseURL = process.env.REACT_APP_PAW_HUGGER_WEB_BASE_URL;

const Pet = ({ data }) => {
  return (
    <div className="col-lg-5 d-flex flex-wrap">
      <p className="h5 text-gray mb-4 ml-3">Dog Details</p>
      <div className="booking-item mt-0 mb-4 mb-lg-0 w-100">
        <div className="row justify-content-center">
          <div className="col-3 col-md-2 px-0 col-lg-auto">
            <img
              src={
                data && data.pet_image
                  ? `${baseURL}/${data.pet_image}`
                  : book_dog_png
              }
              className="img-fluid mb-3"
              alt="img"
            />
          </div>
          <div className="col-9 col-md-10 col-lg-7 ">
            <div className="row features">
              <div className="col-12">
                <p className="my-2">
                  <i className="i30px ibookdog-type"></i>
                  {data && data.pet_name} |
                  <i className="i30px ibookage-cake"></i>
                  {data && data.pet_age}
                </p>
              </div>
              <div className="col-12">
                <p className="my-2">
                  <i className="i30px ibookfeatures-dog"></i>
                  {data && data.personel_prefrence}
                </p>
              </div>
              <div className="col-12">
                <p className="my-2">
                  <i className="i30px ibookfeatures-dog"></i>
                  {data && data.dont_like_things}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pet;
