import React, { useRef, useEffect, useCallback } from "react";
import PropTypes from "prop-types";

import avatar_png from "../../images/avatar.png";
const baseURL = process.env.REACT_APP_PAW_HUGGER_WEB_BASE_URL;

var InforObj = [];

const GoogleMap = ({ markers, height }) => {
  const googleMapRef = useRef();

  function closeOtherInfo() {
    if (InforObj.length > 0) {
      /* detach the info-window from the marker ... undocumented in the API docs */
      InforObj[0].set("marker", null);
      /* and close it */
      InforObj[0].close();
      /* blank the array */
      InforObj.length = 0;
    }
  }

  const createGoogleMap = useCallback(
    (lat, lng) =>
      new window.google.maps.Map(googleMapRef.current, {
        zoom: 8,
        center: {
          lat: lat || -21.7108833,
          lng: lng || 125.0798551,
        },
        disableDefaultUI: true,
      }),
    []
  );

  const createMarker = useCallback(
    () =>
      markers.map((item) => {
        var contentString = `
      <div className="card map-marker-card shadow border-0">
        <div className="row align-items-center">
          <div className="col-auto">
            <img
            className=""
              src=${
                item.user && item.user.profileurl
                  ? `${baseURL}/${item.user.profileurl}`
                  : avatar_png
              }
              alt="avtar"
            >
          </div>
        </div>
      </div>`;

        const marker = new window.google.maps.Marker({
          id: `gmap${item._id}`,
          position: {
            lat: Number(item.user && item.user.latitude) || -21.7108833,
            lng: Number(item.user && item.user.longitude) || 125.0798551,
          },
          map: window.googleMap,
          icon: item.icon,
        });

        const infowindow = new window.google.maps.InfoWindow({
          content: contentString,
          maxWidth: 250,
        });

        marker.addListener("click", function () {
          closeOtherInfo();
          infowindow.open(marker.get("map"), marker);
          InforObj[0] = infowindow;

          document.getElementById(item._id).classList.remove("shadow");
          document.getElementById(item._id).classList.add("shadow-primary");
        });

        marker.addListener("mouseover", function () {
          closeOtherInfo();
          infowindow.open(marker.get("map"), marker);
          InforObj[0] = infowindow;
        });
        marker.addListener("mouseout", function () {
          closeOtherInfo();
          infowindow.close();
          InforObj[0] = infowindow;

          document.getElementById(item._id).classList.remove("shadow-primary");
          document.getElementById(item._id).classList.add("shadow");
        });

        return marker;
      }),
    [markers]
  );

  useEffect(() => {
    window.googleMap = createGoogleMap(
      Number(markers[0].user.latitude),
      Number(markers[0].user.longitude)
    );
    window.marker = createMarker();
  }, [createGoogleMap, createMarker, markers]);

  return (
    <div
      className="mt-3"
      id="google-map"
      ref={googleMapRef}
      style={{ height: height ? height : "100%", width: "100%" }}
    />
  );
};

GoogleMap.propTypes = {
  markers: PropTypes.array.isRequired,
};

export default GoogleMap;
