import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import book_dog_png from "../../../../../../images/book-dog.png";

import { usePrevious } from "../../../../../../app/hooks";
import { getBookingDetails } from "../../../../slice";
import DogDetails from "../../dogDetails";
import CustomerDetails from "../../customerDetails";
import SentPhotos from "../../sentPhotos";

const baseURL = process.env.REACT_APP_PAW_HUGGER_WEB_BASE_URL;

const CancelledDetails = () => {
  const dispatch = useDispatch();
  const { bookingID } = useParams();
  const { goBack } = useHistory();

  const { loading, details } = useSelector((state) => {
    return {
      loading: state.dashboard.loading,
      details: state.dashboard.bookingDetails,
    };
  });

  const prevBookingID = usePrevious(bookingID);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!loading && bookingID && (!details || bookingID !== prevBookingID)) {
      dispatch(getBookingDetails({ bookingID }));
    }
  }, [dispatch, details, bookingID, prevBookingID, loading]);

  return (
    <div className="tab-pane fade  show active " id="completed" role="tabpanel">
      <p className="light-gray mt-4 text-dark mb-1">
        <i className="ti-angle-left ml-5 pointer" onClick={goBack}></i> ID:{" "}
        {details && details.booking_id}
      </p>
      <div className="booking-item mt-0 shadow-none">
        <div className="row justify-content-center">
          <div className="col-sm-2 px-0 col-lg-auto">
            <img
              src={
                details &&
                details.pet_list &&
                details.pet_list[0] &&
                details.pet_list[0].pet_image
                  ? `${baseURL}/${details.pet_list[0].pet_image}`
                  : book_dog_png
              }
              className="img-fluid mb-3"
              alt="img"
            />
          </div>
          <div className="col-sm-10 col-lg-7 ">
            <h5>{details && details.service.service_name}</h5>
            <p className="p-sm font-weight-bold">
              {details &&
              details.bookingCreatedAt &&
              new Date(details.bookingCreatedAt) === new Date()
                ? "Today"
                : new Date(details && details.bookingCreatedAt).toDateString()}
              ,{" "}
              {details &&
                details.bookingCreatedAt &&
                new Date(details.bookingCreatedAt).toLocaleTimeString()}
            </p>
            <div className="row features">
              <div className="col-12">
                <p>
                  <i className="i30px ibookcalender"></i>
                  {details && details.start_date}
                </p>
              </div>
              <div className="col-12">
                <p>
                  <i className="i30px ibookclock-time"></i>
                  {details && details.booking_time} to{" "}
                  {details && details.booking_end_time}
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="row ">
              <div className="col-sm-6 my-3 my-lg-0 col-lg-12 col-xl-11">
                <p className="price d-flex justify-content-end align-items-center h4 mb-3 text-primary">
                  <i className="i40px ibookcompleted"></i>$
                  {details && details.isPaid}
                  <span> / hr</span>
                </p>
              </div>
              {/* <div className="col-sm-6 my-3 my-lg-4 col-lg-12 col-xl-11">
                <button
                  type="button"
                  className="btn btn-primary btn-feet --blue btn-lg btn-block rounded-pill"
                >
                  Book Again
                </button>
              </div> */}
            </div>
          </div>
        </div>
      </div>

      <div className="row my-4">
        {details && (
          <DogDetails data={details.pet_list && details.pet_list[0]} />
        )}
        {details && <CustomerDetails data={details.client} />}
      </div>

      {details && details.track && details.track.length ? (
        <Fragment>
          <p className="h5 text-gray mt-5 mb-4 ml-3">Sent Photos</p>
          {details.track.map((el, i) => (
            <SentPhotos key={i} data={el} />
          ))}
        </Fragment>
      ) : null}
    </div>
  );
};

export default CancelledDetails;
