import React, { useState } from "react";
import { useSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { getRevenue } from "../../../slice";
import { reverseDate } from "../../../../../utils";

const FilterRevenue = () => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);
  const [startDate, setStartDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [endDate, setEndDate] = useState("");

  const handleRefresh = () => {
    setStartDate(new Date().toISOString().split("T")[0]);
    setEndDate("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (startDate) {
      const payload = {
        page: 1,
        perPage: 10,
        startDate: reverseDate(startDate),
      };

      if (endDate) {
        payload.endDate = reverseDate(endDate);
      }

      dispatch(getRevenue({ payload })).then(
        ({ message, status, errorMessage }) => {
          if (status === 200) {
            enqueueSnackbar(message || "Success", {
              variant: "success",
            });
          } else {
            enqueueSnackbar(errorMessage || "Error", {
              variant: "error",
            });
          }
        }
      );
      setShow(false);
    }
  };

  return (
    <div>
      <div className="dropright show">
        <Link
          to="/dashboard/my-bookings/filter"
          className="ml-3"
          type="button"
          data-toggle="dropdown"
          aria-expanded="true"
          onClick={(e) => {
            e.preventDefault();
            setShow(!show);
          }}
        >
          <i className="i50px ifilter"></i>
        </Link>
        {show && (
          <div
            className="dropdown-menu select-dropdown-menu show"
            style={{
              position: "absolute",
              transform: "translate3d(-336px, 0px, 0px)",
              top: "0px",
              left: "0px",
              willChange: "transform",
            }}
            x-placement="left-start"
          >
            <div className="d-flex justify-content-between">
              <span>
                <i className="i30px irefresh" onClick={handleRefresh}></i>
              </span>
              <h5 className="mb-3 text-primary">Filter</h5>
              <span>
                <i
                  className="i20px invert-gray2 iclose pointer"
                  onClick={() => setShow(!show)}
                ></i>
              </span>
            </div>

            <div className="row mt-3 rowMarginHalf colpad-half">
              <div className="col-6 radio-tabs">
                <input
                  type="radio"
                  name="jksfjksf"
                  id="today"
                  checked={startDate === new Date().toISOString().split("T")[0]}
                  onChange={() => {
                    setStartDate(new Date().toISOString().split("T")[0]);
                    setEndDate("");
                  }}
                />
                <label
                  className="label-button rounded-pill d-block"
                  htmlFor="today"
                >
                  Today
                </label>
              </div>
              <div className="col-6 radio-tabs">
                <input
                  type="radio"
                  name="jksfjksf"
                  id="yesterday"
                  checked={
                    startDate ===
                    new Date(Date.now() - 864e5).toISOString().split("T")[0]
                  }
                  onChange={() => {
                    setStartDate(
                      new Date(Date.now() - 864e5).toISOString().split("T")[0]
                    );
                    setEndDate("");
                  }}
                />
                <label
                  className="label-button rounded-pill d-block"
                  htmlFor="yesterday"
                >
                  Yesterday
                </label>
              </div>
            </div>
            <p className="text-dark my-4">By Date</p>
            <div className="row rowMarginHalf colpad-half">
              <div className="col-6">
                <div className="form-group mb-2">
                  <div className="custom-group-input rounded-digonal left-icon">
                    <input
                      type="date"
                      className="form-control "
                      placeholder="Start Date"
                      value={startDate || ""}
                      max={new Date().toISOString().split("T")[0]}
                      required
                      onChange={(e) => {
                        let value = e.target.value;
                        setStartDate(value);
                      }}
                    />
                    <span className="group-icon-left ">
                      <i className="i30px icalendar "></i>{" "}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className="form-group mb-2">
                  <div className="custom-group-input rounded-digonal left-icon">
                    <input
                      type="date"
                      className="form-control "
                      placeholder="Start Date"
                      disabled={!startDate}
                      max={
                        startDate
                          ? startDate
                          : new Date().toISOString().split("T")[0]
                      }
                      required
                      onChange={(e) => {
                        let value = e.target.value;
                        setEndDate(value);
                      }}
                    />
                    <span className="group-icon-left ">
                      <i className="i30px icalendar "></i>{" "}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-10">
                <div className="form-group mt-4">
                  <button
                    type="button"
                    className="btn btn-primary btn-block btn-feet --blue btn-lg btn-block rounded-pill"
                    onClick={handleSubmit}
                  >
                    Filter
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default FilterRevenue;
