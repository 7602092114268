import React, { memo } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import "react-phone-number-input/style.css";
import { useSnackbar } from "notistack";
import { usePaymentInputs } from "react-payment-inputs";
import _ from "lodash";

import { useInputValue, useToggle } from "../../../../../app/hooks";
import { addNewCard } from "../../slice";

const AddNewAccount = memo(() => {
  const { goBack } = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const {
    meta,
    getCardNumberProps,
    getExpiryDateProps,
    getCVCProps,
  } = usePaymentInputs();

  const loading = useSelector((state) => state.dashboard.loading);

  const [open, toggle] = useToggle(true);
  const cardNumber = useInputValue("");
  const cvv = useInputValue("");
  const expiryDate = useInputValue("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (_.isEmpty(validate())) {
      const [month, year] = expiryDate.value.split("/");
      const payload = {
        account_number: cardNumber.value,
        exp_month: Number(month),
        exp_year: Number(year),
        cvc: cvv.value,
      };

      dispatch(addNewCard({ payload, save: true })).then(
        ({ message, status, errorMessage }) => {
          if (status === 200) {
            enqueueSnackbar(message || "Success", {
              variant: "success",
            });
            goBack();
          } else {
            enqueueSnackbar(errorMessage || "Error", {
              variant: "error",
            });
          }
        }
      );
    }
  };

  const handleHide = () => {
    toggle();
    goBack();
  };

  const validate = () => {
    let errors = {};
    if (meta.erroredInputs.cardNumber) {
      errors.cardNumber = meta.erroredInputs.cardNumber;
    }
    if (meta.erroredInputs.expiryDate) {
      errors.expiryDate = meta.erroredInputs.expiryDate;
    }
    if (meta.erroredInputs.cvc) {
      errors.cvc = meta.erroredInputs.cvc;
    }
    return errors;
  };

  return (
    <Modal
      show={open}
      onHide={handleHide}
      size="lg"
      id="add-bankdetails-modal"
      className="modal form-popup "
      aria-labelledby="exampleModalLabel"
    >
      <div className="modal-body ">
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={handleHide}
        ></button>
        <div className="row no-gutters justify-content-center">
          <form
            className="col-sm-10 col-lg-8 py-4  text-center"
            onSubmit={handleSubmit}
          >
            <p className="pb-4 h4">Add New Card</p>
            {meta.isTouched && meta.error && (
              <span className="error">Error: {meta.error}</span>
            )}
            <div className="form-group ">
              <div className="custom-group-input rounded-digonal left-icon">
                <input
                  type="text"
                  className="form-control "
                  placeholder="Card Number"
                  {...getCardNumberProps({ ...cardNumber })}
                />
                <span className="group-icon-left ">
                  <i className="i30px iac-number"></i>{" "}
                </span>
              </div>
              {/* <span className="error">{error.abaNumber}</span> */}
            </div>

            <div className="form-group ">
              <div className="custom-group-input rounded-digonal left-icon">
                <input
                  type="text"
                  className="form-control "
                  placeholder="Expiry Date"
                  {...getExpiryDateProps({ ...expiryDate })}
                />
                <span className="group-icon-left ">
                  <i className="i30px iac-number"></i>{" "}
                </span>
              </div>
              {/* <span className="error">{error.abaNumber}</span> */}
            </div>

            <div className="form-group ">
              <div className="custom-group-input rounded-digonal left-icon">
                <input
                  type="text"
                  className="form-control "
                  placeholder="CVV"
                  {...getCVCProps({ ...cvv })}
                />
                <span className="group-icon-left ">
                  <i className="i30px iac-number"></i>{" "}
                </span>
              </div>
              {/* <span className="error">{error.abaNumber}</span> */}
            </div>

            <button className="btn my-5 btn-primary btn-feet --blue btn-lg btn-block rounded-pill">
              {loading ? "Saving..." : "Save Account"}
            </button>
          </form>
        </div>
      </div>
    </Modal>
  );
});

export default AddNewAccount;
