import React, { Fragment, useEffect } from "react";
import {
  NavLink,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";

import routes from "../../../routes/customer";
// import action from "../payments/notification";

const Dashboard = () => {
  let { url } = useRouteMatch();
  let { pathname } = useLocation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { push } = useHistory();

  const { requests } = useSelector((state) => {
    return {
      requests: state.dashboard.requests,
    };
  });

  useEffect(() => {
    if (requests && requests.length) {
      requests.forEach((element) => {
        enqueueSnackbar("Payment notification", {
          variant: "warning",
          // autoHideDuration: 3000,
          persist: true,
          action: (key) => (
            <Fragment>
              <Button
                onClick={() => {
                  closeSnackbar(key);
                  push(`/dashboard/pay/${element.booking_id}`);
                }}
              >
                'Pay'
              </Button>
              <Button onClick={() => closeSnackbar(key)}>'Dismiss'</Button>
            </Fragment>
          ),
        });
      });
    }
  }, [requests, closeSnackbar, push, enqueueSnackbar]);

  return (
    <main className="page-wrapper">
      <div className="my-accounts mt-5 pt-5">
        <div className="container mt-xl-5 pt-xl-3">
          <ul className="nav my-5 nav-justified text-center custom-tabs ">
            <li className="nav-item py-2">
              <NavLink
                className={
                  pathname && pathname.includes("/dashboard/my-bookings")
                    ? "nav-link active"
                    : "nav-link"
                }
                to={`${url}/my-bookings/pending`}
              >
                My Bookings
              </NavLink>
            </li>
            <li className="nav-item py-2">
              <NavLink className="nav-link" to={`${url}/track-pets`}>
                Track Pet
              </NavLink>
            </li>
            <li className="nav-item py-2">
              <NavLink className="nav-link" to={`${url}/my-pets`}>
                My Pets
              </NavLink>
            </li>
            <li className="nav-item py-2">
              <NavLink className="nav-link " to={`${url}/my-profile`}>
                My Profile
              </NavLink>
            </li>
            <li className="nav-item py-2">
              <NavLink className="nav-link" to={`${url}/bank-accounts`}>
                Bank Cards
              </NavLink>
            </li>
            <li className="nav-item py-2">
              <NavLink className="nav-link" to={`${url}/help`}>
                Help?
              </NavLink>
            </li>
            <li className="nav-item py-2">
              <NavLink className="nav-link " to={`${url}/change-password`}>
                Change Password
              </NavLink>
            </li>
          </ul>
          <Switch>
            {routes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
                children={<route.main />}
              />
            ))}
          </Switch>
        </div>
      </div>
    </main>
  );
};

export default Dashboard;
