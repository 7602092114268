import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useSnackbar } from "notistack";

import { getBankCards, removeCard } from "../slice";

const BankAccounts = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const { bankAccounts, loading } = useSelector((state) => {
    return {
      loading: state.dashboard.loading,
      bankAccounts: state.dashboard.bankAccounts,
    };
  });

  const [removing, setRemoving] = useState(false);

  useEffect(() => {
    if (!bankAccounts) {
      dispatch(getBankCards({ page: 1 }));
    }
  }, [bankAccounts, dispatch]);

  function deleteCard({ cardID }) {
    setRemoving(true);
    dispatch(removeCard({ card_id: cardID })).then(
      ({ message, status, errorMessage }) => {
        setRemoving(false);
        if (status === 200) {
          enqueueSnackbar(message || "Success", {
            variant: "success",
          });
        } else {
          enqueueSnackbar(errorMessage || "Error", {
            variant: "error",
          });
        }
      }
    );
  }

  return (
    <Fragment>
      <div className="my-4 my-lg-5 d-flex align-items-center justify-content-between">
        <p className="h4">My Cards</p>
        <div>
          <Link
            to="/dashboard/bank-accounts/new"
            className="btn btn-lg px-4 btn-primary rounded-pill"
            data-target="#add-bankdetails-modal"
            data-toggle="modal"
          >
            + Add New
          </Link>
        </div>
      </div>

      {loading && <span>{removing ? "Removing..." : "Loading..."}</span>}
      <div className="row my-4">
        {bankAccounts && bankAccounts.data && bankAccounts.data.length
          ? bankAccounts.data.map((el) => (
              <div className="col-md-6 d-flex flex-wrap" key={el.id}>
                <div className="booking-item mt-0 mb-4 w-100">
                  <div className="input-select">
                    {/* <input type="radio" name="pay-methods" id={el.id} /> */}
                    <i
                      className="fa fa-trash pointer"
                      aria-hidden="true"
                      onClick={() => deleteCard({ cardID: el.id })}
                      style={{ float: "right", margin: "2px" }}
                    />
                    <label htmlFor={el.id} className="media">
                      <i className="i40px i-cc mr-3"></i>
                      <div className="media-body py-2">
                        <h5 className="text-uppercase font-weight-bold">
                          {el.brand}
                          {/* <img
                            src="https://www.pinclipart.com/picdir/middle/401-4017515_bank-of-america-logo-transparent-clipart.png"
                            width="60px"
                            alt="alt"
                          /> */}
                        </h5>

                        <p className="h4 my-3">***********{el.last4}</p>
                        <p className="text-gray mb-2 text-capitalize h5">
                          {el.name}
                        </p>
                        <h6 className="text-gray">Exp</h6>
                        <small>
                          {el.exp_month}/{el.exp_year}
                        </small>
                      </div>
                    </label>
                  </div>
                </div>
              </div>
            ))
          : null}
      </div>
    </Fragment>
  );
};

export default BankAccounts;
