import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import revenue_bg_png from "../../../../images/provider/revenue-bg.png";
import book_dog_png from "../../../../images/book-dog.png";

import { getRevenue } from "../../slice";
import FilterRevenue from "./filter";

const baseURL = process.env.REACT_APP_PAW_HUGGER_WEB_BASE_URL;

const Revenue = () => {
  const dispatch = useDispatch();

  const { loading, revenue } = useSelector((state) => {
    return {
      loading: state.provider.loading,
      revenue: state.provider.revenue,
    };
  });

  useEffect(() => {
    if (!revenue) {
      dispatch(getRevenue({ payload: { page: 1, perPage: 10 } }));
    }
  }, [revenue, dispatch]);

  return (
    <Fragment>
      <div className="d-flex justify-content-between">
        <div>
          <p className="h4 text-gray">Revenue</p>
        </div>
        <FilterRevenue />
      </div>

      <div className="revenue-banner">
        <img src={revenue_bg_png} alt="img" />
        <div className="overlayer d-flex justify-content-center">
          <div className="align-self-center text-center">
            <p className="h4 text-light-gray">Total Earning</p>
            <h3 className="text-light">${revenue && revenue.total_revenue}</h3>
          </div>
        </div>
      </div>

      <div className="row my-4">
        {revenue && revenue.data && revenue.data.length ? (
          revenue.data.map((el) => (
            <div className="col-lg-6 d-flex flex-wrap" key={el.booking_id}>
              <div className="booking-item mt-0 mb-4 w-100">
                <div className="row justify-content-center">
                  <div className="col-12 ">
                    <div className="row justify-content-between">
                      <div className="col-auto">
                        <p className="light-gray mb-1">ID: {el.booking_id}</p>
                        <h4>Service : {el.service_name}</h4>
                      </div>
                      <div className="col-auto text-right">
                        <p className="text-gray mb-1">
                          {el.paid_date &&
                            new Date(el.paid_date).toDateString()}
                        </p>
                        <h4 className="price h4 mb-0 text-primary">
                          ${el.isPaid}{" "}
                        </h4>
                        <p className="d-block mb-0">{el.booking_hour} hr</p>
                      </div>
                    </div>
                    <div className="row features">
                      <div className="col-12">
                        <div>
                          <p>
                            <i className="i30px ibookcalender"></i>
                            {el.start_date}
                          </p>
                        </div>
                        <div>
                          <p>
                            <i className="i30px ibookclock-time"></i>
                            {el.booking_time}{" "}
                            {el.booking_end_time && "to " + el.booking_end_time}
                          </p>
                        </div>

                        <div className="row">
                          <div className="col-8 col-sm-9">
                            <p>
                              <i className="i30px ibookdog-type"></i>
                              {el.pet_list &&
                                el.pet_list[0] &&
                                el.pet_list[0].pet_name}{" "}
                              |<i className="i30px ibookage-cake"></i>{" "}
                              {el.pet_list &&
                                el.pet_list[0] &&
                                el.pet_list[0].pet_age}
                            </p>
                          </div>
                          <div className="col-4 col-sm-3">
                            <div className="row justify-content-center">
                              <div className="col-3 px-0">
                                <img
                                  src={
                                    el.service_image
                                      ? `${baseURL}/${el.service_image}`
                                      : book_dog_png
                                  }
                                  className="img-fluid mb-3"
                                  alt="img"
                                />
                              </div>
                              <div className="col-9">
                                <h6 className="text-gray mb-0">Paid by</h6>
                                <h6>
                                  {el.paidByUser &&
                                    el.paidByUser.first_name +
                                      " " +
                                      el.paidByUser.last_name}
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <span>{loading ? "Loading..." : "No records found."}</span>
        )}
      </div>
    </Fragment>
  );
};

export default Revenue;
