import React from "react";

import ChangePassword from "../../features/accounts/changePassword";
import Help from "../../features/accounts/help";
import BankAccounts from "../../features/customer/dashboard/bankAcoounts";
import AddNewAccount from "../../features/customer/dashboard/bankAcoounts/addNew";
import MyBookings from "../../features/customer/dashboard/myBookings";
import MyPets from "../../features/customer/dashboard/myPets";
import AddEditPet from "../../features/customer/dashboard/myPets/addEdit";
import TrackPets from "../../features/customer/dashboard/trackPets";
import MakePayment from "../../features/customer/payments";
import MyProfile from "../../features/myProfile";

const routes = [
  {
    path: "/dashboard/my-bookings",
    main: () => <MyBookings />,
  },
  {
    path: "/dashboard/track-pets",
    exact: true,
    main: () => <TrackPets />,
  },
  {
    path: "/dashboard/my-pets/new",
    exact: true,
    main: () => <AddEditPet />,
  },
  {
    path: "/dashboard/my-pets/:petID",
    exact: true,
    main: () => <AddEditPet />,
  },
  {
    path: "/dashboard/my-pets",
    exact: true,
    main: () => <MyPets />,
  },
  {
    path: "/dashboard/bank-accounts/new",
    exact: true,
    main: () => <AddNewAccount />,
  },
  {
    path: "/dashboard/bank-accounts",
    exact: true,
    main: () => <BankAccounts />,
  },
  {
    path: "/dashboard/help",
    exact: true,
    main: () => <Help />,
  },
  {
    path: "/dashboard/change-password",
    exact: true,
    main: () => <ChangePassword />,
  },
  {
    path: "/dashboard/my-profile",
    exact: true,
    main: () => <MyProfile />,
  },
  {
    path: "/dashboard/pay/:bookingID",
    exact: true,
    main: () => <MakePayment />,
  },
];

export default routes;
