import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  useAddressPredictions,
  useGeoCoder,
  usePosition,
} from "../../../../app/hooks/mapHooks";
import { reverseDate } from "../../../../utils";
import {
  fetchServices,
  getAvailableProviders,
  fetchServiceDetails,
} from "../slice";

const Search = ({
  serviceID,
  setServiceID,
  endDate,
  setEndDate,
  startDate,
  setStartDate,
}) => {
  const dispatch = useDispatch();
  const {
    replace,
    location: { pathname },
  } = useHistory();

  const { serviceID: currentServiceID } = useParams();

  const { serviceDetails, services } = useSelector((state) => {
    return {
      services: state.services.services,
      serviceDetails: state.services.serviceDetails,
    };
  });

  const { lat, lng, currentLocation } = usePosition();

  const [loading, setLoading] = useState(false);
  const [current, setCurrent] = useState(true);
  const [subServiceID, setSubServiceID] = useState("");
  // const [time, setTime] = useState(new Date().toTimeString().split(" ")[0]);
  const [placeId, setPlaceId] = useState("");
  const [address, setAddress] = useState(currentLocation || "");
  const [location, setLocation] = useState("");
  const [showPredictions, setShowPredictions] = useState(false);
  const [error, setError] = useState({});

  const predictions = useAddressPredictions(location || " ");
  const geometry = useGeoCoder(placeId);

  useEffect(() => {
    setLocation(currentLocation);
    setAddress(currentLocation);
  }, [currentLocation]);

  useEffect(() => {
    if (!services) {
      dispatch(fetchServices({ page: 1 }));
    }
  }, [services, dispatch]);

  const validate = () => {
    let errors = {};
    let isValid = true;

    if (!location) {
      isValid = false;
      errors.address = "Required";
    } else if (location && !address) {
      isValid = false;
      errors.address = "Please choose address from predictions.";
    }

    if (!serviceID) {
      isValid = false;
      errors.service = "Required";
    }

    if (!startDate) {
      isValid = false;
      errors.startDate = "Required";
    }

    if (!endDate) {
      isValid = false;
      errors.endDate = "Required";
    }

    setError(errors);
    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      let query = "?cpage=1&perpage=10";

      if (serviceID || currentServiceID) {
        query = query + `&service_id=${serviceID || currentServiceID}`;
      }

      if (
        serviceDetails &&
        serviceDetails.total_service &&
        serviceDetails.total_service.length &&
        subServiceID
      ) {
        query = query + `&sub_service_id=${subServiceID}`;
      }

      if (current) {
        query = query + `&current_lat=${lat}&current_lng=${lng}`;
      } else {
        query =
          query +
          `&current_lat=${geometry && geometry.lat}&current_lng=${
            geometry && geometry.lng
          }`;
      }

      if (startDate) {
        query = query + `&start_date=${reverseDate(startDate)}`;
      }

      if (endDate) {
        query = query + `&end_date=${reverseDate(endDate)}`;
      }

      dispatch(getAvailableProviders({ query })).then(() => {
        setLoading(false);
        const serviceObj = services.data.find((el) => el._id === serviceID);
        replace(`${pathname}?service=${serviceObj.service_name}`);
      });
    }
  };

  return (
    <section className="inner-banner home-banner">
      <div className="banner d-flex justify-content-center">
        <div className="container-fluid align-self-center">
          <div className="row justify-content-center">
            <div className="col-xl-11">
              <div className="caption d-flex  justify-content-center">
                <div className="caption-text font- align-self-center  col-lg-12 ">
                  <div className="form-row align-items-end hero-form">
                    <div className="form-group col-sm-6 col-lg-3 col-xl">
                      <label htmlFor="">I'm looking for?</label>
                      <span className="error">{error.service}</span>
                      <div className="custom-group-input rounded left-icon select_div">
                        <select
                          className="form-control pointer"
                          value={serviceID || currentServiceID}
                          onChange={(e) => {
                            let value = e.target.value;
                            setServiceID(value);
                            dispatch(fetchServiceDetails({ serviceID: value }));
                            setError({ ...error, service: "" });
                          }}
                        >
                          <option value="" disabled>
                            Select your Service
                          </option>
                          {services &&
                            services.data &&
                            services.data.length &&
                            services.data.map((el) => (
                              <option key={el._id} value={el._id}>
                                {el.service_name}
                              </option>
                            ))}
                        </select>
                        <span className="group-icon-left ">
                          <i className="idogfeet i30px"></i>
                        </span>
                      </div>
                    </div>
                    {serviceDetails &&
                    serviceDetails.total_service &&
                    serviceDetails.total_service.length &&
                    serviceDetails.service_id === serviceID ? (
                      <div className="form-group col-sm-6 col-lg-3 col-xl">
                        <label htmlFor="">I'm looking for?</label>
                        <div className="custom-group-input rounded left-icon select_div">
                          <select
                            className="form-control pointer"
                            value={subServiceID || ""}
                            onChange={(e) => {
                              let value = e.target.value;
                              setSubServiceID(value);
                            }}
                          >
                            <option value="" disabled>
                              Select sub service
                            </option>
                            {serviceDetails.total_service.map((el) => (
                              <option key={el._id} value={el._id}>
                                {el.sub_service_name}
                              </option>
                            ))}
                          </select>
                          <span className="group-icon-left ">
                            <i className="idogfeet i30px"></i>
                          </span>
                        </div>
                      </div>
                    ) : null}
                    <div
                      className="form-group col-sm-6 col-lg-3 col-xl"
                      style={{ position: "relative" }}
                    >
                      <label htmlFor="">I'm looking for?</label>
                      <span className="error">{error.address}</span>
                      <div className="custom-group-input rounded both-icon">
                        <input
                          type="text"
                          className="form-control "
                          placeholder="Address"
                          required
                          onInput={() => setError({ ...error, address: "" })}
                          value={address ? address : location}
                          onChange={(e) => {
                            let value = e.target.value;
                            setAddress("");
                            setLocation(value);
                            setShowPredictions(true);
                          }}
                        />
                        <span className="group-icon-left ">
                          <i className="imapmarker i30px"></i>
                        </span>
                        <span className="group-icon-right ">
                          <i
                            className="ilocationcircle i30px pointer"
                            onClick={() => {
                              setCurrent(true);
                              setAddress(currentLocation);
                              setLocation(currentLocation);
                            }}
                          ></i>
                        </span>
                        {showPredictions && (
                          <ul className="predictions">
                            {predictions.map((prediction, index) => (
                              <li
                                key={index}
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  setCurrent(false);
                                  setPlaceId(prediction.placeId);
                                  setAddress(prediction.description);
                                  setLocation(prediction.description);
                                  setShowPredictions(false);
                                }}
                              >
                                {prediction.description}
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                    </div>
                    <div className="form-group col-sm-6 col-lg-3 col-xl">
                      <label htmlFor="">Start Date</label>
                      <span className="error">{error.startDate}</span>
                      <div className="custom-group-input rounded left-icon">
                        <input
                          type="date"
                          className="form-control "
                          placeholder="Start Date"
                          value={startDate || ""}
                          min={new Date().toISOString().split("T")[0]}
                          required
                          onChange={(e) => {
                            let value = e.target.value;
                            setStartDate(value);
                          }}
                        />
                        <span className="group-icon-left ">
                          <i className="icalendar i30px"></i>
                        </span>
                      </div>
                    </div>
                    <div className="form-group col-sm-6 col-lg-3 col-xl">
                      <label htmlFor="">End Date</label>
                      <span className="error">{error.endDate}</span>
                      <div className="custom-group-input rounded left-icon">
                        <input
                          type="date"
                          className="form-control "
                          placeholder="Start Date"
                          // value={endDate || ""}
                          disabled={!startDate}
                          min={
                            startDate
                              ? startDate
                              : new Date().toISOString().split("T")[0]
                          }
                          required
                          onChange={(e) => {
                            let value = e.target.value;
                            setEndDate(value);
                          }}
                        />
                        <span className="group-icon-left ">
                          <i className="icalendar i30px"></i>
                        </span>
                      </div>
                    </div>
                    {/* <div className="form-group col-sm-6 col-lg-3 col-xl">
                      <label htmlFor="">Select Time</label>
                      <span className="error">{error.time}</span>
                      <div className="custom-group-input rounded left-icon">
                        <input
                          type="time"
                          className="form-control "
                          placeholder="Start Time"
                          value={time || ""}
                          min={new Date().toTimeString().split(" ")[0]}
                          required
                          onChange={(e) => {
                            let value = e.target.value;
                            setTime(value);
                          }}
                        />
                        <span className="group-icon-left ">
                          <i className="iclock i30px"></i>
                        </span>
                      </div>
                    </div> */}

                    <div className="form-group col-sm-6 col-lg-3  col-xl">
                      <button
                        type="button"
                        className="btn btn-primary btn-feet --blue btn-lg btn-block rounded-pill"
                        onClick={handleSubmit}
                      >
                        {loading ? "Searching..." : "Search"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Search;
