/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useEffect, useState, useCallback } from "react";
import { debounce } from "lodash";
import { useSnackbar } from "notistack";

// Hook
let cachedScripts = [];

export function useScript(src) {
  // Keeping track of script loaded and error state
  const [state, setState] = useState({
    loaded: false,
    error: false,
  });
  const [script, setScript] = useState("");

  useEffect(
    () => {
      // If cachedScripts array already includes src that means another instance ...
      // ... of this hook already loaded this script, so no need to load again.
      if (cachedScripts.includes(src)) {
        setState({
          loaded: true,
          error: false,
          script: { src: src, async: true, defer: true },
        });
      } else {
        cachedScripts.push(src);

        // Create script
        let script = document.createElement("script");
        script.src = src;
        script.async = true;
        script.defer = true;

        // Script event listener callbacks for load and error
        const onScriptLoad = () => {
          setState({
            loaded: true,
            error: false,
          });
        };

        const onScriptError = () => {
          // Remove from cachedScripts we can try loading again
          const index = cachedScripts.indexOf(src);
          if (index >= 0) cachedScripts.splice(index, 1);
          script.remove();

          setState({
            loaded: true,
            error: true,
          });
        };

        script.addEventListener("load", onScriptLoad);
        script.addEventListener("error", onScriptError);

        // Add script to document body
        document.body.appendChild(script);
        setScript(script);

        // Remove event listeners on cleanup
        return () => {
          script.removeEventListener("load", onScriptLoad);
          script.removeEventListener("error", onScriptError);
          setScript("");
        };
      }
    },
    [src] // Only re-run effect if script src changes
  );

  return [state.loaded, state.error, script];
}

export function useAddressPredictions(input) {
  const [predictions, setPredictions] = useState([]);

  const autocomplete = useRef();

  if (!autocomplete.current) {
    autocomplete.current = new window.google.maps.places.AutocompleteService();
  }

  function getPlacePredictions(input) {
    autocomplete.current.getPlacePredictions({ input }, (predictions) => {
      if (predictions && predictions.length) {
        setPredictions(
          predictions.map((prediction) => ({
            description: prediction.description,
            placeId: prediction.place_id,
          }))
        );
      }
    });
  }

  const debouncedGetPlacePredictions = useCallback(
    debounce(getPlacePredictions, 500),
    []
  );

  useEffect(() => {
    debouncedGetPlacePredictions(input);
  }, [input, debouncedGetPlacePredictions]);

  return predictions;
}

export function useGeoCoder(placeId) {
  const [geometry, setGeometry] = useState(null);

  function getGeomtry(placeId) {
    var geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ placeId }, function (results, status) {
      if (status === "OK") {
        const result = results[0];
        setGeometry({
          lat: result.geometry.location.lat(),
          lng: result.geometry.location.lng(),
          address: result.formatted_addres,
        });
      } else {
        console.error(results);
      }
    });
  }

  const debouncedGetPlacePredictions = useCallback(
    debounce(getGeomtry, 500),
    []
  );

  useEffect(() => {
    debouncedGetPlacePredictions(placeId);
  }, [placeId, debouncedGetPlacePredictions]);

  return geometry;
}

export const usePosition = () => {
  const [position, setPosition] = useState({});
  const [currentLocation, setCurrentLoaction] = useState("");
  const [error, setError] = useState(null);

  const geocoder = new window.google.maps.Geocoder();

  const onChange = ({ coords }) => {
    const latlng = {
      lat: parseFloat(coords.latitude),
      lng: parseFloat(coords.longitude),
    };

    setPosition(latlng);

    geocoder.geocode({ location: latlng }, (results, status) => {
      if (status === "OK") {
        if (results[0]) {
          let formatted_addres = results[0].formatted_address;
          setCurrentLoaction(formatted_addres);
        } else {
          window.alert("No results found");
        }
      } else {
        window.alert("Geocoder failed due to: " + status);
      }
    });
  };

  const onError = (error) => {
    setError(error.message);
  };

  useEffect(() => {
    const geo = navigator.geolocation;
    if (!geo) {
      setError("Geolocation is not supported");
      return;
    }
    let watcher = geo.watchPosition(onChange, onError);
    return () => geo.clearWatch(watcher);
  }, []);

  return { ...position, error, currentLocation };
};

export const useCurrentPosition = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [position, setPosition] = useState({});

  const onChange = ({ coords }) => {
    setPosition({
      latitude: coords.latitude,
      longitude: coords.longitude,
    });
  };
  const onError = (error) => {
    enqueueSnackbar(error.message, {
      variant: "info",
    });
  };
  useEffect(() => {
    const geo = navigator.geolocation;
    if (!geo) {
      enqueueSnackbar("Geolocation is not supported", {
        variant: "info",
      });
      return;
    }
    let watcher = geo.watchPosition(onChange, onError);
    return () => geo.clearWatch(watcher);
  }, []);
  return { ...position };
};
