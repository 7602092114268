import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import Skeleton from "@material-ui/lab/Skeleton";

import avatar_png from "../../../../images/avatar.png";

import { usePrevious } from "../../../../app/hooks";
import { getProviderDetails } from "../slice";
import Details from "./details";

const baseURL = process.env.REACT_APP_PAW_HUGGER_WEB_BASE_URL;

const scrollTo = (ref) =>
  window.scrollTo({
    top: ref.current.offsetTop - 110,
    behavior: "smooth",
  });

const Profile = () => {
  const dispatch = useDispatch();
  const { providerID } = useParams();
  const { search } = useLocation();
  const ref = useRef(null);

  const { loading, details } = useSelector((state) => {
    return {
      loading: state.services.loading,
      details: state.services.details,
    };
  });

  const [psID, setPsID] = useState("");

  const prevProviderID = usePrevious(providerID);

  useEffect(() => {
    if (search && search.includes("psID")) {
      let ID = search.split("psID=")[1];
      setPsID(ID);
    }
  }, [search]);

  useEffect(() => {
    if (!loading && providerID && (!details || providerID !== prevProviderID)) {
      window.scrollTo(0, 0);
      dispatch(getProviderDetails({ providerID }));
    }
  }, [dispatch, details, providerID, prevProviderID, loading]);

  const getPrice = (providerServices) => {
    const data = providerServices.find((el) => el._id === psID);
    if (data) {
      return data.providerServicedata.price;
    }
    return 0;
  };

  return (
    <main className="page-wrapper ">
      <section className="profile-banner">
        <div className="profileDetail">
          <div className="provideravtar provideravtar-lg provideravtar-xl">
            <img
              className="avtar"
              src={
                details && details.profileurl
                  ? `${baseURL}/${details.profileurl}`
                  : avatar_png
              }
              alt="avtar"
            />
            <div className="rating-star h6">
              <div className="circlerating">
                <i className="iratingstar active"></i>
              </div>
              <div>{details && details.rating}</div>
            </div>
          </div>
        </div>
      </section>
      <div className="text-center avdetails">
        <h5>
          {details && details.first_name + " " + details.last_name}
          {details && details.is_verified ? (
            <i className="i30px ml-4 iverified"></i>
          ) : null}
        </h5>

        {details &&
        details.providerServices &&
        details.providerServices.length ? (
          <p className="price h4 text-primary">
            ${getPrice(details.providerServices)}
            <span>/hr</span>
          </p>
        ) : null}
      </div>
      <section className="py-5">
        <div className="container">
          {loading ? (
            <Skeleton variant="rect" height={100} />
          ) : (
            <div className="row align-items-center">
              <div className="col-lg-8">
                <div className="detail-box mb-4 mb-lg-0 shadow round">
                  <div className="row align-items-center">
                    <div className="col-sm-6 col-md-6">
                      <div className="mx-auto ">
                        <p>
                          <i className="i25px iexperience"></i>
                          {details && details.exp_years} year exp
                        </p>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6">
                      <div className="mx-auto ">
                        <p>
                          <i className="i25px ilocationmarker"></i>
                          {details && details.address}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <button
                  type="button"
                  className="btn btn-primary btn-feet --blue btn-lg btn-block rounded-pill"
                  onClick={() => scrollTo(ref)}
                >
                  Book Now
                </button>
              </div>
            </div>
          )}
        </div>
      </section>
      {details && <Details ref={ref} data={details} />}
    </main>
  );
};

export default Profile;
