/* eslint-disable linebreak-style */
/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */

import { createSlice } from "@reduxjs/toolkit";
import { axiosGet, postEncoded } from "../../../api";
import { getMyBookings } from "../dashboard/slice";

const initialState = {
  loading: false,
  error: null,
};

const services = createSlice({
  name: "services",
  initialState,
  reducers: {
    loadingStart(state) {
      state.loading = true;
      state.error = null;
    },
    loadingStop(state) {
      state.loading = false;
    },
    setServices(state, action) {
      state.services = action.payload;
      state.loading = false;
      state.error = null;
    },
    setServiceDetails(state, action) {
      state.serviceDetails = action.payload;
      state.loading = false;
      state.error = null;
    },
    setProviderDetails(state, action) {
      state.details = action.payload;
      state.loading = false;
      state.error = null;
    },
    setProvidersList(state, action) {
      const { data, more } = action.payload;
      if (more && state.providers) {
        state.providers = {
          ...data,
          data: [...state.providers.data, ...data.data],
        };
      } else {
        state.providers = data;
      }
      state.loading = false;
      state.error = null;
    },
    setBookingDetails(state, action) {
      state.formData = action.payload;
      state.error = null;
      state.loading = false;
    },
    setSelectedService(state, action) {
      state.selectedService = action.payload;
      state.error = null;
      state.loading = false;
    },
    setBookingConfirmation(state, action) {
      state.booking = action.payload;
      state.error = null;
      state.loading = false;
    },
  },
});

export const {
  loadingStart,
  loadingStop,
  setServices,
  setProviderDetails,
  setProvidersList,
  setBookingDetails,
  setSelectedService,
  setBookingConfirmation,
  setServiceDetails,
} = services.actions;

export default services.reducer;

export const fetchServices = () => async (dispatch) => {
  dispatch(loadingStart());
  const { data, status } = await axiosGet({
    uri: "/service_list_without_token?cpage=1&perpage=10",
    authName: "phgr",
  });

  if (status === 200) {
    dispatch(setServices(data));
  }

  dispatch(loadingStop());

  return null;
};

export const fetchServiceDetails = ({ serviceID }) => async (dispatch) => {
  dispatch(loadingStart());
  const { data, status } = await axiosGet({
    uri: `/get_service_details_without_token?service_id=${serviceID}`,
    authName: "phgr",
  });

  if (status === 200) {
    dispatch(setServiceDetails(data && data.data && data.data[0]));
  }

  dispatch(loadingStop());

  return { data: data && data.data && data.data[0] };
};

export const getAvailableProviders = ({ query, more }) => async (dispatch) => {
  dispatch(loadingStart());
  const { data, status } = await axiosGet({
    uri: `/get_available_service_provider${query}`,
    authName: "phgr",
  });

  if (status === 200) {
    dispatch(setProvidersList({ data, more }));
  }

  dispatch(loadingStop());

  return null;
};

export const getProviderDetails = ({ providerID }) => async (dispatch) => {
  dispatch(loadingStart());
  const {
    data: { data },
    status,
  } = await axiosGet({
    uri: `/provider_details?provider_id=${providerID}`,
    authName: "phgr",
  });

  if (status === 200) {
    const details = data && data[0];
    dispatch(setProviderDetails(details));
  }

  dispatch(loadingStop());

  return null;
};

export const getProviderAvailability = ({
  providerID,
  date,
  serviceID,
}) => async (dispatch) => {
  dispatch(loadingStart());
  const { data, status } = await axiosGet({
    uri: `/get_availability?provider_id=${providerID}&date=${date}&service_id=${serviceID}`,
    authName: "phgr",
  });

  dispatch(loadingStop());

  return { data, status };
};

export const requestBooking = (payload) => async (dispatch) => {
  dispatch(loadingStart());
  const {
    data: { message, errorMessage, data },
    status,
  } = await postEncoded({
    uri: "/send_request",
    payload,
    authName: "phgr",
  });

  if (status === 200) {
    dispatch(setBookingConfirmation(data));
    dispatch(getMyBookings({ page: 1, type: 1 }));
  }

  dispatch(loadingStop());

  return { status, errorMessage, message };
};

export const selectLoading = (state) => state.services.loading;
