import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import DayPicker from "react-day-picker";
import "react-day-picker/lib/style.css";
import { useSnackbar } from "notistack";

import dog_walker_png from "../../../../../images/profile/dog-walker.png";

import { usePrevious } from "../../../../../app/hooks";
import {
  useCurrentPosition,
  usePosition,
} from "../../../../../app/hooks/mapHooks";
import { reverseDate } from "../../../../../utils";
import {
  getProviderAvailability,
  setBookingDetails,
  setSelectedService,
} from "../../slice";

const baseURL = process.env.REACT_APP_PAW_HUGGER_WEB_BASE_URL;

const modifiersStyles = {
  offDays: {
    color: "#ffc107",
    backgroundColor: "#fffdee",
  },
  workDays: {
    color: "#00bcd4",
    backgroundColor: "#ecf8ff",
  },
  selected: {
    color: "white",
    backgroundColor: "#90ee90",
  },
  outside: {
    backgroundColor: "white",
  },
};

const Availability = ({ data, services }) => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const { providerID } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const { loading } = useSelector((state) => {
    return {
      loading: state.services.loading,
    };
  });

  const { currentLocation } = usePosition();
  const { latitude: lat, longitude: lng } = useCurrentPosition();

  const [serviceID, setServiceID] = useState("");
  const [date, setDate] = useState({ selectedDay: undefined });
  const [available, setAvalable] = useState(0);
  const [slots, setSlots] = useState([]);
  const [slotTime12hr, setSlotTime12hr] = useState("");
  const [slotTime24hr, setSlotTime24hr] = useState("");
  const [location, setLocation] = useState("");
  const [error, setError] = useState("");
  const [disabledDays, setDisabledDays] = useState([
    { before: new Date() },
    { daysOfWeek: [] },
  ]);
  const [modifiers, setModifiers] = useState({
    offDays: { daysOfWeek: [] },
    workDays: { daysOfWeek: [] },
    selected: date.selectedDay,
  });

  const prevLocation = usePrevious(currentLocation);

  useEffect(() => {
    if (data && data.length) {
      let offDays = [0, 1, 2, 3, 4, 5, 6];
      let workDays = [];

      data.forEach((element) => {
        workDays.push(element.working_day);
        offDays = offDays.filter((item) => item !== element.working_day);
      });

      setDisabledDays([{ before: new Date() }, { daysOfWeek: offDays }]);
      setModifiers({
        offDays: { daysOfWeek: offDays },
        workDays: { daysOfWeek: workDays },
        selected: undefined,
      });
    }
  }, [data]);

  useEffect(() => {
    if (prevLocation !== currentLocation) {
      setLocation(currentLocation);
    }
  }, [currentLocation, prevLocation]);

  useEffect(() => {
    if (services && services.length) {
      setServiceID(services[0]._id);
    }
  }, [services]);

  const getAvailability = ({ selectedDay }) => {
    if (providerID && serviceID) {
      dispatch(
        getProviderAvailability({ providerID, date: selectedDay, serviceID })
      ).then(({ data: responseData, status }) => {
        if (status === 200 && responseData.message) {
          setAvalable(responseData.is_available);
          setSlots(responseData.slot_time);

          if (!responseData.is_available) {
            enqueueSnackbar(responseData.message || "Success", {
              variant: "info",
            });
          }
        }
      });
    }
    if (!serviceID) {
      enqueueSnackbar("Please select a service to get availability.", {
        variant: "info",
      });
    }
  };

  const validate = () => {
    let errors = "";
    let isValid = true;

    if (!location) {
      isValid = false;
      errors =
        "Location is required. Please let this app access your loaction.";
    }

    if (!serviceID) {
      isValid = false;
      errors = "Required";
    }

    if (!date) {
      isValid = false;
      errors = "Required";
    }

    if (!available) {
      isValid = false;
      errors = "Provider is not available at this time.";
    }

    setError(errors);
    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (lat && lng && validate()) {
      let nextDay = new Date(date.selectedDay);
      nextDay.setDate(date.selectedDay.getDate());
      const formatedDate = reverseDate(nextDay.toISOString().split("T")[0]);
      const payload = {
        service_id: serviceID,
        provider_id: providerID,
        cust_lat: lat,
        cust_lng: lng,
        cust_location: location,
        start_date: formatedDate,
        booking_time_12hr: slotTime12hr,
        booking_time_24hr: slotTime24hr,
      };

      dispatch(setBookingDetails(payload));

      push(`/services/${providerID}/select-pet`);
    } else if (!(lat && lng)) {
      enqueueSnackbar(
        "Location is required. Please let this app access your loaction.",
        {
          variant: "error",
        }
      );
    }
  };

  function handleDayClick(day, modifiers = {}) {
    if (modifiers.disabled) {
      enqueueSnackbar("Provider is not available at this day.", {
        variant: "warning",
      });
      return;
    }
    setDate({
      selectedDay: modifiers.selected ? undefined : day,
    });

    let nextDay = new Date(day);
    nextDay.setDate(day.getDate());
    const selectedDay = reverseDate(nextDay.toISOString().split("T")[0]);

    getAvailability({ selectedDay });
  }

  return (
    <div
      className="tab-pane fade show active"
      id="availabilityTab"
      role="tabpanel"
    >
      <span className="error">{error}</span>
      <div className="radio-tabs row">
        {services &&
          services.map((el) => (
            <div className="col-md-4" key={el._id}>
              <input
                type="radio"
                name="availviltyType"
                id={el._id}
                checked={el._id === serviceID}
                onChange={() => {
                  setSlots([]);
                  setServiceID(el._id);
                  dispatch(setSelectedService(el));
                }}
              />
              <label
                className="label-button rounded-pill d-block text-left"
                htmlFor={el._id}
              >
                <img
                  src={
                    el && el.service_image
                      ? `${baseURL}/${el.service_image}`
                      : dog_walker_png
                  }
                  alt="img"
                />
                {el.service_name}
              </label>
            </div>
          ))}
      </div>

      <div className="col-sm-9 mx-auto">
        <div className="my-5" id="datepicker">
          <DayPicker
            initialMonth={new Date()}
            disabledDays={disabledDays}
            fromMonth={new Date()}
            // toMonth={new Date(2018, 11)}
            // fixedWeeks
            selectedDays={date.selectedDay}
            onDayClick={handleDayClick}
            modifiers={modifiers}
            modifiersStyles={modifiersStyles}
          />
        </div>
        <br />
        <p className="h5 mb-4">
          Available
          <span className="text-primary">({data && data.length} days)</span>
        </p>
        {loading ? (
          "Loading..."
        ) : slots && slots.length ? (
          <div className="radio-select radio-slots">
            {slots.map((el, index) => (
              <Fragment key={index}>
                <input
                  type="radio"
                  name="slotTime"
                  id={"slot" + index}
                  checked={slotTime12hr === el["12hr"]}
                  disabled={el.isBooking}
                  onChange={() => {
                    setSlotTime12hr(el["12hr"]);
                    setSlotTime24hr(el["24hr"]);
                  }}
                />
                <label htmlFor={"slot" + index} className="">
                  {el["12hr"]}
                </label>
              </Fragment>
            ))}
            <br />
            <br />
            <div className="col-lg-4">
              <button
                type="button"
                className="btn btn-primary btn-feet --blue btn-lg btn-block rounded-pill"
                onClick={handleSubmit}
              >
                Book Now
              </button>
            </div>
          </div>
        ) : (
          <p>Please select a date to get available slots.</p>
        )}
      </div>
    </div>
  );
};

export default Availability;
