import React, { forwardRef } from "react";

import hwc1_png from "../../images/home/hwc1.png";
import hwc2_png from "../../images/home/hwc2.png";
import hwc3_png from "../../images/home/hwc3.png";
import hwc4_png from "../../images/home/hwc4.png";
import hws1_png from "../../images/home/hws1.png";
import hws2_png from "../../images/home/hws2.png";
import hws3_png from "../../images/home/hws3.png";
import hws4_png from "../../images/home/hws4.png";

const How = forwardRef((props, ref) => {
  return (
    <section className="home-how-works" ref={ref}>
      <div className="container-fluid bg-service-img px-0">
        <div className="d-lg-flex">
          <div className="col-lg-5 px-0">
            <div className="text-matter bg-light-blue round-right">
              <h4 className="title text-primary">
                Thank you for your interest in joining our Paw Hugger Platform
                to find your Pet <span>Service Provider</span>
              </h4>
              <ul>
                <li className="text-primary">
                  We offer our platform to our pet owner to find professional
                  service provider community all over Australia. We require that
                  our service provider has a passion and the relevant experience
                  for your pets.
                </li>
                <li className="text-primary">
                  If you are planning an overseas trip, a weekend break, or an
                  overnight getaway, your Paw Hugger Pet Sitter will care for
                  your furry friend while you are away.
                </li>
                <li className="text-primary">
                  At Paw Hugger, we know it can be hard to entrust your pet’s
                  welfare to someone else. After all, they are not just a dog,
                  cat, puppy, rabbit, guinea pig or bird – they are part of your
                  family. It is for this reason that Paw Hugger ensure that all
                  our service provider are passionate about your pet,
                  professional, experience and loves what they do. Also all pet
                  owner can read reviews from previous clients on the Paw Hugger
                  mobile app which is free to download or on our Paw Hugger
                  website. By doing so you can learn more about your pet service
                  provider and their pet care style from other Pet Owners.
                </li>
                <li className="text-primary">
                  When you book with our Paw Hugger mobile app or thru our Paw
                  Hugger website for dog walker, dog sitter, dog boarder and dog
                  groomer, the service provider does not get paid until the
                  service has been performed. Veterinary service is only paid at
                  the veterinary clinics there are no deduction when you booked.
                </li>
                <li className="text-primary">
                  Pet Owner can also sign up to be our pet service provider.
                </li>
                <h6 className="text-primary">
                  Paw Hugger is the perfect opportunity for pet lovers. Here are
                  just some of the benefits that we offer:
                </h6>
                <ul>
                  <li className="text-primary">
                    Flexibility and control over your schedule and the rates you
                    charge
                  </li>
                  <li className="text-primary">
                    Safe, secure, and convenient online payments - get paid
                    doing what you love
                  </li>
                  <li className="text-primary">
                    Attracting and connecting pet owners with your services
                  </li>
                </ul>
                <h6 className="text-primary">
                   Paw Hugger pet service provider are required to:
                </h6>
                <ul>
                  <li className="text-primary">
                    Have a passion for and experience with caring for pets
                  </li>
                  <li className="text-primary">
                    Conduct themselves in a professional and polite manner
                  </li>
                  <li className="text-primary">
                    Take commitment, responsibilities and reliability seriously
                    when looking after pets
                  </li>
                  <li className="text-primary">
                    Be patient and compassionate when working with pets
                  </li>
                  <li className="text-primary">
                    Pet owners are looking for reliable, available pet service
                    provider to take care of their precious pets. Nothing is
                    more important to them than finding the perfect service
                    provider, who truly connects with and loves their pets as
                    their own
                  </li>
                  <li className="text-primary">
                    When a pet owner starts looking for a pet service, they
                    really want to find out who the pet service provider is. So
                    it is really important to cover certain information in your
                    public profile
                  </li>
                </ul>
                <li className="text-primary">
                  Pet parents depend on pet service provider to be 100%
                  committed to caring for their pets, Before you commit to the
                  booking think about how the above requirements can fit into
                  your schedule and external commitments before you apply as a
                  pet service provider .
                </li>
                <h5 className="text-primary">
                  What are the services you can offer on Paw Hugger?
                </h5>
                <ul>
                  <li>
                    <h6 className="text-primary">Dog Boarding - </h6>
                    <small className="text-primary">
                      24-hour overnight care for a dog in your home
                    </small>
                  </li>

                  <li>
                    <h6 className="text-primary">
                      Pet Sitting (home visits) - 
                    </h6>
                    <small className="text-primary">
                      Drop-in visits to a pet’s home to check up on them
                    </small>
                  </li>
                  <li>
                    <h6 className="text-primary"> Dog Walking -</h6> 
                    <small className="text-primary">
                      Visit the dog’s home and walk them
                    </small>
                  </li>
                  <li>
                    <h6 className="text-primary">Dog Grooming – </h6>
                    <small className="text-primary">
                      Offer professional grooming services for the pets.
                    </small>
                  </li>
                  <li>
                    <h6 className="text-primary">Veterinary -</h6>
                    <small className="text-primary">
                      General Health Check, Dental, Vaccinations, Micro
                      Chippings, Surgery
                    </small>
                  </li>
                </ul>
              </ul>
            </div>
          </div>
          <div className="col-lg-7">
            <div className="row">
              <div className="col-sm-6">
                <div className="how-work-item card">
                  <img src={hwc1_png} className="card-img-top" alt="..store." />
                  <div className="card-body">
                    <p className="card-text ">Login Register </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="how-work-item card">
                  <img src={hwc2_png} className="card-img-top" alt="..store." />
                  <div className="card-body">
                    <p className="card-text ">
                      Select Date & Time and Search Pet Service provider
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="how-work-item card">
                  <img src={hwc3_png} className="card-img-top" alt="..store." />
                  <div className="card-body">
                    <p className="card-text ">
                      View the list of Services provider and click on Book Now
                      for Booking
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="how-work-item card">
                  <img src={hwc4_png} className="card-img-top" alt="..store." />
                  <div className="card-body">
                    <p className="card-text ">
                      Select Your Pet and Send Booking Request
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid py-4 px-0">
        <div className="d-lg-flex">
          <div className="col-lg-5 order-md-2 px-0">
            <div className="text-matter bg-light-gray round-left">
              <h4 className="title">
                Thank you for your interest in becoming a Paw Hugger Pet
                <span>Service Provider</span>
              </h4>
              <ul className="text-dark">
                <li className="text-dark">
                  We offer our platform to our pet service provider community to
                  offer their professional services to our pet owner all over
                  Australia. We require that our service provider has a passion
                  for pets.
                </li>
                <li className="text-dark">
                  Paw Hugger is the perfect opportunity for a pet lover to get
                  paid doing what you love. Have flexibility and control over
                  your schedule and the rates you charge.
                </li>
                <li className="text-dark">
                  Plus, benefit from joining our free to load mobile app.
                </li>
                <h6>
                  Paw Hugger is the perfect opportunity for pet lovers. Here are
                  just some of the benefits that we offer:
                </h6>
                <ul>
                  <li>
                    Flexibility and control over your schedule and the rates you
                    charge
                  </li>
                  <li>
                    Safe, secure, and convenient online payments - get paid
                    doing what you love
                  </li>
                  <li>
                    Attracting and connecting pet owners with your services
                  </li>
                </ul>
                <h6> Paw Hugger pet service provider are required to:</h6>
                <ul>
                  <li>
                    Have a passion for and experience with caring for pets
                  </li>
                  <li>
                    Conduct themselves in a professional and polite manner
                  </li>
                  <li>
                    Take commitment, responsibilities and reliability seriously
                    when looking after pets
                  </li>
                  <li>Be patient and compassionate when working with pets</li>
                  <li>
                    Pet owners are looking for reliable, available pet service
                    provider to take care of their precious pets. Nothing is
                    more important to them than finding the perfect service
                    provider, who truly connects with and loves their pets as
                    their own
                  </li>
                  <li>
                    When a pet owner starts looking for a pet service, they
                    really want to find out who the pet service provider is. So
                    it is really important to cover certain information in your
                    public profile
                  </li>
                </ul>
                <p>
                  Pet parents depend on pet service provider to be 100%
                  committed to caring for their pets, Before you commit to the
                  booking think about how the above requirements can fit into
                  your schedule and external commitments before you apply as a
                  pet service provider .
                </p>
                <h5>What are the services you can offer on Paw Hugger?</h5>
                <ul>
                  <li>
                    <h6>Dog Boarding -</h6>
                    <small>24-hour overnight care for a dog in your home</small>
                  </li>
                  <li>
                    <h6>Pet Sitting (home visits) - </h6>
                    <small>
                      Drop-in visits to a pet’s home to check up on them
                    </small>
                  </li>
                  <li>
                    <h6>Dog Walking - </h6>
                    <small>Visit the dog’s home and walk them</small>
                  </li>
                  <li>
                    <h6>Dog Grooming – </h6>
                    <small>
                      Offer professional grooming services for the pets.
                    </small>
                  </li>
                  <li>
                    <h6>Veterinary – </h6>
                    <small>
                      General Health Check, Dental, Vaccinations, Micro
                      Chippings, Surgery
                    </small>
                  </li>
                </ul>
              </ul>
            </div>
          </div>
          <div className="col-lg-7 order-md-1">
            <div className="row">
              <div className="col-sm-6">
                <div className="how-work-item card">
                  <img src={hws1_png} className="card-img-top" alt="store." />
                  <div className="card-body">
                    <p className="card-text ">Login Register </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="how-work-item card">
                  <img src={hws2_png} className="card-img-top" alt="store." />
                  <div className="card-body">
                    <p className="card-text ">
                      Setup your profile with Paw Hugger
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="how-work-item card">
                  <img src={hws3_png} className="card-img-top" alt="store." />
                  <div className="card-body">
                    <p className="card-text ">
                      Get Bookings and Provide Services to Customer
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="how-work-item card">
                  <img src={hws4_png} className="card-img-top" alt="..store." />
                  <div className="card-body">
                    <p className="card-text "> Get Paid and Earn Money </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
});

export default How;
