import React from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import book_request_png from "../../../../images/profile/book-request.png";

import { useToggle } from "../../../../app/hooks";
import { setBookingDetails, setSelectedService } from "../slice";

const Success = () => {
  const { goBack, replace } = useHistory();
  const [open, toggle] = useToggle(true);
  const dispatch = useDispatch();

  const data = useSelector((state) => state.services.booking);

  const handleSubmit = (e) => {
    dispatch(setBookingDetails(null));
    dispatch(setSelectedService(null));
    toggle();
    replace("/dashboard/my-bookings/pending");
  };

  const handleHide = () => {
    toggle();
    goBack();
  };

  return (
    <Modal
      show={open}
      onHide={handleHide}
      size="xl"
      id="bookingsuccess"
      className="modal form-popup "
      aria-labelledby="exampleModalLabel"
    >
      <div className="modal-body ">
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={handleHide}
        ></button>
        <div className="row no-gutters justify-content-center">
          <div className="col-sm-8 py-4 col-lg-6 col-xl-5 text-center">
            <img src={book_request_png} alt="img" className="img-fluid" />
            <p className="h5 text-primary">ID: {data && data.booking_id}</p>
            <p>
              Your request for {data && data.service_name} is sent to the
              service provider. We will notify once it is accepted.
            </p>
            <div className="col-10 mx-auto">
              <button
                className="btn my-5 btn-primary btn-feet --blue btn-lg btn-block rounded-pill"
                onClick={handleSubmit}
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default Success;
