import React, { Fragment } from "react";
import GoogleLogin from "react-google-login";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";

import google_without_shadow_png from "../../../images/home/google_without-shadow.png";

import { socialLogin } from "../slice";

const Google = () => {
  const dispatch = useDispatch();
  const { replace } = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const responseGoogle = async (response) => {
    const { error, profileObj } = response;
    if (!error && profileObj) {
      const { email, familyName, givenName, googleId } = profileObj;
      const payload = {
        social_id: googleId,
        social_type: 1,
        first_name: givenName,
        last_name: familyName,
        email,
      };

      dispatch(socialLogin(payload)).then(
        ({ message, errorMessage, status }) => {
          if (status === 200) {
            enqueueSnackbar(message || "Success", {
              variant: "success",
            });
            replace("/");
          } else {
            enqueueSnackbar(errorMessage || "Error", {
              variant: "error",
            });
          }
        }
      );
    } else {
      // enqueueSnackbar(error || "Error", {
      //   variant: "error",
      // });
      console.error("Error:", error);
    }
  };

  return (
    <Fragment>
      <GoogleLogin
        clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
        render={(renderProps) => (
          <img
            src={google_without_shadow_png}
            alt="fbimg"
            disabled={renderProps.disabled}
            onClick={renderProps.onClick}
          />
        )}
        onSuccess={responseGoogle}
        onFailure={responseGoogle}
        cookiePolicy={"single_host_origin"}
      />
    </Fragment>
  );
};

export default Google;
