import React from "react";
import Slider from "react-slick";

import avatar_png from "../../../../../images/avatar.png";

import { NextArrow, PrevArrow } from "../../../../../components/arrows";

const baseURL = process.env.REACT_APP_PAW_HUGGER_WEB_BASE_URL;

const settings = {
  autoplay: true,
  autoplaySpeed: 2000,
  infinite: true,
  speed: 500,
  dots: true,
  slidesToScroll: 1,
  className: "js-main-slider",
  slidesToShow: 10,
  arrows: true,
  draggable: true,
  fade: false,
  adaptiveHeight: true,
  centerPadding: 30,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const SentPhotos = ({ data }) => {
  return (
    <div className="booking-item px-5">
      <p className="d-flex">
        <i className="i25px ilocationmarker"></i>
        {data && data.location}
      </p>
      <p className="d-flex">
        <i className="i25px ibookclock-time"></i>
        {data && new Date(data.time).toDateString()}
      </p>
      <ul className="details-gallery ">
        <Slider {...settings}>
          {data &&
            data.trackImage &&
            data.trackImage.length &&
            data.trackImage.map((el) => (
              <li key={el._id}>
                <img
                  src={
                    el.image_path ? `${baseURL}/${el.image_path}` : avatar_png
                  }
                  alt="img"
                  className="img-fluid"
                />
              </li>
            ))}
        </Slider>
      </ul>
    </div>
  );
};

export default SentPhotos;
