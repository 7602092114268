import React from "react";

export function NextArrow(props) {
  const { onClick } = props;
  return (
    <i
      className="fas fa-angle-right slick-arrow next-arrow"
      onClick={onClick}
    />
  );
}

export function PrevArrow(props) {
  const { onClick } = props;
  return (
    <i className="fas fa-angle-left slick-arrow prev-arrow" onClick={onClick} />
  );
}
