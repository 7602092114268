import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useLocation, useParams } from "react-router-dom";

import avatar_png from "../../../../images/avatar.png";

import { getAvailableProviders } from "../slice";
import Filter from "./filter";

const baseURL = process.env.REACT_APP_PAW_HUGGER_WEB_BASE_URL;

const ProviderListView = ({ serviceID, startDate, endDate }) => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const { serviceID: service } = useParams();

  const { providers, loading } = useSelector((state) => {
    return {
      providers: state.services.providers,
      loading: state.services.loading,
    };
  });

  return (
    <section className="home-services py-5 ">
      <div className="container-fluid py-lg-5 mt-sm-5">
        <div className="col-xl-11 mx-auto">
          <div className="row justify-content-end	">
            <div className="col-lg-8">
              <div className="row align-items-center">
                <div className="col-sm-6">
                  <h3 className="text-gray font-weight-normal">
                    {search
                      ? search.split("service=")[1] &&
                        search.split("service=")[1].replace("%20", " ")
                      : loading
                      ? "Loading..."
                      : "Pet Sitter"}
                  </h3>
                </div>
                <div className="col-sm-6 text-sm-right">
                  <NavLink
                    to={`/services/${serviceID || service}/list`}
                    replace
                  >
                    <i className="i50px ilistview active"></i>
                  </NavLink>
                  <NavLink
                    to={`/services/${serviceID || service}/map`}
                    className="ml-3"
                    replace
                  >
                    <i className="i50px imapview"></i>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
          {providers && providers.data ? (
            <div className="row justify-content-center">
              <Filter
                serviceID={serviceID}
                startDate={startDate}
                endDate={endDate}
              />
              <div className=" col-lg-8 js-range-unit-filter js-year-unit-filter">
                {providers.data.length ? (
                  providers.data.map((el) => (
                    <div
                      className="serviceItem range-unit-filter shadow year-unit-filter"
                      data-range-unit="979"
                      data-year-unit="2"
                      key={el._id}
                    >
                      <div className="row">
                        <div className="col-sm-3 col-xl-2">
                          <div className=" provideravtar provideravtar-lg">
                            <img
                              className="avtar"
                              src={
                                el.user && el.user.profileurl
                                  ? `${baseURL}/${el.user.profileurl}`
                                  : avatar_png
                              }
                              alt="avtar"
                            />
                            <div className="rating-star">
                              <div className="circlerating">
                                <i className="iratingstar active"></i>
                              </div>
                              <div>{(el.user && el.user.rating) || 0}/5</div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-9 col-xl-10">
                          <div className="row justify-content-between align-items-end">
                            <div className="col-auto">
                              <h4 className="name">
                                {el.user &&
                                  el.user.first_name + " " + el.user.last_name}
                                {el.user && el.user.is_verified ? (
                                  <i className="i30px ml-4 iverified"></i>
                                ) : null}
                              </h4>
                            </div>
                            <div className="col-auto">
                              <h4 className="price text-primary">
                                ${el.price} <span> / hr</span>
                              </h4>
                            </div>
                          </div>
                          <p>
                            <i className="i20px iexperience"></i>
                            {el.user && el.user.exp_years} year Exp
                          </p>
                          <p>
                            <i className="i20px ilocationmarker"></i>
                            {el.user && el.user.address}
                          </p>

                          <div className="row mt-3 align-items-end">
                            {el.providerServiceImages && (
                              <div className="col-sm-7">
                                <p>
                                  <span className="text-gray small">
                                    Photos
                                  </span>
                                </p>
                                <div className="gallery mb-3">
                                  {el.providerServiceImages[0] && (
                                    <img
                                      className="galleryimg"
                                      src={
                                        el.providerServiceImages[0].path
                                          ? `${baseURL}/${el.providerServiceImages[0].path}`
                                          : avatar_png
                                      }
                                      alt="avtar"
                                    />
                                  )}
                                  {el.providerServiceImages[1] && (
                                    <img
                                      className="galleryimg"
                                      src={
                                        el.providerServiceImages[1].path
                                          ? `${baseURL}/${el.providerServiceImages[1].path}`
                                          : avatar_png
                                      }
                                      alt="avtar"
                                    />
                                  )}
                                  {el.providerServiceImages[1] && (
                                    <img
                                      className="galleryimg"
                                      src={
                                        el.providerServiceImages[1].path
                                          ? `${baseURL}/${el.providerServiceImages[1].path}`
                                          : avatar_png
                                      }
                                      alt="avtar"
                                    />
                                  )}
                                  {el.providerServiceTotalImages > 3 && (
                                    <div>
                                      +{el.providerServiceTotalImages - 3}
                                    </div>
                                  )}
                                </div>
                                <p>{el.service_words}</p>
                              </div>
                            )}

                            <div className="col-sm-5">
                              <Link
                                to={`/services/${el.providerid}/services?psID=${el.serviceID}`}
                                className="btn btn-primary btn-feet --blue btn-lg btn-block rounded-pill"
                              >
                                Book Now
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <span>Not available</span>
                )}
              </div>
            </div>
          ) : (
            <span>Not available</span>
          )}
          {providers && Number(providers.pages) > Number(providers.cpage) && (
            <span
              onClick={() =>
                dispatch(
                  getAvailableProviders({
                    query: `?cpage=${providers.cpage + 1}&perpage=10`,
                    more: true,
                  })
                )
              }
            >
              get more...
            </span>
          )}
        </div>
      </div>
    </section>
  );
};

export default ProviderListView;
