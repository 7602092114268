import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useDispatch } from "react-redux";

import remove_photo_png from "../../../../images/provider/remove-photo.png";
import add_photos_png from "../../../../images/provider/add-photos.png";

import {
  addExperienceImage,
  addProfileExperience,
  getProfileData,
  removeExperienceImage,
} from "../../slice";

const baseURL = process.env.REACT_APP_PAW_HUGGER_WEB_BASE_URL;

const Experience = ({ data }) => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [error, setError] = useState({});
  const [images, setImage] = useState([]);
  const [serviceWords, setServiceWords] = useState("");
  const [skill, setSkill] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (data) {
      if (data.skills) {
        setSkill(data.skills);
      }
      if (data.service_words) {
        setServiceWords(data.service_words);
      }
      if (data.providerServiceimages && data.providerServiceimages.length) {
        setImage(data.providerServiceimages);
      }
    }
    // eslint-disable-next-line
  }, []);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file.type.split("/")[0] !== "image") {
      enqueueSnackbar("Only image files are accepted", { variant: "error" });
    } else {
      var formdata = new FormData();
      formdata.append("device_type", "3");
      formdata.append("image", file);

      dispatch(addExperienceImage(formdata)).then(
        ({ message, errorMessage, status, data: imageData }) => {
          if (status === 200) {
            setImage([...imageData]);
            enqueueSnackbar(message || "Success", {
              variant: "success",
            });
          } else {
            enqueueSnackbar(errorMessage || "Error", {
              variant: "error",
            });
          }
        }
      );
    }
  };

  const validate = () => {
    let isValid = true;
    let errors = {};

    if (!serviceWords) {
      isValid = false;
      errors.serviceWords = "Required";
    }

    if (!skill) {
      isValid = false;
      errors.skill = "Required";
    }

    setError(errors);
    return isValid;
  };

  const handleSubmit = () => {
    if (validate()) {
      setLoading(true);
      const payload = {
        service_words: serviceWords,
        skill,
      };

      dispatch(addProfileExperience(payload)).then(
        ({ message, status, errorMessage }) => {
          setLoading(false);
          if (status === 200) {
            dispatch(getProfileData());
            enqueueSnackbar(message || "Success", {
              variant: "success",
            });
            push("/profile-setup/availability");
          } else {
            enqueueSnackbar(errorMessage || "Error", {
              variant: "error",
            });
          }
        }
      );
    }
  };

  const handleRemovePhoto = (photo_id) => {
    dispatch(removeExperienceImage({ photo_id })).then(
      ({ message, status, errorMessage }) => {
        if (status === 200) {
          enqueueSnackbar(message || "Success", {
            variant: "success",
          });
          const newImages = images.filter((el) => el._id !== photo_id);
          setImage(newImages);
        } else {
          enqueueSnackbar(errorMessage || "Error", {
            variant: "error",
          });
        }
      }
    );
  };

  return (
    <div className="container mt-xl-5 pt-xl-3">
      <p className="h4 my-5">Add photos of your services</p>

      <ul className="add-photos">
        {images && images.length
          ? images.map((el) => (
              <li key={el._id}>
                <div>
                  <button
                    type="button"
                    onClick={() => handleRemovePhoto(el._id)}
                  >
                    <img src={remove_photo_png} alt="removeicon" />
                  </button>
                  <img
                    src={el.path ? `${baseURL}/${el.path}` : add_photos_png}
                    className="img-fluid serviceImg"
                    alt="img"
                  />
                </div>
              </li>
            ))
          : null}
        <li>
          <div style={{ position: "relative" }}>
            <img src={add_photos_png} className="img-fluid" alt="img" />
            <input
              type="file"
              id="imageUpload"
              accept=".png, .jpg, .jpeg"
              onChange={handleImageUpload}
              style={{
                position: "absolute",
                top: 0,
                right: 0,
                left: 0,
                bottom: 0,
                opacity: 0,
              }}
            />
          </div>
        </li>
      </ul>

      <p className="h4 text-center mt-5 mb-4">
        Add words about your services{" "}
        <span className="error">{error.serviceWords}</span>
      </p>
      <div className="row">
        <div className="form-group col-12">
          <div className="custom-group-input rounded-digonal">
            <textarea
              name="serviceWords"
              id=""
              minLength={8}
              maxLength={800}
              className="form-control h-auto"
              cols="30"
              rows="10"
              value={serviceWords || ""}
              onChange={(e) => {
                let value = e.target.value;
                setServiceWords(value);
                setError({ ...error, serviceWords: "" });
              }}
            ></textarea>
          </div>
        </div>
      </div>

      <p className="h4 text-center mt-5 mb-4">
        Add your skills <span className="error">{error.skill}</span>
      </p>
      <div className="row">
        <div className="form-group col-12">
          <div className="custom-group-input rounded-digonal">
            <textarea
              name="skill"
              id=""
              minLength={8}
              maxLength={800}
              className="form-control h-auto"
              cols="30"
              rows="10"
              value={skill || ""}
              onChange={(e) => {
                let value = e.target.value;
                setSkill(value);
                setError({ ...error, skill: "" });
              }}
            ></textarea>
          </div>
        </div>
      </div>

      <div className="row justify-content-center">
        <div className="col-md-6 mb-5 col-xl-4">
          <div className="form-group mt-4">
            <button
              type="button"
              className="btn btn-primary btn-block btn-feet --blue btn-lg btn-block rounded-pill"
              onClick={handleSubmit}
            >
              {loading ? "Saving..." : data ? "Save" : "Next"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Experience;
