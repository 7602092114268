import React, { useEffect, useState } from "react";
import { Route, Switch, useLocation, useRouteMatch } from "react-router-dom";
import ProviderListView from "./listView";
import ProviderMapView from "./mapView";
import Profile from "./profile";
import Search from "./search";
import SelectDog from "./selectDog";
import SelectServiceTime from "./selectServiceTime";
import Success from "./success";

const ServiceProvider = () => {
  let { path } = useRouteMatch();
  let { pathname } = useLocation();

  const [serviceID, setServiceID] = useState("");
  const [startDate, setStartDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [endDate, setEndDate] = useState(
    new Date().toISOString().split("T")[0]
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <main className="page-wrapper">
      {pathname.includes("/map") || pathname.includes("/list") ? (
        <Search
          serviceID={serviceID}
          setServiceID={setServiceID}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
        />
      ) : null}

      <Switch>
        <Route exact path={`${path}/:serviceID/list`}>
          <ProviderListView
            serviceID={serviceID}
            startDate={startDate}
            endDate={endDate}
          />
        </Route>
        <Route exact path={`${path}/:serviceID/map`}>
          <ProviderMapView />
        </Route>
        <Route exact path={`${path}/:providerID/select-time`}>
          <SelectServiceTime />
        </Route>
        <Route exact path={`${path}/:providerID/select-pet`}>
          <SelectDog />
        </Route>
        <Route exact path={`${path}/:providerID/success`}>
          <Success />
        </Route>
        <Route path={`${path}/:providerID`}>
          <Profile />
        </Route>
      </Switch>
    </main>
  );
};

export default ServiceProvider;
