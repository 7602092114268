import { useSnackbar } from "notistack";
import React, { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { requestHelp } from "../slice";

const Help = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const loading = useSelector((state) => state.accounts.loading);

  const [type, setType] = useState("Yes");
  const [description, setDescription] = useState("");
  const [error, setError] = useState({});

  const validate = () => {
    let errors = {};
    let isValid = true;

    if (!description) {
      isValid = false;
      errors.description = "Required";
    }

    setError(errors);
    return isValid;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (validate()) {
      dispatch(
        requestHelp({
          type,
          description,
        })
      ).then(({ errorMessage, status, message }) => {
        if (status === 200) {
          setType("Yes");
          setDescription("");
          enqueueSnackbar(message || "Success", {
            variant: "success",
          });
        } else {
          enqueueSnackbar(errorMessage || "Error", {
            variant: "error",
          });
        }
      });
    }
  };

  return (
    <Fragment>
      <div className="row align-items-center">
        <div className="col-3">
          {/* <span className="ti-angle-left h3 text-dark"></span> */}
        </div>
        <div className="col-sm-6 text-center">
          <p className="h3 text-gray">Help?</p>
        </div>
      </div>

      <div className="row justify-content-center my-5">
        <div className="col-lg-10 col-xl-8">
          <div className="round-box px-sm-5">
            <div className="form-group text-center">
              <p className="h6">Did you find you were looking for</p>
            </div>
            <div className="row justify-content-center mt-5">
              <div className="form-group col-md-8">
                <div className="row radio-tabs rowMarginHalf colpad-half">
                  <div className="col">
                    <input
                      type="radio"
                      name="sfafsd"
                      id="yes"
                      checked={type === "Yes"}
                      onChange={() => setType("Yes")}
                    />
                    <label
                      htmlFor="yes"
                      className="label-button rounded-pill d-block"
                    >
                      Yes
                    </label>
                  </div>
                  <div className="col">
                    <input
                      type="radio"
                      name="sfafsd"
                      id="partially"
                      checked={type === "Partially"}
                      onChange={() => setType("Partially")}
                    />
                    <label
                      htmlFor="partially"
                      className="label-button rounded-pill d-block"
                    >
                      Partially
                    </label>
                  </div>
                  <div className="col">
                    <input
                      type="radio"
                      name="sfafsd"
                      id="no"
                      checked={type === "No"}
                      onChange={() => setType("No")}
                    />
                    <label
                      htmlFor="no"
                      className="label-button rounded-pill d-block"
                    >
                      No
                    </label>
                  </div>
                </div>
              </div>
              <div className="form-group mt-4 col-lg-6 text-center">
                <p className="h6">
                  Do you have any suggestion to make our app better?
                </p>
              </div>
              <div className="form-group col-md-8">
                <span className="error">{error.description}</span>
                <div className="custom-group-input rounded-digonal">
                  <textarea
                    className="form-control h-auto"
                    cols="30"
                    rows="7"
                    minLength="10"
                    maxLength="1000"
                    value={description || ""}
                    onChange={(e) => {
                      let value = e.target.value;
                      setDescription(value);
                    }}
                  ></textarea>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-md-6">
                <div className="form-group mt-4">
                  <button
                    type="button"
                    className="btn btn-primary btn-block btn-feet --blue btn-lg btn-block rounded-pill"
                    onClick={handleSubmit}
                  >
                    {loading ? "Submitting..." : "Submit"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Help;
