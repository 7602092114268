import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useSnackbar } from "notistack";

import book_dog_png from "../../../../../images/book-dog.png";

import { removeBooking } from "../../../slice";

const baseURL = process.env.REACT_APP_PAW_HUGGER_WEB_BASE_URL;

const CancelledBooking = ({ list, loading }) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [fetching, setLoading] = useState(false);

  const handleRemove = (bookingID) => {
    if (bookingID) {
      setLoading(true);
      dispatch(removeBooking({ booking_id: bookingID }, 4)).then(
        ({ errorMessage, status, message }) => {
          setLoading(false);
          if (status === 200) {
            enqueueSnackbar(message || "Success", {
              variant: "success",
            });
          } else {
            enqueueSnackbar(errorMessage || "Error", {
              variant: "error",
            });
          }
        }
      );
    }
  };

  return (
    <div className="tab-content details-tab-content p-sm-3" id="myTabContent">
      <div className="tab-pane fade show active" id="cancelled" role="tabpanel">
        {list && list.data && list.data.length ? (
          list.data.map((el) => (
            <div className="booking-item" key={el._id}>
              <div className="row justify-content-center">
                <div className="col-2 px-0 col-lg-auto">
                  <img
                    src={
                      el.service && el.service.service_image
                        ? `${baseURL}/${el.service.service_image}`
                        : book_dog_png
                    }
                    className="img-fluid mb-3"
                    alt="img"
                  />
                </div>
                <div className="col-10 col-lg-7 ">
                  <div className="row">
                    <div className="col-8">
                      <p className="light-gray mb-1">ID: {el.booking_id}</p>
                      <h5>
                        {el.pet_list && el.service && el.service.service_name}
                      </h5>
                      <p className="p-sm font-weight-bold">
                        {new Date(el.bookingCreatedAt) === new Date()
                          ? "Today"
                          : new Date(el.bookingCreatedAt).toDateString()}
                        , {new Date(el.bookingCreatedAt).toLocaleTimeString()}
                      </p>
                      <p className="price h4 my-3 text-primary">
                        ${el.isPaid} <span> / hr</span>
                      </p>
                    </div>
                    <div className="col-auto col-sm-4 text-center d-flex justify-content-center">
                      <div className="align-self-start">
                        <i className="i40px ibookcancelled"></i>
                        <p className="text-danger">Cancelled</p>
                      </div>
                    </div>
                  </div>
                  <div className="row features">
                    <div className="col-sm-6">
                      <p>
                        <i className="i30px ibookcalender"></i>
                        {el.start_date}
                      </p>
                    </div>
                    <div className="col-sm-6">
                      <p>
                        <i className="i30px ibookdog-type"></i>
                        {el.pet_list &&
                          el.pet_list[0] &&
                          el.pet_list[0].pet_name}{" "}
                        |<i className="i30px ibookage-cake"></i>{" "}
                        {el.pet_list &&
                          el.pet_list[0] &&
                          el.pet_list[0].pet_age}
                      </p>
                    </div>
                    <div className="col-sm-6">
                      <p>
                        <i className="i30px ibookclock-time"></i>{" "}
                        {el.booking_time} to {el.booking_end_time}
                      </p>
                    </div>
                    <div className="col-sm-6">
                      <p>
                        <i className="i30px imapmarker"></i> {el.cust_location}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="row mt-3">
                    <div className="col-sm-6 my-3 my-lg-0 col-lg-12 col-xl-11">
                      <button
                        type="button"
                        className="btn my-lg-4 btn-light-primary btn-feet --light-blue btn-lg btn-block rounded-pill"
                        onClick={() => handleRemove(el.booking_id)}
                      >
                        {fetching ? "Removing..." : "Remove"}
                      </button>
                    </div>
                    <div className="col-sm-6 my-3 my-lg-0 col-lg-12 col-xl-11">
                      <Link
                        to={`/dashboard/my-bookings/cancelled/details/${el._id}`}
                        className="btn mt-lg-5 btn-primary btn-feet --blue btn-lg btn-block rounded-pill"
                      >
                        View Details
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <span>{loading ? "Loading..." : "No records found."}</span>
        )}
      </div>
    </div>
  );
};

export default CancelledBooking;
