/* eslint-disable linebreak-style */
/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */

import { createSlice } from "@reduxjs/toolkit";
import { axiosGet, postEncoded, postForm } from "../../../api";

const initialState = {
  loading: false,
  error: null,
};

const dashboard = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    loadingStart(state) {
      state.loading = true;
      state.error = null;
    },
    loadingStop(state) {
      state.loading = false;
    },
    setPetDetails(state, action) {
      state.petDetails = action.payload;
      state.loading = false;
      state.error = null;
    },
    setMyPetList(state, action) {
      const { data, more } = action.payload;
      if (more && state.myPets) {
        state.myPets = {
          ...data,
          data: [...state.myPets.data, ...data.data],
        };
      } else {
        state.myPets = data;
      }
      state.loading = false;
      state.error = null;
    },
    setPendingBookings(state, action) {
      const { data, more } = action.payload;
      if (more && state.pending) {
        state.pending = {
          ...data,
          data: [...state.pending.data, ...data.data],
        };
      } else {
        state.pending = data;
      }
      state.loading = false;
      state.error = null;
    },
    setCompletedBookings(state, action) {
      const { data, more } = action.payload;
      if (more && state.completed) {
        state.completed = {
          ...data,
          data: [...state.completed.data, ...data.data],
        };
      } else {
        state.completed = data;
      }
      state.loading = false;
      state.error = null;
    },
    setOngoingBookings(state, action) {
      const { data, more } = action.payload;
      if (more && state.ongoing) {
        state.ongoing = {
          ...data,
          data: [...state.ongoing.data, ...data.data],
        };
      } else {
        state.ongoing = data;
      }
      state.loading = false;
      state.error = null;
    },
    setCancelledBookings(state, action) {
      const { data, more } = action.payload;
      if (more && state.cancelled) {
        state.cancelled = {
          ...data,
          data: [...state.cancelled.data, ...data.data],
        };
      } else {
        state.cancelled = data;
      }
      state.loading = false;
      state.error = null;
    },
    setUpcomingBookings(state, action) {
      const { data, more } = action.payload;
      if (more && state.upcoming) {
        state.upcoming = {
          ...data,
          data: [...state.upcoming.data, ...data.data],
        };
      } else {
        state.upcoming = data;
      }
      state.loading = false;
      state.error = null;
    },
    setTrackPetList(state, action) {
      state.tarckPetList = action.payload;
      state.loading = false;
      state.error = null;
    },
    setBookingDetails(state, action) {
      state.bookingDetails = action.payload;
      state.loading = false;
      state.error = null;
    },
    setRating(state, action) {
      state.bookingDetails = {
        ...state.bookingDetails,
        ratingReviews: [action.payload],
      };
      state.loading = false;
      state.error = null;
    },
    setTrackingDetails(state, action) {
      state.trackingDetails = action.payload;
      state.loading = false;
      state.error = null;
    },

    // Bank Accounts

    setBankAccounts(state, action) {
      state.bankAccounts = action.payload;
      state.loading = false;
      state.error = null;
    },

    setPaymentRequests(state, action) {
      state.requests = action.payload;
      state.loading = false;
      state.error = null;
    },
  },
});

export const {
  loadingStart,
  loadingStop,
  setPetDetails,
  setMyPetList,
  setTrackPetList,
  setUpcomingBookings,
  setOngoingBookings,
  setCancelledBookings,
  setCompletedBookings,
  setPendingBookings,
  setBookingDetails,
  setTrackingDetails,
  setRating,
  setBankAccounts,
  setPaymentRequests,
} = dashboard.actions;

export default dashboard.reducer;

export const addEditPet = ({ payload, uri }) => async (dispatch) => {
  dispatch(loadingStart());
  const {
    data: { message, errorMessage },
    status,
  } = await postForm({
    uri,
    payload,
    authName: "phgr",
  });

  if (status === 200) {
    dispatch(setPetDetails(null));
    dispatch(getMyPets({ page: 1 }));
  }

  dispatch(loadingStop());

  return { message, status, errorMessage };
};

export const getMyPets = ({ page, more }) => async (dispatch) => {
  dispatch(loadingStart());
  const { data, status } = await axiosGet({
    uri: `/pet_list?cpage=${page}&perpage=10`,
    authName: "phgr",
  });

  if (status === 200) {
    dispatch(setMyPetList({ data, more }));
  }

  dispatch(loadingStop());

  return { status };
};

export const deletePet = (payload) => async (dispatch) => {
  dispatch(loadingStart());
  const {
    data: { message, errorMessage },
    status,
  } = await postEncoded({
    uri: "/pet_remove",
    payload,
    authName: "phgr",
  });

  if (status === 200) {
    dispatch(getMyPets({ page: 1 }));
  }

  dispatch(loadingStop());

  return { message, status, errorMessage };
};

export const getMyBookings = ({ page, type, more }) => async (dispatch) => {
  // newRequest  :  "1",   Ongoing  :  "2",   upcoming   : 3,   Complete :  "4",   Cancel  : "5"

  dispatch(loadingStart());
  const { data, status } = await axiosGet({
    uri: `/booking_list?cpage=${page}&perpage=10&booking_type=${type}`,
    authName: "phgr",
  });

  if (status === 200) {
    switch (type) {
      case 1:
        dispatch(setPendingBookings({ data, more }));
        break;
      case 2:
        dispatch(setOngoingBookings({ data, more }));
        break;
      case 5:
        dispatch(setUpcomingBookings({ data, more }));
        break;
      case 3:
        dispatch(setCompletedBookings({ data, more }));
        break;
      case 4:
        dispatch(setCancelledBookings({ data, more }));
        break;
      default:
        dispatch(setPendingBookings({ data, more }));
        break;
    }
  }

  dispatch(loadingStop());

  return { status };
};

export const getBookingDetails = ({ bookingID }) => async (dispatch) => {
  // newRequest  :  "1",   Ongoing  :  "2",   upcoming   : 3,   Complete :  "4",   Cancel  : "5"

  dispatch(loadingStart());
  const {
    data: { data },
    status,
  } = await axiosGet({
    uri: `/booking_details?booking_id=${bookingID}`,
    authName: "phgr",
  });

  if (status === 200) {
    dispatch(setBookingDetails(data && data[0]));
  }

  dispatch(loadingStop());

  return { status };
};

export const cancelBooking = (payload) => async (dispatch) => {
  dispatch(loadingStart());
  const {
    data: { message, errorMessage },
    status,
  } = await postEncoded({
    uri: "/cancel_booking",
    payload,
    authName: "phgr",
  });

  if (status === 200) {
    dispatch(getMyBookings({ page: 1, type: 1, more: false }));
  }

  dispatch(loadingStop());

  return { message, status, errorMessage };
};

export const rateBooking = (payload) => async (dispatch) => {
  dispatch(loadingStart());
  const {
    data: { message, errorMessage },
    status,
  } = await postEncoded({
    uri: "/provide_rating",
    payload,
    authName: "phgr",
  });

  dispatch(loadingStop());

  if (status === 200) {
    dispatch(setRating(payload));
  }

  return { message, status, errorMessage };
};

export const completePayment = (payload) => async (dispatch) => {
  dispatch(loadingStart());
  const {
    data: { message, errorMessage },
    status,
  } = await postEncoded({
    uri: "/complete_payment",
    payload,
    authName: "phgr",
  });

  dispatch(loadingStop());

  return { message, status, errorMessage };
};

export const getTrackingDetails = ({ bookingID }) => async (dispatch) => {
  dispatch(loadingStart());
  const {
    data: { data },
    status,
  } = await axiosGet({
    uri: `/track_pet?booking_id=${bookingID}`,
    authName: "phgr",
  });

  if (status === 200) {
    dispatch(setTrackingDetails(data));
  }

  dispatch(loadingStop());

  return { status };
};

// Bank Accounts

export const addNewCard = ({ payload, save }) => async (dispatch) => {
  dispatch(loadingStart());
  const {
    data: { code, data, message, errorMessage },
    status,
  } = await postEncoded({
    uri: "/add_new_card",
    payload,
    authName: "phgr",
  });

  if (status === 200 && code === 200) {
    if (save) {
      dispatch(saveNewCard({ payload: { card_id: data } }));
    }
  }

  dispatch(loadingStop());

  return { message, status, errorMessage };
};

export const saveNewCard = ({ payload }) => async (dispatch) => {
  dispatch(loadingStart());
  const {
    data: { message, errorMessage },
    status,
  } = await postEncoded({
    uri: "/create_save_customer_card",
    payload,
    authName: "phgr",
  });

  if (status === 200) {
    dispatch(getBankCards());
  }

  dispatch(loadingStop());

  return { message, status, errorMessage };
};

export const getBankCards = () => async (dispatch) => {
  dispatch(loadingStart());
  const { data, status } = await axiosGet({
    uri: `/card_list`,
    authName: "phgr",
  });

  if (status === 200) {
    dispatch(setBankAccounts(data));
  }

  dispatch(loadingStop());

  return null;
};

export const removeCard = (payload) => async (dispatch) => {
  dispatch(loadingStart());
  const {
    data: { message, errorMessage },
    status,
  } = await postEncoded({
    uri: `/remove_card`,
    payload,
    authName: "phgr",
  });

  if (status === 200) {
    dispatch(getBankCards());
  }

  dispatch(loadingStop());

  return { message, errorMessage, status };
};

export const makePayment = (payload) => async (dispatch) => {
  dispatch(loadingStart());
  const {
    data: { message, errorMessage },
    status,
  } = await postEncoded({
    uri: `/pay`,
    payload,
    authName: "phgr",
  });

  if (status === 200) {
    dispatch(getBankCards());
  }

  dispatch(loadingStop());

  return { message, errorMessage, status };
};

export const getPaymentRequests = () => async (dispatch) => {
  dispatch(loadingStart());
  const {
    data: { data, errorMessage, message },
    status,
  } = await axiosGet({
    uri: `/check_provider_payment_requrest`,
    authName: "phgr",
  });

  if (status === 200) {
    dispatch(setPaymentRequests(data));
  }

  dispatch(loadingStop());

  return { status, message, errorMessage };
};
