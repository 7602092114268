export function reverseDate(s) {
  return s.split("-").reverse().join("-");
}

export function getLastActive(date) {
  var startDate = new Date(date);
  // Do your operations
  var endDate = new Date();

  // get total seconds between the times
  var delta = Math.abs(endDate - startDate) / 1000;

  // calculate (and subtract) whole days
  var days = Math.floor(delta / 86400);
  delta -= days * 86400;

  // calculate (and subtract) whole hours
  var hours = Math.floor(delta / 3600) % 24;
  delta -= hours * 3600;

  // calculate (and subtract) whole minutes
  var minutes = Math.floor(delta / 60) % 60;
  delta -= minutes * 60;

  // what's left is seconds
  var seconds = delta % 60; // in theory the modulus is not required

  if (days > 0) {
    return days + (days === 1 ? " day" : " days");
  } else if (hours > 1) {
    return hours + (hours === 1 ? " hr" : " hrs");
  } else if (minutes > 1) {
    return minutes + (minutes === 1 ? " min" : " mins");
  }

  return seconds + " seconds";
}
