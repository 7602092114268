import React, { forwardRef } from "react";

const About = forwardRef((props, ref) => {
  return (
    <section className="home-aboutus d-flex justify-content-center" ref={ref}>
      <div className="container align-self-end">
        <div className="row justify-content-center align-items-center">
          <div className="col-md-10 pt-md-5">
            <div className="my-5 text-center">
              <h3 className="mb-4">About us</h3>
              <p>
                Paw Hugger is 100% free to join whether you are an owner or
                service provider. You only pay for the service that you choose.
                The app enable you to find local pet service provider who will
                treat your pet as part of their family.
              </p>
              <p>
                All service provider are verified and rated to reflect their
                commitment and quality of service.
              </p>
              <p>
                Cashless payments – Book and pay online so as to benefit from
                flexible cancellations. It gives both parties peace of mind and
                security.
              </p>
              <a
                href="http://3.131.5.153:5002/about-us"
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-xl btn-primary rounded-pill mt-3"
              >
                Know More
                <i className="ti-angle-right ml-4" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
});

export default About;
