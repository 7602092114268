import React, { useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";

import { addStripeAccount } from "../slice";

const Stripe = () => {
  const {
    replace,
    location: { search },
  } = useHistory();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const saveAccount = useCallback(
    (payload) => {
      dispatch(addStripeAccount(payload)).then(
        ({ message, status, errorMessage }) => {
          if (status === 200) {
            enqueueSnackbar(message || "Success", {
              variant: "success",
            });
            window.close();
          } else {
            enqueueSnackbar(errorMessage || "Error", {
              variant: "error",
            });
            replace("/");
          }
        }
      );
    },
    [dispatch, enqueueSnackbar, replace]
  );

  useEffect(() => {
    if (search && search.includes("code=")) {
      const stripeID = search.split("code=")[1];
      if (stripeID) {
        saveAccount({ stripe_account_id: stripeID });
      }
    } else {
      replace("/");
    }
  }, [search, saveAccount, replace]);

  return (
    <div
      style={{
        margin: 0,
        textAlign: "center",
        height: "100vh",
        paddingTop: "25vh",
      }}
    >
      <div
        style={{
          display: "inline-block",
          textAlign: "left",
          lineHeight: "49px",
          height: "49px",
          verticalAlign: "middle",
        }}
      >
        <h2
          style={{
            fontSize: "14px",
            fontWeight: "normal",
            lineHeight: "inherit",
            margin: 0,
            padding: 0,
          }}
        >
          Registering your account...
        </h2>
      </div>
    </div>
  );
};

export default Stripe;
