import React from "react";

import avatar_png from "../../../../../images/avatar.png";

const baseURL = process.env.REACT_APP_PAW_HUGGER_WEB_BASE_URL;

const Reviews = ({ rating, reviewCount, reviews }) => {
  return (
    <div
      className="tab-pane fade show active"
      id="reviewRateTab"
      role="tabpanel"
    >
      <div className="ratingsdetails my-3">
        <i
          className={
            rating > 0 ? "i40px iratingstar active" : "i40px iratingstar"
          }
        ></i>
        <i
          className={
            rating > 1 ? "i40px iratingstar active" : "i40px iratingstar"
          }
        ></i>
        <i
          className={
            rating > 2 ? "i40px iratingstar active" : "i40px iratingstar"
          }
        ></i>
        <i
          className={
            rating > 3 ? "i40px iratingstar active" : "i40px iratingstar"
          }
        ></i>
        <i
          className={
            rating > 4 ? "i40px iratingstar active" : "i40px iratingstar"
          }
        ></i>
        <span className="text-primary ml-4">{rating}/5</span>
      </div>
      <p className="my-4 h5 text-gray">{reviewCount} Reviews</p>

      {reviews && reviews.length
        ? reviews.map((el) => (
            <div className="media d-block d-sm-flex review-box" key={el._id}>
              <img
                src={
                  el.userRating && el.userRating.profileurl
                    ? `${baseURL}/${el.userRating.profileurl}`
                    : avatar_png
                }
                className=" rounded-pill avtar"
                alt="avtar"
              />
              <div className="media-body">
                <div className="row ">
                  <div className="col-auto col-sm-6">
                    <h5 className="">
                      {el.userRating &&
                        el.userRating.first_name +
                          " " +
                          el.userRating.last_name}
                    </h5>
                    <div className="rating text-primary">
                      {(() => {
                        let stars = [];
                        for (
                          let index = 0;
                          index < Number(el.rating);
                          index++
                        ) {
                          stars.push(
                            <i className="fas fa-star" key={index}></i>
                          );
                        }
                        return stars;
                      })()}
                    </div>
                  </div>
                  <div className="col-6 text-sm-right pr-lg-5">
                    {new Date(el.createdAt).toDateString()}
                  </div>
                </div>
                <p className="mb-0">{el.feedback}</p>
              </div>
            </div>
          ))
        : null}
    </div>
  );
};

export default Reviews;
