import React, { memo, useEffect, useState } from "react";
import { useHistory, Prompt, useParams } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";

import login_bg_without_shadow_png from "../../../images/home/login_bg_without_shadow.png";
import logo_footer_png from "../../../images/home/logo_footer.png";

import { useToggle } from "../../../app/hooks";
import OTPInput from "../../../components/otp/OTPInput";
import { resendOTP, verifyOTP, verifySignUpOTP } from "../slice";

const OTP = memo(() => {
  const {
    goBack,
    replace,
    location: { search },
  } = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { user, type } = useParams();
  const dispatch = useDispatch();

  const loading = useSelector((state) => state.accounts.loading);

  const [open, toggle] = useToggle(true);

  const [isBlocking, setIsBlocking] = useState(true);
  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");
  const [timeLeft, setTimeLeft] = useState(60);

  useEffect(() => {
    if (!timeLeft) return;

    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft]);

  useEffect(() => {
    if (isBlocking) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }
  }, [isBlocking]);

  const handleResend = async (event) => {
    event.preventDefault();

    if (timeLeft === 0) {
      dispatch(resendOTP()).then(({ message, status, errorMessage }) => {
        if (status === 200) {
          enqueueSnackbar(message || "Success", {
            variant: "success",
          });

          setTimeLeft(60);
        } else {
          enqueueSnackbar(errorMessage || "Error", {
            variant: "error",
          });
        }
      });
    } else {
      console.error("Error: ");
    }
  };

  const handleHide = () => {
    goBack();
    goBack();
    if (!isBlocking) {
      toggle();
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (otp.length === 6) {
      if (type === "signup") {
        dispatch(verifySignUpOTP({ otp })).then(
          ({ errorMessage, status, message }) => {
            if (status === 200) {
              setIsBlocking(false);
              enqueueSnackbar(message || "Success", {
                variant: "success",
              });

              if (user === "customer") {
                replace("/");
              }
              if (user === "provider") {
                replace("/profile-setup/services");
              }
            } else {
              enqueueSnackbar(errorMessage || "Error", {
                variant: "error",
              });
            }
          }
        );
      } else if (type === "forgot") {
        dispatch(verifyOTP({ otp })).then(
          ({ errorMessage, status, message }) => {
            if (status === 200) {
              setIsBlocking(false);
              enqueueSnackbar(message || "Success", {
                variant: "success",
              });

              replace(`/${user}/reset-password`);
            } else {
              enqueueSnackbar(errorMessage || "Error", {
                variant: "error",
              });
            }
          }
        );
      }
    } else {
      setError("Please enter valid OTP.");
    }
  };

  return (
    <Modal
      show={open}
      onHide={handleHide}
      size="xl"
      id="otpModal"
      className="modal form-popup "
      aria-labelledby="exampleModalLabel"
    >
      <Prompt
        when={isBlocking}
        message={(location) =>
          `Are you sure you want to go to ${location.pathname}`
        }
      />
      <div className="modal-body ">
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={handleHide}
        ></button>
        <div className="row no-gutters justify-content-end">
          <div className="col-md-6 form-img d-lg-flex justify-content-center">
            <div className="img-holder align-self-center">
              <img
                className="img-fluid"
                src={login_bg_without_shadow_png}
                alt=" img"
              />
            </div>
            <img src={logo_footer_png} alt="img" className="logomodal" />
          </div>
          <div className="col-lg-6 form-box d-flex justify-content-center">
            <span
              className="pointer back-btn fas fa-2x fa-angle-left"
              onClick={handleHide}
            ></span>
            <form
              className="w-100 px-5 align-self-center"
              onSubmit={handleSubmit}
            >
              <div className="text-center col-9  mx-auto pb-5">
                <h4 className="mb-3">OTP</h4>
                <p className="h6">
                  Please enter the six digit OTP sent on{" "}
                  {search ? search.split("p=")[1] : "your mobile."}
                </p>
              </div>

              <div className="form-group">
                <OTPInput
                  length={6}
                  isNumberInput
                  autoFocus
                  className="col-12 mb-5 partitioned"
                  onChangeOTP={(value) => {
                    setOtp(value);
                    setError("");
                  }}
                />
                <div className="invalid-tooltip otp-validate">{error}</div>
              </div>

              <div className=" col-10 mx-auto mt-5">
                <button className="btn btn-primary btn-block btn-feet --blue btn-lg btn-block rounded-pill">
                  {loading ? "Verifying..." : "Submit"}
                </button>
              </div>
              <p className="text-center mt-5 ">
                <span className="">
                  Didn't get code?{" "}
                  <span
                    className="text-primary font-weight-bold"
                    onClick={handleResend}
                    style={{ cursor: timeLeft === 0 ? "pointer" : "default" }}
                  >
                    Resend
                  </span>
                </span>
                <span className="float-right mr-4">{timeLeft}</span>
              </p>
            </form>
          </div>
        </div>
      </div>
    </Modal>
  );
});

export default OTP;
