import React from "react";
import ContactUs from "../components/contact";
import Forgot from "../features/accounts/forgot";

import Login from "../features/accounts/login";
import OTP from "../features/accounts/otp";
import Reset from "../features/accounts/reset";
import Signup from "../features/accounts/signup";
import SignupForm from "../features/accounts/signup/Form";
import Home from "../features/home";

const routes = [
  {
    path: "/",
    exact: true,
    main: () => <Home />,
  },
  {
    path: "/paw-hugger",
    exact: true,
    main: () => <Signup />,
  },
  {
    path: "/:user/login",
    exact: true,
    main: () => <Login />,
  },
  {
    path: "/:user/signup",
    exact: true,
    main: () => <SignupForm />,
  },
  {
    path: "/:user/forgot-password",
    exact: true,
    main: () => <Forgot />,
  },
  {
    path: "/:user/verify/:type",
    exact: true,
    main: () => <OTP />,
  },
  {
    path: "/:user/reset-password",
    exact: true,
    main: () => <Reset />,
  },
  {
    path: "/contact-us",
    exact: true,
    main: () => <ContactUs />,
  },
];

export default routes;
