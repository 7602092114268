import React, { forwardRef } from "react";

import production_video_mp4 from "../../images/production_video.mp4";

const Why = forwardRef((props, ref) => {
  return (
    <section className="home-whychooseus" ref={ref}>
      <div className="container-fluid ">
        <div className="px-lg-5">
          <div className="row mx-0 align-items-center">
            <div className="col-md-5 ">
              <div className="">
                <video width="500" controls className="img-fluid">
                  <source src={production_video_mp4} type="video/mp4" />
                  Your browser does not support HTML video.
                </video>
              </div>
            </div>
            <div className="col-md-7 pt-md-5">
              <div className="my-5">
                <h3 className="mb-4">Why Choose Us</h3>
                <p>
                  Paw Hugger connects pet owners with affordable local pet
                  carers and professional pet service provider. Pet sitters and
                  pet boarder a great alternative to local dog kennels or pet
                  hotels. Each pet sitter caters exactly to your pet’s needs so
                  you can be sure that your pet is getting all the love and
                  attention they need while you are away. When you are too busy
                  to take your dog our for their daily exercise our pet walker
                  will enable your dog to enjoy their exercise and also provides
                  mental stimulation, physical exercise and chances for
                  socialization.
                </p>
                <p>
                  Paw Hugger is 100% free to join whether you are an owner or
                  service provider. You only pay for the service that you
                  choose. The apps enable you to find local pet service provider
                  who will treat your pet as part of their family.
                </p>
                <p>
                  All service providers are verified and rated to reflect their
                  commitment and quality of service.
                </p>
                <p>
                  Cashless payments – Book and pay online to benefit from
                  flexible cancellations. It gives both parties peace of mind
                  and security.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
});

export default Why;
