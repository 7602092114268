import React from "react";
import { useSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import Skeleton from "@material-ui/lab/Skeleton";

import book_dog_png from "../../../../../images/book-dog.png";

import { cancelBooking, completePayment } from "../../slice";

const baseURL = process.env.REACT_APP_PAW_HUGGER_WEB_BASE_URL;

const Booking = ({ data, loading }) => {
  const { status } = useParams();
  const dispatch = useDispatch();
  const { replace } = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  // const [isPaid, setIsPaid] = useState(false);

  const handleCancel = (bookingID) => {
    if (bookingID) {
      dispatch(cancelBooking({ booking_id: bookingID })).then(
        ({ errorMessage, status: responseStatus, message }) => {
          if (responseStatus === 200) {
            enqueueSnackbar(message || "Success", {
              variant: "success",
            });
            replace(`/dashboard/my-bookings/${status}`);
          } else {
            enqueueSnackbar(errorMessage || "Error", {
              variant: "error",
            });
          }
        }
      );
    }
  };

  const handlePayment = (bookingID) => {
    if (bookingID) {
      dispatch(completePayment({ booking_id: bookingID })).then(
        ({ errorMessage, status, message }) => {
          if (status === 200) {
            // setIsPaid(true);
            enqueueSnackbar(message || "Success", {
              variant: "success",
            });
          } else {
            enqueueSnackbar(errorMessage || "Error", {
              variant: "error",
            });
          }
        }
      );
    }
  };

  return (
    <div className="booking-item mt-0 shadow-none">
      <div className="row justify-content-center">
        <div className="col-sm-2 px-0 col-lg-auto">
          {loading ? (
            <Skeleton variant="rect" width={200} height={200} />
          ) : (
            <img
              src={
                data &&
                data.pet_list &&
                data.pet_list[0] &&
                data.pet_list[0].pet_image
                  ? `${baseURL}/${data.pet_list[0].pet_image}`
                  : book_dog_png
              }
              className="img-fluid mb-3"
              alt="img"
            />
          )}
        </div>
        <div className="col-sm-10 col-lg-7 ">
          {loading ? (
            <Skeleton variant="rect" width={200} style={{ marginBottom: 6 }} />
          ) : (
            <h5>
              {data &&
                data.pet_list &&
                data.service &&
                data.service.service_name}
            </h5>
          )}
          {loading ? (
            <Skeleton variant="rect" width={200} style={{ marginBottom: 6 }} />
          ) : (
            <p className="p-sm font-weight-bold">
              {data && new Date(data.bookingCreatedAt) === new Date()
                ? "Today"
                : new Date(data.bookingCreatedAt).toDateString()}
              , {new Date(data.bookingCreatedAt).toLocaleTimeString()}
            </p>
          )}
          {loading ? (
            <Skeleton variant="rect" width={200} style={{ marginBottom: 6 }} />
          ) : (
            <div className="row features">
              <div className="col-12">
                <p>
                  <i className="i30px ibookcalender"></i>
                  {data.start_date}
                </p>
              </div>
              <div className="col-12">
                <p>
                  <i className="i30px ibookclock-time"></i>
                  {data.booking_time} to
                  {data.booking_end_time}
                </p>
              </div>
            </div>
          )}
        </div>
        <div className="col-lg-3">
          <div className="row mt-3">
            <div className="col-sm-6 my-3 my-lg-0 col-lg-12 col-xl-11">
              <p className="price d-flex justify-content-end align-items-center h4 my-3 text-primary">
                <i className="i40px ibookcompleted"></i>${data && data.isPaid}
                <span>/hr</span>
              </p>
            </div>
            <div className="col-sm-6 my-3 my-lg-0 col-lg-12 col-xl-11">
              {(() => {
                switch (status) {
                  case "pending":
                    return (
                      <button
                        type="button"
                        className="btn mt-lg-5 btn-primary btn-feet --blue btn-lg btn-block rounded-pill"
                        onClick={() => handleCancel(data.booking_id)}
                      >
                        Cancel Booking
                      </button>
                    );

                  case "upcoming":
                    return (
                      <button
                        type="button"
                        className="btn mt-lg-5 btn-primary btn-feet --blue btn-lg btn-block rounded-pill"
                        onClick={() => handleCancel(data.booking_id)}
                      >
                        Cancel Booking
                      </button>
                    );

                  case "completed":
                    // if (data.isPaid || isPaid) {
                    return (
                      <Link
                        to={`/services/${data && data.provider_id}/services`}
                        className="btn mt-lg-5 btn-primary btn-feet --blue btn-lg btn-block rounded-pill"
                      >
                        Book Again
                      </Link>
                    );
                  // }
                  // return (
                  //   <button
                  //     type="button"
                  //     className="btn mt-lg-5 btn-primary btn-feet --blue btn-lg btn-block rounded-pill"
                  //     onClick={() => handlePayment(data && data.booking_id)}
                  //   >
                  //     Complete Payment
                  //   </button>
                  // );

                  case "ongoing":
                    // return (
                    //   <Link
                    //     to={`/dashboard/my-bookings/track/${
                    //       data && data.booking_id
                    //     }`}
                    //     className="btn mt-lg-5 btn-primary btn-feet --blue btn-lg btn-block rounded-pill"
                    //   >
                    //     Track Pet
                    //   </Link>
                    // );
                    return (
                      <button
                        type="button"
                        className="btn mt-lg-5 btn-primary btn-feet --blue btn-lg btn-block rounded-pill"
                        onClick={() => handlePayment(data && data.booking_id)}
                      >
                        Complete Payment
                      </button>
                    );

                  default:
                    return (
                      <Link
                        to={`/services/${data && data.provider_id}/services`}
                        className="btn mt-lg-5 btn-primary btn-feet --blue btn-lg btn-block rounded-pill"
                      >
                        Book Again
                      </Link>
                    );
                }
              })()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Booking;
