import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { rateBooking } from "../../slice";

const Reviews = ({ data }) => {
  const dispatch = useDispatch();
  const { bookingID } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const [feedback, setFeedback] = useState("");
  const [rating, setRating] = useState(1);
  const [loading, setLoading] = useState(false);

  const [error, setError] = useState("");

  const handleSubmit = (event, value) => {
    event.preventDefault();

    if (feedback) {
      setLoading(true);
      const payload = {
        booking_id: bookingID,
        rating,
        feedback,
      };

      dispatch(rateBooking(payload)).then(
        ({ errorMessage, status, message }) => {
          setLoading(false);
          if (status === 200) {
            setFeedback("");
            setRating(1);
            enqueueSnackbar(message || "Success", {
              variant: "success",
            });
          } else {
            enqueueSnackbar(errorMessage || "Error", {
              variant: "error",
            });
          }
        }
      );
    } else {
      setError("Required.");
    }
  };

  function getClassName(level) {
    if (rating >= level) {
      return "i40px iratingstar active";
    } else if (rating === level - 0.5) {
      return "fas fa-star-half-alt";
    } else {
      return "i40px iratingstar";
    }
  }

  return (
    <div className="booking-item py-5 mt-5 px-5 text-center">
      <div className="col-lg-8 mx-auto">
        <p className="h3">Rating & Reviews</p>
        <p className="h6 text-gray">
          Please rate & review the service provider
        </p>
        <div className="details-rating ">
          <i className={getClassName(1)} onClick={() => setRating(1)}></i>
          <i className={getClassName(2)} onClick={() => setRating(2)}></i>
          <i className={getClassName(3)} onClick={() => setRating(3)}></i>
          <i className={getClassName(4)} onClick={() => setRating(4)}></i>
          <i className={getClassName(5)} onClick={() => setRating(5)}></i>
        </div>
        <span className="error">{error}</span>
        <textarea
          className="form-control my-4 review-textarea"
          cols="30"
          rows="7"
          value={feedback || ""}
          onChange={(e) => {
            let value = e.target.value;
            setFeedback(value);
          }}
        ></textarea>
        <button
          type="button"
          className="btn mt-lg-5 btn-primary btn-feet --blue btn-lg  px-5 rounded-pill"
          onClick={handleSubmit}
        >
          {loading ? "Saving..." : "Submit"}
        </button>
      </div>
    </div>
  );
};

export default Reviews;
