import React from "react";

import avatar_png from "../../../../../images/avatar.png";

const baseURL = process.env.REACT_APP_PAW_HUGGER_WEB_BASE_URL;

const CustomerDetails = ({ data }) => {
  return (
    <div className="col-lg-7 d-flex flex-wrap">
      <p className="h5 text-gray mb-4  ml-3">Customer Details</p>
      <div className="serviceItem my-0 shadow  w-100">
        <div className="row">
          <div className="col-3 col-xl-2">
            <div className="provideravtar">
              <img
                className="avtar"
                src={
                  data && data.profileurl
                    ? `${baseURL}/${data.profileurl}`
                    : avatar_png
                }
                alt="avtar"
              />
            </div>
          </div>
          <div className="col-9 col-xl-10">
            <div className="row justify-content-between ">
              <div className=" col-auto">
                <h5 className="name">
                  {data && data.first_name + " " + data.last_name}
                </h5>
                <p>
                  <i className="i20px imobflag"></i>
                  {data && data.country_code + " " + data.mobile}
                </p>
                <p>
                  <i className="i20px ilocationmarker"></i>
                  {data && data.address}
                </p>
              </div>
              <div className="col-auto">
                <a
                  href={`https://maps.google.com/?q=${data && data.latitude},${
                    data && data.longitude
                  }`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="i40px idirection"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerDetails;
