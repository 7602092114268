import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";

import {
  useAddressPredictions,
  useGeoCoder,
  usePosition,
} from "../../app/hooks/mapHooks";
import { reverseDate } from "../../utils";
import {
  fetchServiceDetails,
  fetchServices,
  getAvailableProviders,
} from "../customer/services/slice";

const Banner = () => {
  const dispatch = useDispatch();
  const { replace, push } = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const { services, serviceDetails, isLoggedIn, user } = useSelector(
    (state) => {
      return {
        services: state.services.services,
        serviceDetails: state.services.serviceDetails,
        isLoggedIn: state.accounts.loggedIn,
        user: state.accounts.user,
      };
    }
  );

  const { lat, lng, currentLocation } = usePosition();

  const [loading, setLoading] = useState(false);
  const [serviceID, setServiceID] = useState("");
  const [subServiceID, setSubServiceID] = useState("");
  const [startDate, setStartDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [endDate, setEndDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [current, setCurrent] = useState(true);
  // const [time, setTime] = useState(new Date().toTimeString().split(" ")[0]);
  const [placeId, setPlaceId] = useState("");
  const [address, setAddress] = useState(currentLocation || "");
  const [location, setLocation] = useState("");
  const [showPredictions, setShowPredictions] = useState(false);
  const [error, setError] = useState({});

  const predictions = useAddressPredictions(location || " ");
  const geometry = useGeoCoder(placeId);

  useEffect(() => {
    setLocation(currentLocation);
    setAddress(currentLocation);
  }, [currentLocation]);

  useEffect(() => {
    if (!services) {
      dispatch(fetchServices({ page: 1 }));
    }
  }, [services, dispatch]);

  const validate = () => {
    let errors = {};
    let isValid = true;

    if (!location) {
      isValid = false;
      errors.address = "Required";
    } else if (location && !address) {
      isValid = false;
      errors.address = "Please choose address from predictions.";
    }

    if (!serviceID) {
      isValid = false;
      errors.service = "Required";
    }

    if (!startDate) {
      isValid = false;
      errors.startDate = "Required";
    }

    if (!endDate) {
      isValid = false;
      errors.endDate = "Required";
    }

    setError(errors);
    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isLoggedIn && user === "Customer") {
      if (validate()) {
        setLoading(true);
        let query = "?cpage=1&perpage=10";

        if (serviceID) {
          query = query + `&service_id=${serviceID}`;
        }

        if (
          serviceDetails &&
          serviceDetails.total_service &&
          serviceDetails.total_service.length &&
          subServiceID
        ) {
          query = query + `&sub_service_id=${subServiceID}`;
        }

        if (current) {
          query = query + `&current_lat=${lat}&current_lng=${lng}`;
        } else {
          query =
            query +
            `&current_lat=${geometry && geometry.lat}&current_lng=${
              geometry && geometry.lng
            }`;
        }

        if (startDate) {
          query = query + `&start_date=${reverseDate(startDate)}`;
        }

        if (endDate) {
          query = query + `&end_date=${reverseDate(endDate)}`;
        }

        dispatch(getAvailableProviders({ query })).then(() => {
          setLoading(false);
          const serviceObj = services.data.find((el) => el._id === serviceID);
          replace(
            `/services/${serviceID}/list?service=${serviceObj.service_name}`
          );
        });
      }
    } else {
      if (user === "Provider") {
        enqueueSnackbar("Please login as a customer.", {
          variant: "info",
        });
      } else {
        push("/customer/login");
      }
    }
  };

  return (
    <section className="home-banner ">
      <div className="banner-img" />
      <div className="banner d-flex justify-content-center">
        <div className="container align-self-center">
          <div className="row justify-content-center">
            <div className="col-xl-10">
              <div className="caption d-flex  justify-content-center">
                <div className="caption-text font- align-self-center  col-lg-12 ">
                  <div className="form-row hero-form">
                    <div className="form-group col-sm-6 col-lg-3 col-xl">
                      <label htmlFor="">I'm looking for?</label>
                      <span className="error">{error.service}</span>
                      <div className="custom-group-input rounded left-icon select_div">
                        <select
                          className="form-control pointer"
                          value={serviceID || ""}
                          onChange={(e) => {
                            let value = e.target.value;
                            setError({ ...error, service: "" });
                            setServiceID(value);
                            dispatch(fetchServiceDetails({ serviceID: value }));
                          }}
                          required
                        >
                          <option value="" disabled>
                            Select your Service
                          </option>
                          {services &&
                            services.data &&
                            services.data.length &&
                            services.data.map((el) => (
                              <option key={el._id} value={el._id}>
                                {el.service_name}
                              </option>
                            ))}
                        </select>
                        <span className="group-icon-left ">
                          <i className="idogfeet i30px"></i>
                        </span>
                      </div>
                    </div>
                    {serviceDetails &&
                    serviceDetails.total_service &&
                    serviceDetails.total_service.length &&
                    serviceDetails.service_id === serviceID ? (
                      <div className="form-group col-sm-6 col-lg-3 col-xl">
                        <label htmlFor="">I'm looking for?</label>
                        <div className="custom-group-input rounded left-icon select_div">
                          <select
                            className="form-control pointer"
                            value={subServiceID || ""}
                            onChange={(e) => {
                              let value = e.target.value;
                              setSubServiceID(value);
                            }}
                          >
                            <option value="" disabled>
                              Select sub service
                            </option>
                            {serviceDetails.total_service.map((el) => (
                              <option key={el._id} value={el._id}>
                                {el.sub_service_name}
                              </option>
                            ))}
                          </select>
                          <span className="group-icon-left ">
                            <i className="idogfeet i30px"></i>
                          </span>
                        </div>
                      </div>
                    ) : null}
                    <div
                      className="form-group col-sm-6 col-lg-3"
                      style={{ position: "relative" }}
                    >
                      <label htmlFor="">I'm looking for?</label>
                      <span className="error">{error.address}</span>
                      <div className="custom-group-input rounded both-icon">
                        <input
                          type="text"
                          className="form-control "
                          placeholder="Address"
                          required
                          onInput={() => setError({ ...error, address: "" })}
                          value={address ? address : location}
                          onChange={(e) => {
                            let value = e.target.value;
                            setAddress("");
                            setLocation(value);
                            setShowPredictions(true);
                          }}
                        />
                        <span className="group-icon-left ">
                          <i className="imapmarker i30px"></i>
                        </span>
                        <span className="group-icon-right ">
                          <i
                            className="ilocationcircle i30px pointer"
                            onClick={() => {
                              setCurrent(true);
                              setAddress(currentLocation);
                              setLocation(currentLocation);
                            }}
                          ></i>
                        </span>
                        {showPredictions && (
                          <ul className="predictions">
                            {predictions.map((prediction, index) => (
                              <li
                                key={index}
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  setCurrent(false);
                                  setPlaceId(prediction.placeId);
                                  setAddress(prediction.description);
                                  setLocation(prediction.description);
                                  setShowPredictions(false);
                                }}
                              >
                                {prediction.description}
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                    </div>
                    <div className="form-group col-sm-6 col-lg-3">
                      <label htmlFor="">Start Date</label>
                      <span className="error">{error.startDate}</span>
                      <div className="custom-group-input rounded left-icon">
                        <input
                          type="date"
                          className="form-control "
                          placeholder="Start Date"
                          value={startDate || ""}
                          min={new Date().toISOString().split("T")[0]}
                          required
                          onChange={(e) => {
                            let value = e.target.value;
                            setStartDate(value);
                          }}
                        />
                        <span className="group-icon-left ">
                          <i className="icalendar i30px"></i>
                        </span>
                      </div>
                    </div>
                    <div className="form-group col-sm-6 col-lg-3 col-xl">
                      <label htmlFor="">End Date</label>
                      <span className="error">{error.endDate}</span>
                      <div className="custom-group-input rounded left-icon">
                        <input
                          type="date"
                          className="form-control"
                          placeholder="Start Date"
                          // value={endDate || ""}
                          disabled={!startDate}
                          min={
                            startDate
                              ? startDate
                              : new Date().toISOString().split("T")[0]
                          }
                          required
                          onChange={(e) => {
                            let value = e.target.value;
                            setEndDate(value);
                          }}
                        />
                        <span className="group-icon-left ">
                          <i className="icalendar i30px"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row justify-content-center">
                    <div className=" mt-4 col-lg-4 col-6 ">
                      <button
                        type="button"
                        className="btn btn-primary btn-feet --blue btn-lg btn-block rounded-pill"
                        onClick={handleSubmit}
                      >
                        {loading ? "Searching..." : "Search"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
