import { Skeleton } from "@material-ui/lab";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useLocation, useParams } from "react-router-dom";

import avatar_png from "../../../../images/avatar.png";

import GoogleMap from "../../../../components/googleMap";
import { getAvailableProviders } from "../slice";

const baseURL = process.env.REACT_APP_PAW_HUGGER_WEB_BASE_URL;

const ProviderMapView = () => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const { serviceID } = useParams();

  const { providers } = useSelector((state) => {
    return {
      providers: state.services.providers,
    };
  });

  return (
    <section className="home-services py-5 ">
      <div className="container-fluid py-lg-5 mt-sm-5">
        <div className="col-xl-11 mx-auto">
          <div className="row align-items-center">
            <div className="col-6">
              <h3 className="text-gray font-weight-normal">
                {search
                  ? search.split("service=")[1] &&
                    search.split("service=")[1].replace("%20", " ")
                  : "Pet Sitter"}
              </h3>
            </div>
            <div className="col-sm-6 text-sm-right">
              <NavLink to={`/services/${serviceID}/list`} replace>
                <i className="i50px ilistview"></i>
              </NavLink>
              <NavLink
                to={`/services/${serviceID}/map`}
                className="ml-3"
                replace
              >
                <i className="i50px imapview active"></i>
              </NavLink>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className=" col-lg-6">
              {providers && providers.data && providers.data.length ? (
                providers.data.map((el) => (
                  <div className="serviceItem shadow" id={el._id} key={el._id}>
                    <div className="row">
                      <div className="col-sm-3 col-xl-2">
                        <div className="provideravtar">
                          <img
                            className="avtar"
                            src={
                              el.user && el.user.profileurl
                                ? `${baseURL}/${el.user.profileurl}`
                                : avatar_png
                            }
                            alt="avtar"
                          />
                          <div className="rating-star">
                            <div className="circlerating">
                              <i className="iratingstar active"></i>
                            </div>
                            <div>{(el.user && el.user.rating) || 0}/5</div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-9 col-xl-10">
                        <div className="row justify-content-between align-items-end">
                          <div className="col-auto">
                            <h4 className="name">
                              {el.user &&
                                el.user.first_name + " " + el.user.last_name}
                              {el.user && el.user.is_verified ? (
                                <i className="i30px ml-4 iverified"></i>
                              ) : null}
                            </h4>
                          </div>
                          <div className="col-auto">
                            <h4 className="price text-primary">
                              ${el.price} <span> / hr</span>
                            </h4>
                          </div>
                        </div>
                        <p>
                          <i className="i20px iexperience"></i>
                          {el.user && el.user.exp_years} year Exp
                        </p>
                        <p>
                          <i className="i20px ilocationmarker"></i>
                          {el.user && el.user.address}
                        </p>

                        <div className="row mt-3 align-items-center">
                          {el.providerServiceImages && (
                            <div className="col-xl-7 col-sm-6">
                              <p>
                                <span className="text-gray small">Photos</span>
                              </p>
                              <div className="gallery">
                                {el.providerServiceImages[0] && (
                                  <img
                                    className="galleryimg"
                                    src={
                                      el.providerServiceImages[0].path
                                        ? `${baseURL}/${el.providerServiceImages[0].path}`
                                        : avatar_png
                                    }
                                    alt="avtar"
                                  />
                                )}
                                {el.providerServiceImages[1] && (
                                  <img
                                    className="galleryimg"
                                    src={
                                      el.providerServiceImages[1].path
                                        ? `${baseURL}/${el.providerServiceImages[1].path}`
                                        : avatar_png
                                    }
                                    alt="avtar"
                                  />
                                )}
                                {el.providerServiceImages[1] && (
                                  <img
                                    className="galleryimg"
                                    src={
                                      el.providerServiceImages[1].path
                                        ? `${baseURL}/${el.providerServiceImages[1].path}`
                                        : avatar_png
                                    }
                                    alt="avtar"
                                  />
                                )}
                                {el.providerServiceTotalImages > 3 && (
                                  <div>
                                    +{el.providerServiceTotalImages - 3}
                                  </div>
                                )}
                              </div>
                              <p>{el.service_words}</p>
                            </div>
                          )}
                          <div className="col-xl-5 col-sm-6">
                            <Link
                              to={`/services/${el.providerid}/services`}
                              className="btn btn-primary btn-feet --blue btn-lg btn-block rounded-pill"
                            >
                              Book Now
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <span>Not available</span>
              )}
            </div>
            <div className="col-lg-6">
              <div
                id="servicemap"
                className="mt-5"
                style={{ display: "block", height: "100%" }}
              >
                {providers ? (
                  <GoogleMap markers={providers.data} height="600px" />
                ) : (
                  <Skeleton variant="rect" height={600} />
                )}
              </div>
            </div>
          </div>
          {providers && Number(providers.pages) > Number(providers.cpage) && (
            <span
              onClick={() =>
                dispatch(
                  getAvailableProviders({
                    query: `?cpage=${providers.cpage + 1}&perpage=10`,
                    more: true,
                  })
                )
              }
            >
              get more...
            </span>
          )}
        </div>
      </div>
    </section>
  );
};

export default ProviderMapView;
