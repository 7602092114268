import React, { forwardRef } from "react";
import { NavLink, Route, Switch, useRouteMatch } from "react-router-dom";

import avatar_png from "../../../../../images/avatar.png";

import Availability from "../availability";
import Reviews from "../reviews";
import Services from "../services";
import Stats from "../stats";

const baseURL = process.env.REACT_APP_PAW_HUGGER_WEB_BASE_URL;

const Details = forwardRef((props, ref) => {
  let { path, url } = useRouteMatch();

  const { data } = props;

  return (
    <section className="py-5">
      <div className="container">
        <p>
          {data &&
            data.providerServices &&
            data.providerServices[0] &&
            data.providerServices[0].userdetails &&
            data.providerServices[0].userdetails.service_words}
        </p>
        <h5 className="text-primary mt-4">Skills:</h5>
        <ul className="list-feet mt-2">
          {data &&
            data.providerServices &&
            data.providerServices[0] &&
            data.providerServices[0].userdetails &&
            data.providerServices[0].userdetails.skills &&
            data.providerServices[0].userdetails.skills
              .split(/\r?\n/)
              .map((el, index) => <li key={index}>{el}</li>)}
        </ul>
        {data && data.providerServiceImagesTotal ? (
          <p className="text-gray h6 mt-4">
            Photos ({data && data.providerServiceImagesTotal})
          </p>
        ) : null}

        <ul className="details-gallery ">
          {data &&
            data.providerServiceimages &&
            data.providerServiceimages.map((el) => (
              <li key={el._id}>
                <img
                  src={el && el.path ? `${baseURL}/${el.path}` : avatar_png}
                  alt="img"
                  className="img-fluid"
                />
              </li>
            ))}
        </ul>

        <div className="col-xl-11 mx-auto mt-5" ref={ref}>
          <ul
            className="nav nav-justified custom-tabs group-tabs nav-pills"
            id="myTab"
            role="tablist"
          >
            <li className="nav-item">
              <NavLink
                className="nav-link"
                data-toggle="tab"
                to={`${url}/services`}
                role="tab"
              >
                Services
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className="nav-link"
                data-toggle="tab"
                to={`${url}/availability`}
                role="tab"
              >
                Availability
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className="nav-link"
                data-toggle="tab"
                to={`${url}/stats`}
                role="tab"
              >
                Stats
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className="nav-link"
                data-toggle="tab"
                to={`${url}/reviews`}
                role="tab"
              >
                Review & Rating
              </NavLink>
            </li>
          </ul>

          <div
            className="tab-content details-tab-content py-3 p-sm-4 p-lg-5"
            id="myTabContent"
          >
            <Switch>
              <Route exact path={`${path}/services`}>
                <Services data={data && data.providerServices} path={url} />
              </Route>
              <Route exact path={`${path}/availability`}>
                <Availability
                  data={data && data.workingday}
                  services={data && data.providerServices}
                />
              </Route>
              <Route exact path={`${path}/stats`}>
                <Stats data={data && data.stats} />
              </Route>
              <Route exact path={`${path}/reviews`}>
                <Reviews
                  rating={data && data.rating}
                  reviewCount={data && data.reviewCount}
                  reviews={data && data.rating_review}
                />
              </Route>
            </Switch>
          </div>
        </div>
      </div>
    </section>
  );
});

export default Details;
