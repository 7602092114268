/* eslint-disable linebreak-style */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/jsx-props-no-spreading */

import React, { memo, useLayoutEffect, useRef } from "react";
import PropTypes from "prop-types";

import { usePrevious } from "../../app/hooks";

const SingleOTPInputComponent = (props) => {
  const { focus, autoFocus, ...rest } = props;
  const inputRef = useRef();
  const prevFocus = usePrevious(!!focus);
  useLayoutEffect(() => {
    if (inputRef.current) {
      if (focus && autoFocus) {
        inputRef.current.focus();
      }
      if (focus && autoFocus && focus !== prevFocus) {
        inputRef.current.focus();
        inputRef.current.select();
      }
    }
  }, [autoFocus, focus, prevFocus]);

  return <input placeholder="0" ref={inputRef} {...rest} />;
};

SingleOTPInputComponent.propTypes = {
  autoFocus: PropTypes.bool,
  focus: PropTypes.bool,
};

SingleOTPInputComponent.defaultProps = {
  autoFocus: true,
  focus: true,
};

const SingleOTPInput = memo(SingleOTPInputComponent);

export default SingleOTPInput;
