/* eslint-disable linebreak-style */

import axios from "axios";
import qs from "querystring";

const baseURL = process.env.REACT_APP_PAW_HUGGER_WEB_BASE_URL;

export async function postForm({ uri, payload, authName }) {
  try {
    const { data, status } = await axios.post(baseURL + uri, payload, {
      headers: {
        Authorization: localStorage && localStorage.getItem(authName),
      },
    });

    return {
      data,
      status,
    };
  } catch (error) {
    if (error.response && error.response.data) {
      return {
        data: error.response.data,
        status: error.response.status,
      };
    }
    return {
      data: {},
      status: 404,
    };
  }
}

export async function postEncoded({ uri, payload, authName }) {
  try {
    const { data, status } = await axios.post(
      baseURL + uri,
      qs.stringify(payload),
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: localStorage && localStorage.getItem(authName),
        },
      }
    );
    return {
      data,
      status,
    };
  } catch (error) {
    if (error.response && error.response.data) {
      return {
        data: error.response.data,
        status: error.response.status,
      };
    }
    return {
      data: {},
      status: 404,
    };
  }
}

export async function putEncoded({ uri, payload, authName }) {
  try {
    const { data, status } = await axios.put(
      baseURL + uri,
      qs.stringify(payload),
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: localStorage && localStorage.getItem(authName),
        },
      }
    );

    return {
      data,
      status,
    };
  } catch (error) {
    if (error.response && error.response.data) {
      return {
        data: error.response.data,
        status: error.response.status,
      };
    }
    return {
      data: {},
      status: 404,
    };
  }
}

export async function putForm({ uri, payload, authName }) {
  try {
    const { data, status } = await axios.put(baseURL + uri, payload, {
      headers: {
        Authorization: localStorage && localStorage.getItem(authName),
      },
    });

    return {
      data,
      status,
    };
  } catch (error) {
    if (error.response && error.response.data) {
      return {
        data: error.response.data,
        status: error.response.status,
      };
    }
    return {
      data: {},
      status: 404,
    };
  }
}

export async function axiosGet({ uri, authName }) {
  try {
    const { data, status } = await axios.get(baseURL + uri, {
      headers: {
        Authorization: localStorage && localStorage.getItem(authName),
      },
    });

    return {
      data,
      status,
    };
  } catch (error) {
    if (error.response && error.response.data) {
      return {
        data: error.response.data,
        status: error.response.status,
      };
    }
    return {
      data: {},
      status: 404,
    };
  }
}

export async function deleteEncoded({ uri, payload, authName }) {
  try {
    const { data, status } = await axios.delete(baseURL + uri, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: localStorage && localStorage.getItem(authName),
      },
      data: qs.stringify(payload),
    });

    return {
      data,
      status,
    };
  } catch (error) {
    if (error.response && error.response.data) {
      return {
        data: error.response.data,
        status: error.response.status,
      };
    }
    return {
      data: {},
      status: 404,
    };
  }
}

export async function createStipeAccount() {
  axios
    .get(`${baseURL}/get_stripe_connect_link`, {
      headers: {
        Authorization: localStorage && localStorage.getItem("phgr"),
      },
    })
    .then(({ data }) => {
      window.open(data.data, "_blank").focus();
    });
}
