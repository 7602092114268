import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import _ from "lodash";
import CircularProgress from "@material-ui/core/CircularProgress";

import {
  fetchServiceDetails,
  fetchServices,
  setServiceDetails,
  selectLoading,
} from "../../../customer/services/slice";
import { addProfileService, getProfileData } from "../../slice";

const Services = ({ data }) => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [activeService, setActiveService] = useState("");
  const [completedService, setCompletedService] = useState([]);
  const [serviceName, setServiceName] = useState("");
  const [error, setError] = useState({});
  const [state, setState] = useState({});
  const [hasSubService, setHasSubService] = useState(false);
  const [subServiceDesc, setSubServiceDesc] = useState("");
  const [activeSubService, setActiveSubService] = useState("");
  const [completedSubService, setCompletedSubService] = useState([]);

  const { loading, services, serviceDetails } = useSelector((state) => {
    return {
      loading: state.provider.loading,
      services: state.services.services,
      serviceDetails: state.services.serviceDetails,
    };
  });

  const fetching = useSelector(selectLoading);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!services) {
      dispatch(fetchServices({ page: 1 }));
      dispatch(setServiceDetails(null));
    } else if (services && services.data && services.data[0]) {
      let newState = {};
      const { _id, service_name } = services.data[0];
      setActiveService(_id);
      setServiceName(service_name);

      services.data.forEach((element) => {
        newState[element.service_id] = {
          serviceID: element.service_id,
          time_slots: "",
          time_unit: "hr",
          exp_years: "",
          price: "",
        };
      });

      setState(newState);
    }
  }, [services, dispatch]);

  useEffect(() => {
    if (
      serviceDetails &&
      serviceDetails.total_service &&
      serviceDetails.total_service.length &&
      serviceDetails.service_id === activeService
    ) {
      setHasSubService(true);
      setActiveSubService(serviceDetails.total_service[0]._id);
    } else {
      setHasSubService(false);
      setActiveSubService("");
    }
  }, [serviceDetails, activeService, dispatch]);

  useEffect(() => {
    if (data && data.length) {
      let newState = { ...state };
      let services = [];
      let subSurvices = [];
      data.forEach((element) => {
        if (element.sub_service_id) {
          newState[element.sub_service_id] = { ...element };
          subSurvices.push(element.sub_service_id);
          services.push(element.serviceID);
        } else {
          newState[element.serviceID] = { ...element };
          services.push(element.serviceID);
        }
      });
      setCompletedSubService(
        subSurvices.filter((v, i, a) => a.indexOf(v) === i)
      );
      setCompletedService(services.filter((v, i, a) => a.indexOf(v) === i));
      setState({ ...newState });
    }
    // eslint-disable-next-line
  }, [data]);

  const validateService = () => {
    let errors = {};
    let isValid = true;

    if (completedService.includes(activeService)) {
      if (!(state[activeService] && state[activeService].price)) {
        isValid = false;
        errors.cost = "*Required";
      } else if (
        state[activeService] &&
        state[activeService].price &&
        typeof state[activeService].price !== "number" &&
        state[activeService].price < 1
      ) {
        isValid = false;
        errors.cost = "Please enter a valid positive number";
      }

      if (!(state[activeService] && state[activeService].time_slots)) {
        isValid = false;
        errors.interval = "*Required";
      }

      if (!(state[activeService] && Number(state[activeService].exp_years))) {
        isValid = false;
        errors.experience = "*Required";
      }
    }

    setError(errors);
    return isValid;
  };

  const validateSubService = () => {
    let errors = {};
    let isValid = true;

    if (completedSubService.includes(activeSubService)) {
      if (
        serviceName === "Dog Groomer" &&
        !(state[activeSubService] && state[activeSubService].short_hair_cost)
      ) {
        isValid = false;
        errors.shortHair = "*Required";
      } else if (
        serviceName === "Dog Groomer" &&
        state[activeSubService] &&
        state[activeSubService].short_hair_cost &&
        typeof state[activeSubService].short_hair_cost !== "number" &&
        state[activeSubService].short_hair_cost < 1
      ) {
        isValid = false;
        errors.shortHair = "Please enter a valid positive number";
      }

      if (
        serviceName === "Dog Groomer" &&
        !(state[activeSubService] && state[activeSubService].medium_hair_cost)
      ) {
        isValid = false;
        errors.mediumHair = "*Required";
      } else if (
        serviceName === "Dog Groomer" &&
        state[activeSubService] &&
        state[activeSubService].medium_hair_cost &&
        typeof state[activeSubService].medium_hair_cost !== "number" &&
        state[activeSubService].medium_hair_cost < 1
      ) {
        isValid = false;
        errors.mediumHair = "Please enter a valid positive number";
      }

      if (
        serviceName === "Dog Groomer" &&
        !(state[activeSubService] && state[activeSubService].long_hair_cost)
      ) {
        isValid = false;
        errors.longHair = "*Required";
      } else if (
        serviceName === "Dog Groomer" &&
        state[activeSubService] &&
        state[activeSubService].long_hair_cost &&
        typeof state[activeSubService].long_hair_cost !== "number" &&
        state[activeSubService].long_hair_cost < 1
      ) {
        isValid = false;
        errors.longHair = "Please enter a valid positive number";
      }

      if (!(state[activeSubService] && state[activeSubService].time_slots)) {
        isValid = false;
        errors.interval = "*Required";
      }

      if (
        !(state[activeSubService] && Number(state[activeSubService].exp_years))
      ) {
        isValid = false;
        errors.experience = "*Required";
      }
    }

    setError(errors);
    return isValid;
  };

  const validate = () => {
    let isValid = false;

    if (completedService.length && validateService()) {
      isValid = true;
    }
    if (completedSubService.length && validateSubService()) {
      isValid = true;
    }

    for (const key of Object.keys(state)) {
      if (!_.isEmpty(state[key])) {
        isValid = true;
      }
    }

    return isValid;
  };

  const handleServiceChange = ({ event, serviceID, name }) => {
    event.preventDefault();

    if (
      !hasSubService &&
      completedService.includes(activeService) &&
      validateService()
    ) {
      setError({});
      dispatch(fetchServiceDetails({ serviceID })).then(({ data }) => {
        setActiveService(serviceID);
        setServiceName(name);

        if (data && data.total_service) {
          let newState = {};
          data.total_service.forEach((element) => {
            newState[element._id] = {
              serviceID: element.serviceId,
              time_slots: "",
              time_unit: "hr",
              exp_years: "",
              price: "",
              short_hair_cost: "",
              medium_hair_cost: "",
              long_hair_cost: "",
              sub_service_id: element._id,
            };
          });
          setState({ ...newState, ...state });
        }
      });
    } else if (!completedService.includes(activeService) || hasSubService) {
      setError({});
      dispatch(fetchServiceDetails({ serviceID })).then(({ data }) => {
        setActiveService(serviceID);
        setServiceName(name);
      });
    } else {
      enqueueSnackbar("Please fill all details.", {
        variant: "error",
      });
    }
  };

  const handleSubServiceChange = ({ event, subServiceID, description }) => {
    event.preventDefault();

    if (
      completedSubService.includes(activeSubService) &&
      validateSubService()
    ) {
      setError({});
      setActiveSubService(subServiceID);
      setSubServiceDesc(description);
    } else if (!completedSubService.includes(activeSubService)) {
      setError({});
      setActiveSubService(subServiceID);
      setSubServiceDesc(description);
    } else {
      enqueueSnackbar("Please fill all details.", {
        variant: "error",
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validate()) {
      let payload = { selectedService: [] };

      for (const key of Object.keys(state)) {
        const element = state[key];
        if (
          !_.isEmpty(element) &&
          element.exp_years &&
          element.serviceID &&
          (completedService.includes(element.serviceID) ||
            completedSubService.includes(element.sub_service_id))
        ) {
          let value = {};

          if (element.serviceID) {
            value.serviceID = element.serviceID;
          }
          if (element.price) {
            value.price = element.price;
          }
          if (element.exp_years) {
            value.exp_years = element.exp_years;
          }
          if (element.time_slots) {
            value.time_slots = element.time_slots;
          }
          if (element.time_unit) {
            value.time_unit = element.time_unit;
          }
          if (element.sub_service_id) {
            value.sub_service_id = element.sub_service_id;
          }
          if (element.short_hair_cost) {
            value.short_hair_cost = element.short_hair_cost;
          }
          if (element.medium_hair_cost) {
            value.medium_hair_cost = element.medium_hair_cost;
          }
          if (element.long_hair_cost) {
            value.long_hair_cost = element.long_hair_cost;
          }
          if (element.time_unit) {
            value.time_unit = element.time_unit;
          }

          payload.selectedService.push(value);
        }
      }

      dispatch(addProfileService(payload)).then(
        ({ message, status, errorMessage }) => {
          if (status === 200) {
            dispatch(getProfileData());
            enqueueSnackbar(message || "Success", {
              variant: "success",
            });
            push("/profile-setup/experience");
          } else {
            enqueueSnackbar(errorMessage || "Error", {
              variant: "error",
            });
          }
        }
      );
    } else {
      setError({ ...error, service: "Please select at least one service." });
      enqueueSnackbar(
        "Please select at least one service using toggle button & fill details.",
        {
          variant: "error",
        }
      );
    }
  };

  return (
    <div className="container mt-xl-5 pt-xl-3">
      <form onSubmit={handleSubmit}>
        <p className="h4 mb-5">
          What services you offer?{" "}
          <span className="error">{error.service}</span>
        </p>
        <ul className="nav nav-justified text-center services-tabs ">
          {services &&
            services.data &&
            services.data.length &&
            services.data.map((el) => (
              <li className="nav-item" key={el._id}>
                <Link
                  className={
                    completedService.includes(el.service_id)
                      ? `nav-link ${
                          el.service_name &&
                          el.service_name.toLowerCase().replace("dog ", "")
                        } completed`
                      : activeService === el.service_id
                      ? `nav-link ${
                          el.service_name &&
                          el.service_name.toLowerCase().replace("dog ", "")
                        } active`
                      : `nav-link ${
                          el.service_name &&
                          el.service_name.toLowerCase().replace("dog ", "")
                        }`
                  }
                  id={el._id}
                  to="/profile-setup/services"
                  onClick={(event) =>
                    handleServiceChange({
                      event,
                      serviceID: el.service_id,
                      name: el.service_name,
                    })
                  }
                >
                  <i
                    className={`i50px idog-${
                      el.service_name &&
                      el.service_name.toLowerCase().replace("dog ", "")
                    }`}
                  ></i>
                  {el.service_name}
                  {fetching && activeService === el.service_id ? (
                    <CircularProgress />
                  ) : null}
                </Link>
              </li>
            ))}
        </ul>

        <div className="my-5 my-lg-5 d-flex align-items-center justify-content-between">
          <p className="h4 text-gray">{loading ? "Saving..." : serviceName}</p>

          <div className="d-flex justify-content-between">
            <span>Select this service here</span>
            <div className="switch-btn">
              <input
                type="checkbox"
                name=""
                id=""
                value={completedService.includes(activeService) || ""}
                checked={completedService.includes(activeService)}
                onChange={() => {
                  if (completedService.includes(activeService)) {
                    let array = completedService;
                    let index = array.indexOf(activeService);
                    if (index !== -1) {
                      array.splice(index, 1);
                    }
                    setCompletedService(array);
                  } else {
                    setCompletedService([...completedService, activeService]);
                  }
                }}
              />
              <span></span>
            </div>
          </div>
        </div>

        {hasSubService && serviceDetails.service_id === activeService ? (
          <Fragment>
            <ul
              className="nav booking-tabs service-groomtab"
              id="myTab"
              role="tablist"
            >
              {serviceDetails.total_service.map((el) => (
                <li className="nav-item" key={el._id}>
                  <Link
                    className={
                      activeSubService === el._id
                        ? `nav-link active`
                        : completedSubService.includes(el._id)
                        ? `nav-link active`
                        : `nav-link`
                    }
                    data-toggle="tab"
                    to="/profile-setup/services"
                    role="tab"
                    onClick={(event) => {
                      handleSubServiceChange({
                        event,
                        subServiceID: el._id,
                        serviceID: el.serviceId,
                        description: el.sub_service_desc,
                      });
                    }}
                  >
                    {el.sub_service_name}
                  </Link>
                </li>
              ))}
            </ul>
            <div className="tab-content mt-4 d-flex align-items-center justify-content-between">
              <div className="tab-pane active show fade">
                <p className="text-gray">{subServiceDesc}</p>
              </div>
              <div>
                <div className="switch-btn">
                  <input
                    type="checkbox"
                    value={completedSubService.includes(activeSubService) || ""}
                    checked={completedSubService.includes(activeSubService)}
                    onChange={() => {
                      setError({ ...error, service: "" });
                      if (completedSubService.includes(activeSubService)) {
                        let array = completedSubService;
                        let index = array.indexOf(activeSubService);
                        if (index !== -1) {
                          array.splice(index, 1);
                        }
                        setCompletedSubService(array);
                      } else {
                        setCompletedSubService([
                          ...completedSubService,
                          activeSubService,
                        ]);
                        setCompletedSubService([
                          ...completedSubService,
                          activeSubService,
                        ]);
                      }
                    }}
                  />
                  <span></span>
                </div>
              </div>
            </div>

            <div className="row mt-5">
              <div className="form-group col-md-5 col-lg-4">
                <label htmlFor="">
                  Time Intervals <span className="error">{error.interval}</span>
                </label>
                <div className="custom-group-input rounded-digonal  left-icon select_div">
                  <select
                    name="interval"
                    id=""
                    className="form-control"
                    value={
                      (state[activeSubService] &&
                        state[activeSubService].time_slots) ||
                      ""
                    }
                    onChange={(e) => {
                      let value = e.target.value;
                      setError({ ...error, interval: "" });
                      setState({
                        ...state,
                        [activeSubService]: {
                          ...state[activeSubService],
                          time_slots: value,
                        },
                      });
                    }}
                  >
                    <option value="" disabled>
                      Select Interval
                    </option>
                    <option value="0.5">30 minutes</option>
                    <option value="1">1 hr</option>
                    <option value="1.5">90 minutes</option>
                    <option value="2">2 hrs</option>
                  </select>
                  <span className="group-icon-left ">
                    <i className="i30px ibookdog-type"></i>{" "}
                  </span>
                </div>
              </div>
            </div>
            {serviceDetails.service_name === "Dog Groomer" ? (
              <div className="row mt-5">
                <div className="form-group col-md-5 col-lg-4">
                  <label htmlFor="">
                    Small Dog <span className="error">{error.shortHair}</span>
                  </label>
                  <div className="custom-group-input rounded-digonal left-icon">
                    <input
                      type="number"
                      name="shortHair"
                      id=""
                      min={1}
                      value={
                        (state[activeSubService] &&
                          state[activeSubService].short_hair_cost) ||
                        ""
                      }
                      className="form-control "
                      placeholder="Service Cost"
                      onChange={(e) => {
                        let value = e.target.value;
                        setError({ ...error, shortHair: "" });
                        setState({
                          ...state,
                          [activeSubService]: {
                            ...state[activeSubService],
                            short_hair_cost: value,
                          },
                        });
                      }}
                    />
                    <span className="group-icon-left ">
                      <i className="i30px icost"></i>{" "}
                    </span>
                  </div>
                </div>
                <div className="form-group col-md-5 col-lg-4">
                  <label htmlFor="">
                    Medium Dog <span className="error">{error.mediumHair}</span>
                  </label>
                  <div className="custom-group-input rounded-digonal left-icon">
                    <input
                      type="number"
                      name="mediumHair"
                      id=""
                      min={1}
                      value={
                        (state[activeSubService] &&
                          state[activeSubService].medium_hair_cost) ||
                        ""
                      }
                      className="form-control "
                      placeholder="Service Cost"
                      onChange={(e) => {
                        let value = e.target.value;
                        setError({ ...error, mediumHair: "" });
                        setState({
                          ...state,
                          [activeSubService]: {
                            ...state[activeSubService],
                            medium_hair_cost: value,
                          },
                        });
                      }}
                    />
                    <span className="group-icon-left ">
                      <i className="i30px icost"></i>{" "}
                    </span>
                  </div>
                </div>
                <div className="form-group col-md-5 col-lg-4">
                  <label htmlFor="">
                    Large Dog <span className="error">{error.longHair}</span>
                  </label>
                  <div className="custom-group-input rounded-digonal left-icon">
                    <input
                      type="number"
                      name="longHair"
                      id=""
                      min={1}
                      value={
                        (state[activeSubService] &&
                          state[activeSubService].long_hair_cost) ||
                        ""
                      }
                      className="form-control "
                      placeholder="Service Cost"
                      onChange={(e) => {
                        let value = e.target.value;
                        setError({ ...error, longHair: "" });
                        setState({
                          ...state,
                          [activeSubService]: {
                            ...state[activeSubService],
                            long_hair_cost: value,
                          },
                        });
                      }}
                    />
                    <span className="group-icon-left ">
                      <i className="i30px icost"></i>{" "}
                    </span>
                  </div>
                </div>
              </div>
            ) : null}
          </Fragment>
        ) : (
          <div className="row mt-5">
            {serviceName !== "Veterinary" ? (
              <div className="form-group col-md-5 col-lg-4">
                <label htmlFor="">
                  Time Intervals <span className="error">{error.interval}</span>
                </label>
                <div className="custom-group-input rounded-digonal left-icon select_div">
                  <select
                    name="interval"
                    id=""
                    className="form-control pointer"
                    value={
                      (state[activeService] &&
                        state[activeService].time_slots) ||
                      ""
                    }
                    onChange={(e) => {
                      let value = e.target.value;
                      setError({ ...error, interval: "" });
                      setState({
                        ...state,
                        [activeService]: {
                          ...state[activeService],
                          time_slots: value,
                        },
                      });
                    }}
                  >
                    <option value="" disabled>
                      Select Interval
                    </option>
                    {serviceName === "Dog Boarder" ? (
                      <Fragment>
                        <option value="1">1 day</option>
                        <option value="2">2 day</option>
                        <option value="3">3 day</option>
                        <option value="4">4 day</option>
                        <option value="5">5 day</option>
                        <option value="6">6 day</option>
                        <option value="7">1 week</option>
                        <option value="14">2 week</option>
                        <option value="21">3 week</option>
                        <option value="28">4 week</option>
                      </Fragment>
                    ) : (
                      <Fragment>
                        <option value="0.5">30 mins</option>
                        <option value="1">1 hr</option>
                        <option value="1.5">90 mins</option>
                        <option value="2">2 hrs</option>
                      </Fragment>
                    )}
                  </select>
                  <span className="group-icon-left ">
                    <i className="i30px ibookdog-type"></i>{" "}
                  </span>
                </div>
              </div>
            ) : null}

            <div className="form-group col-md-5 col-lg-4">
              <label htmlFor="">
                Add Cost <span className="error">{error.cost}</span>
              </label>
              <div className="custom-group-input rounded-digonal both-icon">
                <input
                  type="number"
                  name="cost"
                  id=""
                  min={1}
                  value={
                    (state[activeService] && state[activeService].price) || ""
                  }
                  className="form-control"
                  placeholder="Service Cost"
                  onChange={(e) => {
                    let value = e.target.value;
                    setError({ ...error, cost: "" });
                    setState({
                      ...state,
                      [activeService]: {
                        ...state[activeService],
                        price: value,
                      },
                    });
                  }}
                />
                <span className="group-icon-left">
                  <i className="i30px icost"></i>{" "}
                </span>
                <span className="group-icon-right">
                  {serviceName === "Dog Boarder" ? "/day" : "/hr"}
                </span>
              </div>
            </div>
          </div>
        )}

        <p className="h4 my-5">
          How much experience do you have?{" "}
          <span className="error">{error.experience}</span>
        </p>

        <div className="radio-tabs mb-5 experience-radio">
          <div>
            <input
              type="radio"
              name="experience"
              id="1year"
              // value={1}
              checked={
                state[hasSubService ? activeSubService : activeService] &&
                Number(
                  state[hasSubService ? activeSubService : activeService]
                    .exp_years
                ) === 1
              }
              onChange={() => {
                setError({ ...error, experience: "" });
                setState({
                  ...state,
                  [hasSubService ? activeSubService : activeService]: {
                    ...state[hasSubService ? activeSubService : activeService],
                    exp_years: 1,
                  },
                });
              }}
            />
            <label htmlFor="1year" className="label-button rounded-pill">
              1 Year
            </label>
          </div>
          <div>
            <input
              type="radio"
              name="experience"
              id="2years"
              // value={2}
              checked={
                state[hasSubService ? activeSubService : activeService] &&
                Number(
                  state[hasSubService ? activeSubService : activeService]
                    .exp_years
                ) === 2
              }
              onChange={() => {
                setError({ ...error, experience: "" });
                setState({
                  ...state,
                  [hasSubService ? activeSubService : activeService]: {
                    ...state[hasSubService ? activeSubService : activeService],
                    exp_years: 2,
                  },
                });
              }}
            />
            <label htmlFor="2years" className="label-button rounded-pill">
              2 Years
            </label>
          </div>
          <div>
            <input
              type="radio"
              name="experience"
              id="3years"
              // value={3}
              checked={
                state[hasSubService ? activeSubService : activeService] &&
                Number(
                  state[hasSubService ? activeSubService : activeService]
                    .exp_years
                ) === 3
              }
              onChange={() => {
                setError({ ...error, experience: "" });
                setState({
                  ...state,
                  [hasSubService ? activeSubService : activeService]: {
                    ...state[hasSubService ? activeSubService : activeService],
                    exp_years: 3,
                  },
                });
              }}
            />
            <label htmlFor="3years" className="label-button rounded-pill">
              3 Years
            </label>
          </div>
          <div>
            <input
              type="radio"
              name="experience"
              id="4years"
              // value={4}
              checked={
                state[hasSubService ? activeSubService : activeService] &&
                Number(
                  state[hasSubService ? activeSubService : activeService]
                    .exp_years
                ) === 4
              }
              onChange={() => {
                setError({ ...error, experience: "" });
                setState({
                  ...state,
                  [hasSubService ? activeSubService : activeService]: {
                    ...state[hasSubService ? activeSubService : activeService],
                    exp_years: 4,
                  },
                });
              }}
            />
            <label htmlFor="4years" className="label-button rounded-pill">
              4 Years
            </label>
          </div>
          <div>
            <input
              type="radio"
              name="experience"
              id="5years"
              // value={5}
              checked={
                state[hasSubService ? activeSubService : activeService] &&
                Number(
                  state[hasSubService ? activeSubService : activeService]
                    .exp_years
                ) === 5
              }
              onChange={() => {
                setError({ ...error, experience: "" });
                setState({
                  ...state,
                  [hasSubService ? activeSubService : activeService]: {
                    ...state[hasSubService ? activeSubService : activeService],
                    exp_years: 5,
                  },
                });
              }}
            />
            <label htmlFor="5years" className="label-button rounded-pill">
              5 Years
            </label>
          </div>
        </div>

        <div className="row mt-5">
          <div className="form-group col-md-5">
            <div className="custom-group-input rounded-digonal left-icon">
              <input
                type="number"
                name="experience"
                id=""
                value={
                  (state[hasSubService ? activeSubService : activeService] &&
                    Number(
                      state[hasSubService ? activeSubService : activeService]
                        .exp_years
                    )) ||
                  ""
                }
                min={0}
                max={99}
                className="form-control"
                placeholder="or add your experience"
                onChange={(e) => {
                  let value = e.target.value;
                  setError({ ...error, experience: "" });
                  setState({
                    ...state,
                    [hasSubService ? activeSubService : activeService]: {
                      ...state[
                        hasSubService ? activeSubService : activeService
                      ],
                      exp_years: value,
                    },
                  });
                }}
              />
              <span className="group-icon-left ">
                <i className="i30px iexperience"></i>{" "}
              </span>
            </div>
          </div>
        </div>

        <div className="row justify-content-center">
          <div className="col-md-6 mb-5 col-xl-4">
            <div className="form-group mt-5">
              <button
                type="submit"
                className="btn btn-primary btn-block btn-feet --blue btn-lg btn-block rounded-pill"
              >
                {loading ? "Saving..." : data ? "Save" : "Next"}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Services;
