import React from "react";
import {
  NavLink,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from "react-router-dom";

import routes from "../../../routes/provider";

const Dashboard = () => {
  let { url } = useRouteMatch();
  let { pathname } = useLocation();

  return (
    <main className="page-wrapper">
      <div className="my-accounts mt-5 pt-5">
        <div className="container mt-xl-5 pt-xl-3">
          <ul className="nav my-5 nav-justified text-center custom-tabs ">
            <li className="nav-item py-2">
              <NavLink
                className={
                  pathname && pathname.includes("/dashboard/my-bookings")
                    ? "nav-link active"
                    : "nav-link"
                }
                to={`${url}/my-bookings/new-requests`}
              >
                My Bookings
              </NavLink>
            </li>
            <li className="nav-item py-2">
              <NavLink className="nav-link" to={`${url}/revenue`}>
                Revenue
              </NavLink>
            </li>
            <li className="nav-item py-2">
              <NavLink className="nav-link" to={`${url}/availability`}>
                Availability
              </NavLink>
            </li>
            <li className="nav-item py-2">
              <NavLink className="nav-link " to={`${url}/my-profile`}>
                My Profile
              </NavLink>
            </li>
            <li className="nav-item py-2">
              <NavLink className="nav-link" to={`${url}/bank-accounts`}>
                Bank Accounts
              </NavLink>
            </li>
            <li className="nav-item py-2">
              <NavLink className="nav-link" to={`${url}/help`}>
                Help?
              </NavLink>
            </li>
            <li className="nav-item py-2">
              <NavLink className="nav-link " to={`${url}/change-password`}>
                Change Password
              </NavLink>
            </li>
          </ul>
          <Switch>
            {routes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
                children={<route.main />}
              />
            ))}
          </Switch>
        </div>
      </div>
    </main>
  );
};

export default Dashboard;
