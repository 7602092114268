/* eslint-disable linebreak-style */
/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */

import { createSlice } from "@reduxjs/toolkit";
import { axiosGet, postEncoded, postForm } from "../../api";
import { getPaymentRequests } from "../customer/dashboard/slice";

const initialState = {
  user: "Guest",
  loggedIn: false,
  loading: false,
  error: null,
};

const accounts = createSlice({
  name: "accounts",
  initialState,
  reducers: {
    loadingStart(state) {
      state.loading = true;
      state.error = null;
    },
    loadingStop(state) {
      state.loading = false;
    },
    setLoginSuccess(state, action) {
      const { data } = action.payload;
      if (data && data.usertype === 2) {
        state.user = "Customer";
      }
      if (data && data.usertype === 3) {
        state.user = "Provider";
      }
      state.details = data;
      state.loading = false;
      state.loggedIn = true;
      state.error = null;
    },
    setLoginError(state, action) {
      state.details = action.payload;
      state.loading = false;
      state.loggedIn = false;
      state.error = null;
    },
    logoutUser(state) {
      localStorage.clear();
      state.loggedIn = false;
      state.details = null;
    },
  },
});

export const {
  loadingStart,
  loadingStop,
  logoutUser,
  setLoginSuccess,
  setLoginError,
} = accounts.actions;

export default accounts.reducer;

export const login = (payload) => async (dispatch) => {
  dispatch(loadingStart());
  const {
    data: {
      data,
      message,
      token,
      errorMessage,
      varificationToken,
      otpmessage,
      profile_step,
    },
    status,
  } = await postEncoded({
    uri: "/login",
    payload,
  });

  if (status === 200) {
    if (varificationToken && otpmessage) {
      localStorage.setItem("token", varificationToken);
    } else {
      localStorage.setItem("phgr", token);
    }
    dispatch(setLoginSuccess({ data }));

    if (data.usertype) {
      dispatch(getPaymentRequests());
    }
  } else {
    dispatch(setLoginError(data));
    localStorage.clear();
  }

  dispatch(loadingStop());

  return { message, status, errorMessage, otpmessage, profile_step };
};

export const signup = (payload) => async (dispatch) => {
  dispatch(loadingStart());
  const {
    data: { message, errorMessage, varificationToken },
    status,
  } = await postForm({
    uri: "/signup",
    payload,
  });

  if (status === 200) {
    localStorage.setItem("token", varificationToken);
  } else {
    localStorage.clear();
  }

  dispatch(loadingStop());

  return { message, errorMessage, status };
};

export const getDetails = () => async (dispatch) => {
  dispatch(loadingStart());
  const {
    data: { data, message, errorMessage },
    status,
  } = await axiosGet({
    uri: "/get_user_detail",
    authName: "phgr",
  });

  if (status === 200) {
    dispatch(setLoginSuccess({ data }));

    if (data.usertype) {
      dispatch(getPaymentRequests());
    }
  } else {
    dispatch(setLoginError(data));
    localStorage.clear();
  }

  dispatch(loadingStop());

  return { message, errorMessage, status };
};

export const forgotPassword = (payload) => async (dispatch) => {
  dispatch(loadingStart());
  const {
    data: { otpmessage, errorMessage, varificationToken },
    status,
  } = await postEncoded({
    uri: "/forgotPassword",
    payload,
  });

  if (status === 200 && varificationToken) {
    localStorage.setItem("token", varificationToken);
  }

  dispatch(loadingStop());

  return { message: otpmessage, errorMessage, status };
};

export const resendOTP = () => async (dispatch) => {
  // dispatch(loadingStart());
  const {
    data: { message, errorMessage },
    status,
  } = await postEncoded({
    uri: "/resendotp",
    payload: null,
    authName: "token",
  });

  // dispatch(loadingStop());

  return { message, status, errorMessage };
};

export const verifyOTP = (payload) => async (dispatch) => {
  dispatch(loadingStart());
  const {
    data: { message, errorMessage },
    status,
  } = await postEncoded({
    uri: "/otpverification",
    payload,
    authName: "token",
  });

  dispatch(loadingStop());

  return { message, errorMessage, status };
};

export const verifySignUpOTP = (payload) => async (dispatch) => {
  dispatch(loadingStart());
  const {
    data: { message, errorMessage, data, token },
    status,
  } = await postEncoded({
    uri: "/otpverificationforsingup",
    payload,
    authName: "token",
  });

  if (status === 200 && data) {
    localStorage.setItem("phgr", token);
    dispatch(setLoginSuccess({ data }));
  }

  dispatch(loadingStop());

  return { message, errorMessage, status };
};

export const resetPassword = (payload) => async (dispatch) => {
  dispatch(loadingStart());
  const {
    data: { message, errorMessage },
    status,
  } = await postEncoded({
    uri: "/resetpassword",
    payload,
    authName: "token",
  });

  if (status === 200) {
    localStorage.removeItem("token");
  }

  dispatch(loadingStop());

  return { message, errorMessage, status };
};

export const changePassword = (payload) => async (dispatch) => {
  dispatch(loadingStart());
  const {
    data: { message, errorMessage },
    status,
  } = await postEncoded({
    uri: "/change_password",
    payload,
    authName: "phgr",
  });

  if (status === 200) {
    localStorage.removeItem("token");
  }

  dispatch(loadingStop());

  return { message, errorMessage, status };
};

export const verifyEmail = (payload) => async (dispatch) => {
  dispatch(loadingStart());
  const {
    data: { message, exists, errorMessage },
    status,
  } = await postEncoded({
    uri: "/emailuniquecheck",
    payload,
    authName: "token",
  });

  dispatch(loadingStop());

  return { message, errorMessage, exists, status };
};

export const verifyMobile = (payload) => async (dispatch) => {
  dispatch(loadingStart());
  const {
    data: { message, exists, errorMessage },
    status,
  } = await postEncoded({
    uri: "/phoneuniquecheck",
    payload,
    authName: "token",
  });

  dispatch(loadingStop());

  return { message, errorMessage, exists, status };
};

export const logout = () => async (dispatch) => {
  dispatch(loadingStart());
  const {
    data: { message, errorMessage },
    status,
  } = await postEncoded({
    uri: "/logout",
    payload: { device_type: 3 },
    authName: "phgr",
  });

  if (status === 200) {
    dispatch(logoutUser());
  }

  dispatch(loadingStop());

  return { message, status, errorMessage };
};

export const socialLogin = (payload) => async (dispatch) => {
  dispatch(loadingStart());
  const {
    data: { data, message, token, errorMessage },
    status,
  } = await postEncoded({
    uri: "/sociallogin",
    payload,
  });

  if (status === 200) {
    localStorage.setItem("phgr", token);

    dispatch(setLoginSuccess({ data }));
  } else {
    dispatch(setLoginError(data));
    localStorage.clear();
  }

  dispatch(loadingStop());

  return { message, status, errorMessage };
};

export const requestHelp = (payload) => async (dispatch) => {
  dispatch(loadingStart());
  const {
    data: { message, errorMessage },
    status,
  } = await postEncoded({
    uri: "/feedback",
    payload,
    authName: "phgr",
  });

  dispatch(loadingStop());

  return { message, status, errorMessage };
};

export const contact = (payload) => async (dispatch) => {
  dispatch(loadingStart());
  const {
    data: { message, errorMessage },
    status,
  } = await postEncoded({
    uri: "/create_contact_us",
    payload,
    authName: "phgr",
  });

  dispatch(loadingStop());

  return { message, status, errorMessage };
};

export const updateProfile = (payload) => async (dispatch) => {
  dispatch(loadingStart());
  const {
    data: { message, data, errorMessage },
    status,
  } = await postForm({
    uri: "/edit_profile",
    payload,
    authName: "phgr",
  });

  if (status === 200) {
    dispatch(setLoginSuccess({ data: data && data.data }));
  }

  dispatch(loadingStop());

  return { message, status, errorMessage };
};

export const selectProfile = (state) => state.accounts.details;
