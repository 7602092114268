import React, { memo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import "react-phone-number-input/style.css";
import PhoneInput, {
  isValidPhoneNumber,
  formatPhoneNumberIntl,
} from "react-phone-number-input";

import login_bg_with_shadow_png from "../../../images/home/login_bg_with_shadow.png";
import logo_footer_png from "../../../images/home/logo_footer.png";

import { useToggle } from "../../../app/hooks";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { forgotPassword } from "../slice";

const Forgot = memo(() => {
  const { goBack, push } = useHistory();
  const { user } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const loading = useSelector((state) => state.accounts.loading);

  const [open, toggle] = useToggle(true);

  const [mobile, setMobile] = useState("");
  const [error, setError] = useState({});

  const validate = () => {
    let errors = {};
    let isValid = true;

    if (!mobile) {
      isValid = false;
      errors.mobile = "Required";
    } else if (!isValidPhoneNumber(mobile)) {
      isValid = false;
      errors.mobile = "Invalid mobile number.";
    }

    setError(errors);
    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      const countryCode = formatPhoneNumberIntl(mobile).split(" ")[0];
      const payload = {
        country_code: countryCode,
        mobile: mobile.replace(countryCode, ""),
        usertype: user === "customer" ? 2 : 3,
      };

      dispatch(forgotPassword(payload)).then(
        ({ errorMessage, status, message }) => {
          if (status === 200) {
            enqueueSnackbar(message || "Success", {
              variant: "success",
            });
            push(
              `/${user}/verify/forgot?p=${mobile.replace(
                mobile.substring(3, 10),
                "*******"
              )}`
            );
          } else {
            enqueueSnackbar(errorMessage || "Error", {
              variant: "error",
            });
          }
        }
      );
    }
  };
  const handleHide = () => {
    goBack();
    goBack();
    toggle();
  };

  return (
    <Modal
      show={open}
      onHide={handleHide}
      size="xl"
      id="forgotModal"
      className="modal form-popup "
      aria-labelledby="exampleModalLabel"
    >
      <div className="modal-body ">
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={handleHide}
        ></button>
        <div className="row no-gutters justify-content-end">
          <div className="col-md-7 form-img d-lg-flex justify-content-center">
            <div className="img-holder align-self-center">
              <img
                className="img-fluid"
                src={login_bg_with_shadow_png}
                alt=" img"
              />
            </div>
            <img src={logo_footer_png} alt="img" className="logomodal" />
          </div>
          <div className="col-lg-5 form-box d-flex justify-content-center">
            <span
              className="pointer back-btn fas fa-2x fa-angle-left"
              onClick={goBack}
            ></span>
            <form
              className="w-100 px-5 align-self-center"
              onSubmit={handleSubmit}
            >
              <div className="text-center  mx-auto pb-5">
                <h4 className="mb-3">Forgot Password</h4>
                <p className="h6">
                  Please enter your registered mobile number to reset password.
                </p>
              </div>

              <div className="form-group">
                <div className="custom-group-input rounded-digonal">
                  <PhoneInput
                    className="form-control"
                    placeholder="Mobile Number"
                    international
                    defaultCountry="AU"
                    countryCallingCodeEditable={false}
                    initialValueFormat="national"
                    value={mobile}
                    required
                    onInput={() => setError({ ...error, mobile: "" })}
                    onChange={setMobile}
                  />
                </div>
                <span className="error">{error.mobile}</span>
              </div>

              <div className=" mx-auto mt-5">
                <button
                  type="submit"
                  className="btn btn-primary btn-block btn-feet --blue btn-lg btn-block rounded-pill"
                >
                  {loading ? "Loading..." : "Next"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Modal>
  );
});

export default Forgot;
