import React, { memo } from "react";
import { useHistory, Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";

import customer6_03_png from "../../../images/home/customer6_03.png";
import provider07_png from "../../../images/home/provider07.png";

import { useToggle } from "../../../app/hooks";

const Signup = memo(() => {
  const { goBack } = useHistory();
  const [open, toggle] = useToggle(true);

  const handleHide = () => {
    toggle();
    goBack();
  };

  return (
    <Modal
      show={open}
      onHide={handleHide}
      size="lg"
      id="signupasModal"
      className="modal form-popup "
      aria-labelledby="exampleModalLabel"
    >
      <div className="modal-body p-4">
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={handleHide}
        ></button>
        <h5 className="text-center mt-4"> Choose Option </h5>
        <div className="row signupas justify-content-center">
          <div className="col-xl-11">
            <Link to="/customer/login" name="signupas" id="customer" replace>
              <label htmlFor="customer" style={{ cursor: "pointer" }}>
                <img
                  src={customer6_03_png}
                  alt="customer"
                  className="img-fluid"
                />
              </label>
            </Link>
          </div>
          <div className="col-xl-11">
            <Link to="/provider/login" name="signupas" id="provider" replace>
              <label htmlFor="provider" style={{ cursor: "pointer" }}>
                <img
                  src={provider07_png}
                  alt="provider"
                  className="img-fluid"
                />
              </label>
            </Link>
          </div>
        </div>
      </div>
    </Modal>
  );
});

export default Signup;
