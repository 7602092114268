import React, { memo, useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import "react-phone-number-input/style.css";
import { useSnackbar } from "notistack";

import { useToggle } from "../../../app/hooks";
import { getBankCards, makePayment } from "../dashboard/slice";

const MakePayment = memo(() => {
  const { goBack } = useHistory();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { bookingID } = useParams();

  const { bankAccounts, loading } = useSelector((state) => {
    return {
      loading: state.dashboard.loading,
      bankAccounts: state.dashboard.bankAccounts,
    };
  });

  useEffect(() => {
    if (!bankAccounts) {
      dispatch(getBankCards({ page: 1 }));
    }
  }, [bankAccounts, dispatch]);

  const [selecetedCard, setSelectedCard] = useState();
  const [open, toggle] = useToggle(true);

  const handleHide = () => {
    toggle();
    goBack();
  };

  const handlePay = (e) => {
    e.preventDefault();

    if (selecetedCard) {
      const payload = {
        stripe_card_id: selecetedCard,
        currency: "INR",
        booking_id: bookingID,
      };
      dispatch(makePayment(payload)).then(
        ({ message, status, errorMessage }) => {
          if (status === 200) {
            enqueueSnackbar(message || "Success", {
              variant: "success",
            });
            goBack();
          } else {
            enqueueSnackbar(errorMessage || "Error", {
              variant: "error",
            });
          }
        }
      );
    } else {
      enqueueSnackbar("Please select a card to pay.", {
        variant: "error",
      });
    }
  };

  return (
    <Modal
      show={open}
      onHide={handleHide}
      size="xl"
      id="add-bankdetails-modal"
      className="modal form-popup "
      aria-labelledby="exampleModalLabel"
    >
      <div className="modal-body ">
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={handleHide}
        ></button>
        <div className="my-4 my-lg-5 d-flex align-items-center justify-content-between">
          <p className="h4 ml-5">Your saved cards</p>
          <div>
            <Link
              to="/dashboard/bank-accounts/new"
              className="btn btn-lg px-4 btn-primary rounded-pill mr-5"
              data-target="#add-bankdetails-modal"
              data-toggle="modal"
            >
              + Add New
            </Link>
          </div>
        </div>

        {loading && <span>Loading...</span>}
        {bankAccounts && bankAccounts.data && bankAccounts.data.length ? (
          <div className="row my-4">
            {bankAccounts.data.map((el) => (
              <div className="col-md-6 d-flex flex-wrap" key={el.id}>
                <div className="booking-item mt-0 mb-4 w-100">
                  <div className="input-select">
                    <input
                      type="radio"
                      name="pay-methods"
                      id={el.id}
                      value={el.id}
                      checked={el.id === selecetedCard}
                      onChange={() => setSelectedCard(el.id)}
                    />
                    <label htmlFor={el.id} className="media">
                      <i className="i40px i-cc mr-3"></i>
                      <div className="media-body py-2">
                        <h5 className="text-uppercase font-weight-bold">
                          {el.brand}
                          {/* <img
                            src="https://www.pinclipart.com/picdir/middle/401-4017515_bank-of-america-logo-transparent-clipart.png"
                            width="60px"
                            alt="alt"
                          /> */}
                        </h5>

                        <p className="h4 my-3">***********{el.last4}</p>
                        <p className="text-gray mb-2 text-capitalize h5">
                          {el.name}
                        </p>
                        <h6 className="text-gray">Exp</h6>
                        <small>
                          {el.exp_month}/{el.exp_year}
                        </small>
                      </div>
                    </label>
                  </div>
                </div>
              </div>
            ))}
            <button
              type="button"
              className="btn my-5 px-4 mx-5 btn-primary btn-feet --blue btn-lg btn-block rounded-pill"
              onClick={handlePay}
            >
              {loading ? "Please wait..." : "Pay"}
            </button>
          </div>
        ) : (
          <p>Please add your card to make payment.</p>
        )}
      </div>
    </Modal>
  );
});

export default MakePayment;
