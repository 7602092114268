import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  NavLink,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from "react-router-dom";
import { getMyBookings } from "../../slice";

import CancelledBooking from "./cancelled";
import CompletedBooking from "./completed";
import CompletedDetails from "./completed/details";
import OngoingBooking from "./ongoing";
import OngoingDetails from "./ongoing/details";
import PendingBooking from "./newRequests";
import UpcomingBooking from "./upcoming";
import FilterBookings from "./filter";
import SendUpdate from "./ongoing/update";
import CancelledDetails from "./cancelled/details";

const MyBookings = () => {
  let { path, url } = useRouteMatch();
  const dispatch = useDispatch();
  const {
    push,
    location: { pathname },
  } = useHistory();

  const {
    loading,
    newRequests,
    completed,
    ongoing,
    cancelled,
    upcoming,
  } = useSelector((state) => {
    return {
      loading: state.provider.loading,
      newRequests: state.provider.newRequests,
      completed: state.provider.completed,
      ongoing: state.provider.ongoing,
      cancelled: state.provider.cancelled,
      upcoming: state.provider.upcoming,
    };
  });

  useEffect(() => {
    if (!ongoing) {
      dispatch(getMyBookings({ page: 1, type: 2 })).then(({ status }) => {
        if (status === 200 && pathname !== "/dashboard/my-bookings/ongoing") {
          push("/dashboard/my-bookings/ongoing");
        }
      });
    }
  }, [ongoing, dispatch, push, pathname]);

  return (
    <Fragment>
      <div className="d-flex justify-content-between">
        <div>
          <ul className="nav booking-tabs mb-3" id="myTab" role="tablist">
            <li className="nav-item">
              <NavLink
                className="nav-link"
                data-toggle="tab"
                to={`${url}/new-requests`}
                role="tab"
                onClick={() => dispatch(getMyBookings({ page: 1, type: 1 }))}
              >
                <i className="i30px ifeet-blue"></i>New Requests
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className="nav-link"
                data-toggle="tab"
                to={`${url}/upcoming`}
                role="tab"
                onClick={() => dispatch(getMyBookings({ page: 1, type: 5 }))}
              >
                <i className="i30px iupcoming"></i>Upcoming
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className="nav-link"
                data-toggle="tab"
                to={`${url}/ongoing`}
                role="tab"
                onClick={() => dispatch(getMyBookings({ page: 1, type: 2 }))}
              >
                <i className="i30px ibookongoing-blue"></i>Ongoing
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className="nav-link"
                data-toggle="tab"
                to={`${url}/completed`}
                role="tab"
                onClick={() => dispatch(getMyBookings({ page: 1, type: 3 }))}
              >
                <i className="i30px ibookcompleted-blue"></i>Completed
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className="nav-link"
                data-toggle="tab"
                to={`${url}/cancelled`}
                role="tab"
                onClick={() => dispatch(getMyBookings({ page: 1, type: 4 }))}
              >
                <i className="i30px ibookcancelled-blue"></i>Cancelled
              </NavLink>
            </li>
          </ul>
        </div>
        {!pathname.includes("details") && <FilterBookings />}
      </div>
      <Switch>
        <Route exact path={`${path}/new-requests`}>
          <PendingBooking list={newRequests} loading={loading} />
        </Route>
        <Route exact path={`${path}/upcoming`}>
          <UpcomingBooking list={upcoming} loading={loading} />
        </Route>
        <Route exact path={`${path}/ongoing`}>
          <OngoingBooking list={ongoing} loading={loading} />
        </Route>
        <Route exact path={`${path}/completed`}>
          <CompletedBooking list={completed} loading={loading} />
        </Route>
        <Route exact path={`${path}/cancelled`}>
          <CancelledBooking list={cancelled} loading={loading} />
        </Route>
        <Route exact path={`${path}/completed/details/:bookingID`}>
          <CompletedDetails />
        </Route>
        <Route exact path={`${path}/ongoing/details/:bookingID`}>
          <OngoingDetails />
        </Route>
        <Route exact path={`${path}/cancelled/details/:bookingID`}>
          <CancelledDetails />
        </Route>
        <Route exact path={`${path}/ongoing/update/:bookingID`}>
          <SendUpdate />
        </Route>
      </Switch>
    </Fragment>
  );
};

export default MyBookings;
