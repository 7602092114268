import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Skeleton from "@material-ui/lab/Skeleton";

import { usePrevious } from "../../../../../app/hooks";
import { getBookingDetails } from "../../slice";
import Booking from "./Booking";
import Pet from "./Pet";
import Photos from "./Photos";
import Provider from "./Provider";
import Reviews from "./Reviews";

const BookingDetails = () => {
  const dispatch = useDispatch();
  const { bookingID, status } = useParams();
  const { goBack } = useHistory();

  const { loading, details } = useSelector((state) => {
    return {
      loading: state.dashboard.loading,
      details: state.dashboard.bookingDetails,
    };
  });

  const prevBookingID = usePrevious(bookingID);

  useEffect(() => {
    if (!loading && bookingID && (!details || bookingID !== prevBookingID)) {
      window.scrollTo(0, 0);
      dispatch(getBookingDetails({ bookingID }));
    }
  }, [dispatch, details, bookingID, prevBookingID, loading]);

  return (
    <div className="tab-pane fade  show active" id="completed" role="tabpanel">
      {loading ? (
        <Skeleton variant="rect" width={200} />
      ) : (
        <p className="light-gray mt-4 text-dark mb-1">
          <i className="ti-angle-left ml-5 pointer" onClick={goBack}></i> ID:{" "}
          {details && details._id}
        </p>
      )}

      {details && <Booking data={details} loading={loading} />}

      <div className="row my-4">
        {details && <Pet data={details.pet_list && details.pet_list[0]} />}
        {details && <Provider data={details.provider} />}
      </div>

      {details &&
      details.track &&
      details.track.length &&
      status !== "pending" &&
      status !== "upcoming"
        ? details.track.map((el, i) => <Photos key={i} data={el} />)
        : null}

      {details &&
        !(details.ratingReviews && details.ratingReviews.length) &&
        (status === "completed" || status === "ongoing") && <Reviews />}
    </div>
  );
};

export default BookingDetails;
