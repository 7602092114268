import React, { forwardRef } from "react";
import { useSelector } from "react-redux";

import service2_png from "../../images/home/service2.png";

const baseURL = process.env.REACT_APP_PAW_HUGGER_WEB_BASE_URL;

const Services = forwardRef((props, ref) => {
  const { services } = useSelector((state) => {
    return {
      services: state.services.services,
    };
  });

  return (
    <section className="home-services py-5 " ref={ref}>
      <div className="container-fluid py-lg-5 mt-md-5">
        <div className="row justify-content-center">
          <div className=" col-lg-9 text-center">
            <h3>
              <span>Our Services </span>
            </h3>
            <p>
              Paw Hugger is 100% free to join whether you are an owner or
              service provider. You only pay for the service that you choose.
              The app enable you to find local pet service provider who will
              treat your pet as part of their family.
            </p>
            <p>
              All service provider are verified and rated to reflect their
              commitment and quality of service.
            </p>
            <p>
              Cashless payments – Book and pay online so as to benefit from
              flexible cancellations. It gives both parties peace of mind and
              security.
            </p>
          </div>
        </div>
        <div className="px-lg-5">
          <div className="row justify-content-center">
            {services && services.data && services.data.length ? (
              services.data.map((el) => (
                <div className="col-md-6 col-lg-4 d-flex" key={el._id}>
                  <div className="service-item card shadow-yellow">
                    <img
                      src={
                        el.service_image
                          ? `${baseURL}/${el.service_image}`
                          : service2_png
                      }
                      className="card-img-top"
                      alt="pet"
                    />
                    <div className="card-body ">
                      <div className="align-self-center">
                        <p className="card-title" style={{ color: "#40769B" }}>
                          {el.service_name && el.service_name.split(" ")[0]}
                          <span>
                            {el.service_name && el.service_name.split(" ")[1]}
                          </span>
                        </p>
                        <p
                          className="card-text rate"
                          style={{ color: "#40769B" }}
                        >
                          {el.total_service && el.total_service > 1000
                            ? el.total_service / 1000
                            : el.total_service}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div>Data not available</div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
});

export default Services;
