import React from "react";

const BillingInformation = ({ data }) => {
  return (
    <div className="row my-4">
      <div className="col-lg-6 d-flex flex-wrap">
        <p className="h5 text-gray mb-4 ml-3">Billing Information</p>
        <div className="booking-item mt-0 mb-4 mb-lg-0 w-100">
          <div className="d-flex justify-content-between p-3">
            <div className="h5">Total Paid</div>
            <div className="h5">
              <span className="mr-5">:</span>{" "}
              <span className="">${data && data.isPaid}</span>
            </div>
          </div>
          <div className="d-flex justify-content-between p-3">
            <div>
              <h5>Paw Hugger Commision</h5>
              <p className="">2% commission of service</p>
            </div>
            <div className="h5">
              <span className="mr-5">:</span>{" "}
              <span className=" text-danger">
                ${data && data.commistion_amount}
              </span>
            </div>
          </div>
          <hr />
          <div className="d-flex justify-content-between p-3">
            <div className="">
              <h5>Recieved Amount</h5>
            </div>
            <div className="h5">
              <span className="mr-5">:</span>{" "}
              <span>${data && data.amount}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BillingInformation;
