import React from "react";
import Modal from "react-bootstrap/Modal";
import { Link, useHistory, useParams } from "react-router-dom";
import { useToggle } from "../../../../app/hooks";

const SelectServiceTime = () => {
  const { goBack } = useHistory();
  const { providerID } = useParams();
  const [open, toggle] = useToggle(true);

  const handleSubmit = (e) => {
    e.preventDefault();
    // loginUser(email.value, password.value);
  };

  const handleHide = () => {
    toggle();
    goBack();
  };

  return (
    <Modal
      show={open}
      onHide={handleHide}
      size="xl"
      id="bookingModal"
      className="modal form-popup "
      aria-labelledby="exampleModalLabel"
    >
      <div className="modal-body ">
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={handleHide}
        ></button>
        <div className="row no-gutters justify-content-end">
          <div className="col-12 form-box d-flex justify-content-center">
            <form
              className="w-100 px-5 py-5 align-self-center"
              onSubmit={handleSubmit}
            >
              <p className="text-gray mb-5 h5  text-capitalize">
                Select Dog Sitter service time
              </p>
              <p className="h6 mb-3 text-capitalize">Dog Sitter service time</p>
              <div className="radio-select radio-slots mb-4">
                <input type="radio" name="serviceTime" id="jkjk" />
                <label for="jkjk" className="">
                  1 hr
                </label>
                <input type="radio" name="serviceTime" id="kjh" />
                <label for="kjh" className="">
                  2 hr
                </label>
                <input type="radio" name="serviceTime" id="mjh" disabled />
                <label for="mjh" className="">
                  3 hr
                </label>
                <input type="radio" name="serviceTime" id="kjhu" />
                <label for="kjhu" className="">
                  4 hr
                </label>
                <input type="radio" name="serviceTime" id="jkss" checked />
                <label for="jkss" className="">
                  5 hr
                </label>
              </div>
              <p className="h6 mb-3 text-capitalize">Or</p>
              <div className="radio-select radio-slots">
                <input type="radio" name="serviceTime" id="kjku" disabled />
                <label for="kjku" className="">
                  Full Day
                </label>
              </div>

              <div className="row justify-content-center">
                <div className="col-lg-6 mt-5">
                  <Link
                    to={`/services/${providerID}/select-dog`}
                    className="btn btn-primary btn-feet --blue btn-lg btn-block rounded-pill"
                  >
                    Next
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SelectServiceTime;
